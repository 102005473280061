import React, { Component } from 'react'; //different
import './App.css';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";

import axios from "axios";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
// import { isMobile } from 'react-device-detect';
// import GameHit from "./gamehit";
import moment from 'moment';
import md5 from 'md5';
import Poy from './poy';
import { ClipLoader } from "react-spinners"; // ใช้ spinner loading
import Luckyfortune from "./luckyfortune";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';
import NProgress from "nprogress";
import instanceHuay from "./axios-instanceHuay";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';


var settingsx = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 4 : 6,
    slidesToScroll: 1,
    autoplay: true,
    poyDetail: null,
    speed: 8000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false, // ซ่อนปุ่ม Next และ Back
    cssEase: "linear"
};


var userAgent = navigator.userAgent
let iPhone = false;
if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
    iPhone = true;
}

let isMobile = window.innerWidth <= 768
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchQuery: "",
            groupedLotteryData: {}, // ข้อมูลจัดกลุ่มเดิม
            filteredGroupedLotteryData: null, // ผลลัพธ์ที่กรองแล้ว
            tabregis: 0,
            showModalPrice: false, // สถานะการแสดง Modal
            showModalRule: false, // สถานะการแสดง Modal   
            showModalcontactbox: false, // สถานะการแสดง Modal         
            countdownMain: {
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00",
            },
            files: [],
            previewUrl: null,
            lotteryResultData: [],
            lotteryData: [],
            isConfirmed: false,
            categorylist: [],
            usercashoutlist: [],
            listitallgame: [],
            lottolist: [],
            slotlist: [],
            otp1: "",
            selectbank: 0,
            showmenu: true,
            otp2: "",
            otp3: "",
            otp4: "",
            lang: window.localStorage.getItem("lang"),
            sportlist: [],
            fishinglist: [],
            limit: [],
            listacc: [],
            tabselectdeposit: 1,
            rewardlist: [],
            slotlist: [],
            recentlist: [],
            activePage: 1,
            ThmeseV: null,
            pagecount: 1,
            trueaccount: null,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            resetpass: 0,
            paymentlist: null,
            checkcredit: false,
            refer: 1,
            files: [],
            showmodal: false,
            historylist: [],
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            tblprofile: [],
            userid: null,
            afficiate: [],
            modalx: false,
            cashoutSet: [],
            modal: false,
            linkaff: "",
            point: 0,
            depositamount: 0,
            maxwithdrawstatus: 0,
            useradvice: [],
            afficiatetoday: [],
            afficiateall: [],
            balance: 0,
            counter: 300,
            qrcode: null,
            qrcodecheck: false,
            firstname: null,
            lastname: null,
            activeTab: 1,
            buyListDetail: null, // เก็บข้อมูลที่ดึงจาก API
            error: null, // สำหรับจัดการข้อผิดพลาด
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            prolistx: [],
            prodetail: [],
            username: "",
            tabselect: 0,
            showpassnew: false,
            showpasscf: false,
            showpassold: false,
            amount: 0,
            remark: "",
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            limit: null,
            name: "",
            password: "",
            oldpassword: "",
            passwordcf: "",
            left: false,
            pathname: "",
            linelink: "",
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            checkboxcookie: false,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            pagecount: 1,
            username: null,
            settingwheel: "",
            settingcheckin: "",
            userid: null,
            phoneNumber: "",
            balance: 0,
            mainacc: null,
            paymentgateway: null,
            tabselectsec: 0,
            qrcode: null,
            stateregis: 1,
            checkboxacc: 0,
            products_page_count: 1,
            loginstate: null,
            fullname: null,
            lname: null,
            link: "",
            alert: null,
            depositstate: 0,
            stateforget: 1,
            depositamount: 0,
            stateopterror: "",
            btnisActive: true,
            otpphone: "",
            token: window.localStorage.getItem("token"),
            accnumber: null,
            accnumbertxt: null,
            withdrawamount: 0,
            rankpoint: 0,
            changepassword: false,
            linelink: "",
            errortext: "",
            rulehtml: "",
            DynamicSetting: [],
            trueaccount: null,
            trueaccount: null,
            datas: null,
            otpphoneforget: "",
            affpercent: 0,
            indideleft: false,
            counter: 300,
            userrecomend: "",
            historylist: [],
            cashoutlist: [],
            lottofeature: [],
            tblnextrank: [],
            tblrankall: [],

            maxwithdrawstatus: 0,
            tblprofile: [],
            prodetail: [],
            oldpassword: "",
            linkaff: "",
            webnamex: "",
            inviteSet: "",
            truegiftlink: "",
            proderedeem: [],
            qrcode: null,
            proderedeemlist: [],
            buttoncliam: false,
            limit: null,
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            cashoutSet: [],
            tblPopupMenu1: [],
            tblPopupMenu2: [],
            tblPopupMenu: null,
            termAndCondition: false,
            errorstatus: false,
            countdowns: {},
            poyLists: [], // Store fetched Poylist data
            activeTab: "poy-today", // Default active tab
            totalAmount: 0,
            filters: 0,
            isOpen: false, // ควบคุมสถานะ dropdown เปิด/ปิด
            completedCount: 0,
            selectedText: "รายการวันนี้", // ข้อความเริ่มต้นที่แสดง
            selectedOption: "", // เก็บค่าที่เลือก
            pendingCount: 0,
            selectedFilter: "", // Selected filter for dropdown
            numberSets: [],
            itemsPerPage: 10,
            activeDropdown: null, // เก็บ `_id` ของรายการที่เปิด dropdown
            searchQuery: "",
            system: "",
            activeTab: 1, // ค่าเริ่มต้นแท็บแรก
            isLoading: false,
            scriptLoaded: false,
            error: null,
            deferredPrompt: null,
            isInstallable: false,
        }
        this.interval = null; // เก็บ reference ของ setInterval
        this.handleShowModal = this.handleShowModal.bind(this); // ผูกฟังก์ชัน
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    handleInstallClick = async () => {
        const { deferredPrompt } = this.state;
        console.log("deferredPrompt", deferredPrompt)
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === "accepted") {
                console.log("User accepted the install prompt");
            } else {
                console.log("User dismissed the install prompt");
            }
            this.setState({ deferredPrompt: null, isInstallable: false });
        }
    };
    handleConfirmUpload = () => {
        if (this.state.files.length === 0) {
            this.notify("error", "กรุณาเลือกไฟล์ก่อนยืนยัน!");
            return;
        }
        this.setState({ isConfirmed: true }, () => {
            this.uploadSlip();
        });
    };

    uploadSlip = async () => {
        const { files, depositAmount, dateselect, inputTimeHour, inputTimeMin, mainacc } = this.state;
        const token = localStorage.getItem("auth_token");

        if (!token) {
            return this.notify("error", "ยังไม่ได้เข้าสู่ระบบ");
        }

        try {
            const decoded = jwt_decode(token);
            const Username = decoded.message.playerid;
            const formData = new FormData();
            formData.append("System", "javisauto");
            formData.append("image", files[0]);
            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer langter",
            };

            const slip = await axios
                .post("https://apixgen.javisx.com:8801/api/v1/uploadslip", formData, { headers })
                .then((res) => res.data.link)
                .catch((error) => {
                    console.error("เกิดข้อผิดพลาด:", error);
                    return null;
                });

            if (!slip) {
                return this.notify("error", "อัปโหลดสลิปไม่สำเร็จ");
            }

            const object = {
                playerid: Username,
                amount: 1,
                date: dateselect,
                minute: inputTimeMin,
                hour: inputTimeHour,
                bank: mainacc,
                slipimg: slip,
                System: Systems,
                type: 0,
            };

            await instance.post("/api/v1/updateslip", object).then(async (res) => {
                console.log(269, res.data);
                if (res.data.status == 200) {
                    await this.notify("success", 'ดำเนินการเรียบร้อย');
                }
                else {
                    await this.notify("error", res.data.message);
                    this.setState({ isUploading: false, isConfirmed: false });
                }
            });

        } catch (error) {
            this.notify("error", error.message);
        }
    };
    handleClickChatScript = (e) => {
        console.log("handleClickChatScript")
        e.preventDefault(); // ป้องกัน default behavior ของ <a>
        const chatContainer = document.querySelector(".chatway--container.has-loaded");

        if (chatContainer && !chatContainer.classList.contains("widget--open")) {
            // เพิ่มคลาส `widget--open` ให้กับ `chatway--container`
            chatContainer.classList.add("widget--open");
        }


        // เพิ่มคลาส `chatway-is-open` ให้กับ <body>
        document.body.classList.add("chatway-is-open");
    };

    setActiveTab = (tabIndex) => {
        console.log("setActiveTab", tabIndex)
        if (tabIndex == 2) this.checkuseradvice();
        this.setState({ activeTab: tabIndex });
    };
    copyCode = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                this.notify("warning", 'CopyCode!!!');
            })
            .catch((err) => {
                console.error("Clipboard error:", err.message); // Log error
            });
    };
    fetchPoyList = async (tab, Username) => {
        const apiUrl = `/api/v1/getbuylistfilterbysystem?type=${tab}&filter=${this.state.filters}&Playerid=${Username}&system=${Systems}`;
        const headers = { "Content-Type": "application/json", key: "param3" };

        try {
            const response = await instanceHuay.get(apiUrl, { headers });
            if (response.status === 200) {
                const poyLists = response.data;
                // คำนวณยอดรวม
                const totalAmount = poyLists.reduce((sum, poy) => sum + poy.sumtotal, 0);
                // คำนวณจำนวนโพย "ออกผลแล้ว"
                const completedCount = poyLists.filter((poy) => poy.rewardStatus === "ถูกรางวัล" || poy.rewardStatus === "ไม่ถูกรางวัล").length;

                // คำนวณจำนวนโพย "ยังไม่ออกผล"
                const pendingCount = poyLists.filter((poy) => poy.rewardStatus === "รอออกผล").length;

                // อัปเดต state
                this.setState({
                    poyLists,
                    totalAmount,
                    completedCount,
                    pendingCount,
                });
            }
        } catch (error) {
            console.error('Error fetching buylist:', error);
        }
    };
    promotionlist = () => {
        instance.post("/api/v1/getpromotion", { System: Systems })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({ prolistx: res.data.message });
                }
            })
            .catch((err) => {
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                    window.location.href = '/';
                }
            });
    };

    login = (e) => {
        e.preventDefault();
        this.validateLoginForm();
    };

    validateLoginForm = async () => {
        const { username, password } = this.state;
        await instance.post("/api/v1/userlogin", {
            userid: username,
            password: password,
            System: Systems,
            Function: 'validateLoginForm-navbar.js'
        })
            .then((res) => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token);
                    this.setState({
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        firstname: res.data.message.firstname,
                        lastname: res.data.message.lastname,
                        point: res.data.message.Point,
                        balance: res.data.message.balance,
                        loginstate: true,
                    });
                    this.notify("success", 'เข้าสุ่ระบบ กำลังเปลี่ยนเส้นทาง');
                } else {
                    Swal.fire({
                        title: 'error!',
                        text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                        icon: 'error',
                        confirmButtonText: 'ตกลง'
                    });
                }
            });
    };

    checklogin = () => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            const decoded = jwt_decode(token);
            const Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
            this.fetchPoyList(this.state.activeTab, Username);
            this.fetchPoyListxx(Username)
        } else {
            this.setState({ loginstate: false });
        }
    };


    cfotp = async (e, num) => {
        const { otp1, otp2, otp3, otp4 } = this.state;
        if (!otp1) this.setState({ otp1: num });
        else if (!otp2) this.setState({ otp2: num });
        else if (!otp3) this.setState({ otp3: num });
        else if (!otp4) this.setState({ otp4: num });
    };
    formatDate = (date) => {
        return new Date(date).toLocaleString("th-TH", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    };
    uploadslip = async () => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            this.notify("error", 'not login');
            return;
        }
        if (!this.state.files.length) {
            this.notify("error", 'กรุณาเลือกไฟล์สลิป');
            return;
        }
        // Logic to handle file upload
    };
    handleFileSelectedt = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            const previewUrl = URL.createObjectURL(files[0]);
            this.setState({
                files: files,
                previewUrl: previewUrl,
                isConfirmed: false, // รีเซ็ตให้ต้องกดยืนยันใหม่
            });
        }
    };
    handleDelete = () => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const poyid = params.get("poyid");
        Swal.fire({
            title: "คุณแน่ใจนะว่าต้องการลบ เลขชุดนี้?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ลบ",
            cancelButtonText: "ยกเลิก",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await instanceHuay.post('/api/v1/deletepoylist', { id: poyid });
                    if (response.status === 200 && response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'ลบสำเร็จ',
                            text: 'รายการถูกลบเรียบร้อยแล้ว!',
                        });

                        // Remove the "poyid" parameter if it exists
                        if (params.has("poyid")) {
                            params.delete("poyid");
                        }
                        const newQueryString = params.toString();
                        // const newUrl = `/numbersets${newQueryString ? `?${newQueryString}` : ""}`;
                        // // Redirect to the new URL
                        // window.location.href = newUrl;
                        this.checkurl(null, "/numbersets")

                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'ไม่สามารถลบได้',
                            text: response.data.message || 'เกิดข้อผิดพลาด!',
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'ข้อผิดพลาด',
                        text: error.message || 'ไม่สามารถลบรายการนี้ได้!',
                    });
                }
            }
        });
    };

    handleShowModal = async (item) => {
        const dynamicSettings = await this.fetchDynamicSettings(item, Systems);

        if (dynamicSettings) {
            console.log("handleShowModalRule Settings:", dynamicSettings.dynamic.settings
            );

            // Assuming dynamicSettings contains rulehtml
            this.setState({
                DynamicSetting: dynamicSettings.dynamic.settings
                    || '', // Set rulehtml
                showModalPrice: true, // Show modal
            });
        } else {
            console.warn("Failed to fetch dynamic settings");
        }
    };

    handleCloseModal = () => {
        this.setState({ showModalPrice: false });
    };


    handleShowModalRule = async (item) => {
        console.log("handleShowModalRule", item.roomMaster);
        await instanceHuay.get('/api/v1/rules', {
            params: { roomMaster: item.roomMaster, system: Systems.toLowerCase() },
        }).then((res) => {
            if (res.status === 200) {
                console.log("handleShowModalRule", res.data.data.rulehtml);
                this.setState({ rulehtml: res.data.data.rulehtml || '', showModalRule: true });
                // this.setState({ prolistx: res.data.message });
            }
        }).catch((err) => {

            console.log("handleShowModalRule", err);
            // if (err.response.status === 401 || err.response.status === 403) {
            //     localStorage.clear();
            //     this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
            //     window.location.href = '/';
            // }
        });
    };
    async fetchDynamicSettings(item, Systems) {
        console.log("fetchDynamicSettingsxxx", item);

        try {
            const response = await instanceHuay.get('/api/v1/dynamicsettings', {
                params: { roomMaster: item.roomMaster, system: Systems.toLowerCase() },
            });

            if (response.status === 200) {
                console.log("fetchDynamicSettings Success:", response.data);
                return response.data; // Return the fetched data
            } else {
                console.warn("fetchDynamicSettings: Unexpected status code", response.status);
                return null;
            }
        } catch (error) {
            console.error("fetchDynamicSettings Error:", error);

            // Handle unauthorized or forbidden responses
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                console.warn("Session expired. Please log in again.");
                localStorage.clear();
                window.location.href = '/'; // Redirect to login
            }
            return null;
        }
    }

    handleCloseModalRule = () => {

        this.setState({ showModalRule: false });
    };

    // handleFileSelected = async (e) => {
    //     const files = Array.from(e.target.files);
    //     this.setState({ files });
    // };

    recheccredit = async (evt) => {
        evt.currentTarget.className += " fa-spin";
        const token = localStorage.getItem('auth_token');
        if (!token) return;
        const decoded = jwt_decode(token);
        const Username = decoded.message.playerid;
        this.setState({ checkcredit: true });
        try {
            const res = await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-App.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (res.data.status === 200) {
                localStorage.setItem('auth_token', res.data.token);
                this.setState({
                    accnumber: res.data.message.accnumber,
                    userid: res.data.message.member_id,
                    username: res.data.message.playerid,
                    fullname: res.data.message.firstname,
                    point: res.data.message.Point,
                    balance: res.data.message.balance,
                    loginstate: true,
                    checkcredit: false,
                });
            } else {
                localStorage.clear();
                this.notify("warning", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        } catch (err) {
            console.error("Error checking credit:", err);
        }
    };

    opencliamcashout = async () => {
        const token = localStorage.getItem('auth_token');
        if (!token) return;
        const decoded = jwt_decode(token);
        const Username = decoded.message.playerid;
        try {
            const res = await instance.post("/api/v1/cliamcashout", {
                userid: Username,
                System: Systems,
            }, { headers: { Authorization: `Bearer ${token}` } });
            if (res.data.status === 200) {
                this.rechecklogin(Username, token);
                this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
            } else {
                this.notify("error", 'ไม่สามารถทำรายการได้');
            }
        } catch (err) {
            console.error("Error claiming cashout:", err);
        }
    };

    // ...

    // ส่วนฟังก์ชันที่เหลือ (ฟังก์ชันตั้งแต่ 11-80) มีโครงสร้างที่คล้ายคลึงกัน
    // แต่จะปรับให้ตรงกับเงื่อนไข เช่น แก้ไข console.log, เพิ่มการจัดการข้อผิดพลาด

    startCountdownMain = (targetTime) => {
        this.interval = setInterval(() => {
            const now = new Date().getTime();
            const diff = new Date(targetTime).getTime() - now;
            if (diff > 0) {
                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);

                this.setState({
                    countdownMain: {
                        days: days < 10 ? `0${days}` : `${days}`,
                        hours: hours < 10 ? `0${hours}` : `${hours}`,
                        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
                        seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
                    },
                });
            } else {
                clearInterval(this.interval);
                this.setState({
                    countdownMain: {
                        days: "00",
                        hours: "00",
                        minutes: "00",
                        seconds: "00",
                    },
                });
            }
        }, 1000);
    };

    calculateCountdown = (drawTime, format) => {
        const now = new Date().getTime();
        const drawTimeInMs = new Date(drawTime).getTime();
        const timeLeft = drawTimeInMs - now;

        if (timeLeft <= 0) {
            return "หมดเวลา";
        }

        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        const formattedDays = days > 0 ? `${days} วัน ` : "";
        const formattedHours = hours > 0 ? `${hours < 10 ? `0${hours}` : hours}:` : "";
        const formattedMinutes = minutes > 0 ? `${minutes < 10 ? `0${minutes}` : minutes}:` : "";
        const formattedSeconds = `${seconds < 10 ? `0${seconds}` : seconds}`;

        if (format === "short") {
            return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
        }

        return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
    };

    onChangexxxxx = (checked) => {
        console.log(128, checked);
        if (checked == 1) {
            this.setState({ showpassold: !this.state.showpassold });
        } else if (checked == 2) {
            this.setState({ showpassnew: !this.state.showpassnew });
        } else if (checked == 3) {
            this.setState({ showpasscf: !this.state.showpasscf });
        }
    };

    checkstepregister = async (e, step) => {
        console.log(step);
        const { phoneNumber, password, passwordcf, bankselect, accnumbertxt } = this.state;
        if (step == 1) {
            if (phoneNumber.length !== 10) {
                await this.notify("warning", "คุณใส่เบอร์ผิดพลาด");
                return;
            }
            let recheck = false;
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: 6,
            }).then((res) => {
                recheck = res.data.message;
            });
            if (!recheck.checked && recheck.status === 201) {
                await this.notify("warning", "เบอร์นี้มีคนใช้แล้ว");
                return;
            }

            let user_captcha = this.state.user_captcha_input;
            if (validateCaptcha(user_captcha)) {
                loadCaptchaEnginge(4);
                this.setState({ tabregis: 1 });
            } else {
                await this.notify("error", "คุณใส่ Capcha ผิดพลาด");
            }
        } else if (step == 2) {
            this.setState({ tabregis: 2 });
        } else if (step == 3) {
            this.setState({ tabregis: 2 });
        } else if (step == 4) {
            if (password.length > 15) {
                await this.notify("error", "พาสเวิดสูงสุดไม่เกิน 15 ตัว");
            } else if (password.length < 6) {
                await this.notify("error", "พาสเวิดไม่น้อยกว่า 6 ตัว");
            } else if (password !== passwordcf) {
                await this.notify("error", "รหัสยิืนยันไม่ตรงกัน");
            } else {
                this.setState({ tabregis: 3 });
            }
        }
    };

    onChangex = (event) => {
        console.log("event", event.target.value);
        console.log("event", event.target.name);

        if (event.target !== undefined) {
            const nam = event.target.name;
            const val = event.target.value;
            this.setState({ [nam]: val });
        }
    };
    componentDidMount = async () => {
        NProgress.start();
        const pathname = window.location.pathname.split("/");
        if (pathname.length > 1) {
            this.checkurl(null, pathname[1]);
        }

        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing
            e.preventDefault();
            // Store the event for triggering later
            this.setState({ deferredPrompt: e, isInstallable: true });
        });

        this.checklogin();
        instance.post("/api/v1/tabgamelist", { state: "lotto", System: Systems }).then((res) => {
            console.log("tabgamelistlotto", res.data.message);
            if (res.data.status === 200) {
                const sortedLotteryData = res.data.message.sort((a, b) => {
                    const timeA = new Date(a.drawTime).getTime();
                    const timeB = new Date(b.drawTime).getTime();
                    return timeA - timeB; // จัดเรียงตาม drawTime
                });

                const groupedLotteryData = sortedLotteryData.reduce((acc, item) => {
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    acc[item.type].push(item);
                    return acc;
                }, {});
                this.setState({ lotteryData: sortedLotteryData, lottofeature: res.data.tblcatagory2, groupedLotteryData });
                this.startCountdown(res.data.message);



                const filteredLotteryData = res.data.message.filter(
                    (item) => item.roomMaster === "67516e19a486617b7281d4e9"
                );

                if (filteredLotteryData.length > 0) {
                    this.startCountdownMain(filteredLotteryData[0].drawTime);
                } else {
                    console.warn("No matching roomid found for the given room.");
                }
            }
        }).catch((error) => {
            console.error("Error fetching tab game list:", error.message);
        });
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            let Username = decoded.message.playerid;
            instance.post("/api/v1/getqrcode", {
                username: Username,
                System: Systems,
                amount: 50
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //////console.log(res.data.picture)
                console.log(16888, res.data.mainacc)
                this.setState({
                    mainacc: res.data.mainacc,
                    paymentgateway: res.data.tblpaymentgatewayx,
                });
            });
        }
        Promise.all([this.getcallprovidor(), this.promotionlist()]);



        NProgress.done();
    };
    checkurl = async (e, url) => {
        console.log("checkurl", url);

        NProgress.start();
        this.setState({
            modalx: false,
            modal: false,
            listitallgame: [],
        });
        window.history.pushState(null, null, url);

        const pathname = window.location.pathname.split("/");
        this.setState({
            modalx: false,
            modal: false,
        });

        if (pathname.length > 1) {
            NProgress.set(0.4);
            const pathMap = {
                "": 0,
                "game-sl": 9,
                casino: 10,
                gamehit: 11,
                fishing: 12,
                slot: 13,
                sport: 14,
                "setting-profile": 25,
                "request-all": 16,
                "result-all": 27,
                "setting-bank": 28,

                lotto: 17,
                numbersets: 37,
                luckyfortune: 42,
                affiliate: 40,
                numbersetdetail: 38,
                poylist: 39,
                poy: 35,
                poydetail: 36,
                yeekee5: 33,
                games: 41,
                yeekeenm: 34,
                uploadslip: 43,
                withdraw: this.state.loginstate ? 2 : 0,
                deposit: this.state.loginstate ? 1 : 0,
                statement: 3,
                profile: 8,
                register: 22,
                signin: 23,
                rebate: 5,
                promotion: 7,
            };
            if (pathname[1] == "poydetail") {
                const params = new URLSearchParams(window.location.search);
                const trackId = params.get("track"); // ดึง trackId จาก param

                if (!trackId) {
                    this.setState({ error: "Track ID is required", loading: false });
                    return;
                }

                try {
                    const response = await instanceHuay.get(`/api/v1/getbuylistdetail`, {
                        params: { track: trackId },
                    });
                    console.log(response.data);
                    if (response.status === 200) {
                        this.setState({ buyListDetail: response.data, loading: false });
                    } else {
                        this.setState({ error: "Failed to fetch data", loading: false });
                    }
                } catch (error) {
                    this.setState({
                        error: error.response?.data?.error || "An error occurred",
                        loading: false,
                    });
                }
            }
            if (pathname[1] == "numbersetdetail") {
                const params = new URLSearchParams(window.location.search);
                const poyid = params.get("poyid"); // ดึง trackId จาก param

                console.log(poyid);
                try {
                    const response = await instanceHuay.post("/api/v1/getpoydetail", { poyid });

                    if (response.status === 200 && response.data.success) {
                        this.setState({ poyDetail: response.data.data, isLoading: false });
                    } else {
                        this.setState({ isLoading: false });
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }

            if (pathname[1] in pathMap) {
                this.setState({ tabselect: pathMap[pathname[1]] });
            } else if (pathname[1] + "/" + pathname[2] === "refer/overview") {
                this.setState({ tabselect: 4, refer: 1 });
            } else if (pathname[1] + "/" + pathname[2] === "refer/member") {
                this.setState({ tabselect: 4, refer: 2 });
            } else if (pathname[1] + "/" + pathname[2] === "refer/income-summary") {
                this.setState({ tabselect: 4, refer: 3 });
            } else if (pathname[1] + "/" + pathname[2] === "refer/share-network") {
                this.setState({ tabselect: 4, refer: 4 });
            } else if (pathname[1] === "gamelist") {
                await this.getgame(pathname[2], pathname[3]);
                this.setState({ tabselect: 15, pathname: pathname[3] });
            }
            if (pathname[1] === "register") {
                setTimeout(() => {
                    loadCaptchaEnginge(4); // จำนวนตัวอักษร Captcha
                }, 0);
            }

            if (pathname[1] === "result-all") {
                this.fetchLotteryData();
            }

        } else {
            this.setState({ tabselect: 0 });
        }

        NProgress.done();
        window.scrollTo(0, 0);
    };

    async calllisthistory(userid) {
        await instance.post("/api/v1/userwithdrawlist", {
            userid: userid,
            Cat_ID: 0,
            System: Systems,
            page: 1,
            type: 1
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({ historylist: datas.withdrawlist });
            }
        });
    }
    fetchLotteryData = async () => {
        this.setState({ loading: true, error: null });
        try {
            const response = await instanceHuay.get(`/api/v1/getResult`, {
                params: { date: "20250113" }
            });
            console.log(`/api/v1/getResult`, response.data.success)
            if (response.data.success) {
                this.setState({ lotteryResultData: response.data.data, loading: false });
            } else {
                this.setState({ error: "Failed to fetch lottery results", loading: false });
            }
        } catch (err) {
            this.setState({
                error: err.response?.data?.error || "An error occurred",
                loading: false,
            });
        }
    };

    confirmdeposit = async (e, num) => {
        e.preventDefault();
        const token = localStorage.getItem('auth_token');
        if (!token) return;

        const decoded = jwt_decode(token);
        const Username = decoded.message.playerid;

        if (num === 0) {
            if (this.state.depositamount >= this.state.limit.min && this.state.depositamount <= this.state.limit.max) {
                await instance.post("/api/v1/getqrcode", {
                    username: Username,
                    System: Systems,
                    amount: this.state.depositamount
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).then((res) => {
                    console.log("mainacc", res.data);
                    this.setState({
                        mainacc: res.data.mainacc,
                        listacc: res.data.tblmain,
                        qrcode: res.data.tblpaymentgatewayx.QRcode,
                        paymentlist: res.data.tblpaymentgatewayx,
                        counter: 300,
                        qrcodecheck: true
                    });
                    this.notify("success", "กรุณาสแกน qrcode ภายใน 5 นาที");
                });
                this.setState({ depositstate: 1 });
            } else {
                this.notify("warning", `เติมขั้นต่ำ ${this.state.limit.min} บาท สูงสุด ${this.state.limit.max} บาท`);
            }
        } else if (num === 1) {
            this.setState({ depositstate: 1 });
        } else if (num === 5) {
            document.getElementById("truemonney").click();
        } else {
            this.setState({ depositstate: 0 });
        }
    };

    onChange = async (event) => {
        if (!event.target) return;

        const { name, value } = event.target;
        if (name === "withdrawamount") {
            if (this.state.maxwithdrawstatus === 1) {
                this.setState({ [name]: this.state.balance });
                this.notify("warning", "บังคับถอนยอดทั้งหมด");
            } else if (Number(value) <= Number(this.state.balance)) {
                this.setState({ [name]: value });
            } else {
                this.notify("warning", "ยอดถอนของท่านไม่เพียงพอในการถอน");
            }
        } else {
            this.setState({ [name]: value });
        }
    };

    submitwithdraw = async (e) => {
        if (parseFloat(this.state.withdrawamount) > parseFloat(this.state.balance)) {
            this.notify("warning", "ยอดถอนของท่านไม่เพียงพอในการถอน");
            return;
        }

        if (parseFloat(this.state.withdrawamount) < 10) {
            this.notify("warning", "ขั้นต่ำในการถอน 50 บาท");
            return;
        }

        const token = localStorage.getItem('auth_token');
        if (!token) {
            this.notify("error", "รายการถอนมีปัญหา โปรดติดต่อพนักงาน");
            return;
        }

        const decoded = jwt_decode(token);
        const Username = decoded.message.playerid;

        await instance.post("/api/v1/userwithdraw", {
            User_id: Username,
            System: Systems,
            amount: this.state.withdrawamount
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            if (res.data.status === 200) {
                this.notify("success", "รายการถอนเรียบร้อย");
                this.checkurl(e, "/statement");
            } else if (res.data.status === 203) {
                this.notify("error", `ท่านติด Turnover อีก ${res.data.amountturnover} บาท`);
            } else if (res.data.status === 207) {
                this.notify("error", `ขั้นต่ำในการถอน ${res.data.maxwithdraw} บาท`);
            } else if (res.data.status === 209) {
                this.notify("error", "คุณทำรายการถี่เกินไปโปรดรอ 3-5 นาที");
            } else if (res.data.status === 211) {
                this.notify("error", "ยอดเงินไม่เพียงพอ");
            }
            else if (res.data.status === 210) {
                this.notify("error", res.data.message);
            } else {
                this.notify("error", "ไม่สามารถทำรายการถอนได้ โปรดแจ้งพนักงาน");
            }
        });
    };

    async modalerror(object) {
        await Swal.fire({
            title: object.title,
            text: object.text,
            icon: "error",
            confirmButtonText: "ตกลง"
        });
    }

    validatergisterForm = async (e) => {
        e.preventDefault();
        const { phoneNumber, fname, password, passwordcf, checkboxacc, accnumbertxt, link } = this.state;

        if (password.length < 6) {
            this.modalerror({ title: 'Error!', text: 'Password คุณไม่ครบ 6 ตัวโปรดตรวจสอบ' });
            return;
        }
        if (!phoneNumber) {
            this.modalerror({ title: 'Error!', text: 'คุณไม่ทำการใส่ เบอร์โทรศัพท์' });
            return;
        }
        if (!accnumbertxt) {
            this.modalerror({ title: 'Error!', text: 'คุณไม่ทำการใส่เลขบัญชีธนาคาร' });
            return;
        }
        if (!fname) {
            this.modalerror({ title: 'Error!', text: 'คุณไม่ทำการใส่ ชื่อ-นามสกุล' });
            return;
        }
        if (checkboxacc === 0) {
            this.modalerror({ title: 'Error!', text: 'คุณยังไม่ได้เลือกธนาคาร' });
            return;
        }
        if (password !== passwordcf) {
            this.modalerror({ title: 'Error!', text: 'คุณใส่พาสเวิดไม่ตรงกัน' });
            return;
        }

        await instance.post("/api/v1/register", {
            User_id: phoneNumber,
            name: fname,
            password: password,
            accbank: checkboxacc,
            accno: accnumbertxt,
            System: Systems,
            link: link,
            Useradvise: this.state.userrecomend
        }).then(async (res) => {
            if (res.data.status === 200) {
                Swal.fire({ title: 'Success!', text: 'ทำการสมัครเรียบร้อย', icon: 'success', confirmButtonText: 'ตกลง' });
                await instance.post("/api/v1/userlogin", {
                    userid: phoneNumber,
                    password: password,
                    System: Systems,
                    Function: 'validateLoginForm-navbar.js'
                }).then((loginRes) => {
                    if (loginRes.data.status === 200) {
                        localStorage.setItem('auth_token', loginRes.data.token);
                        window.location.href = '/';
                    }
                });
            } else {
                this.setState({
                    errorstatus: 1,
                    errortext: res.data.status === 202 ? 'Username นี้ทำการสมัครไปแล้ว' : 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
                });
            }
        });
    };

    resetpassword = async (e, type) => {
        e.preventDefault();
        const { phoneNumber, password, oldpassword, passwordcf } = this.state;
        if (password !== passwordcf) {
            this.notify("error", 'คุณใส่พาสเวิดไม่ตรงกัน');
            return;
        }

        const token = localStorage.getItem('auth_token');
        let username = phoneNumber;

        if (type === 1 && token) {
            const decoded = jwt_decode(token);
            username = decoded.message.playerid;
        }

        await instance.post("/api/v1/resetpassword", {
            username,
            oldpass: oldpassword,
            newpass: password,
            System: Systems,
            type
        }, {
            headers: token ? { Authorization: `Bearer ${token}` } : {}
        }).then((res) => {
            const message = {
                200: 'เปลี่ยนพาสเวิดเรียบร้อย',
                201: 'คุณใส่รหัสเก่าผิดพลาด',
                202: 'คุณใช้พาสเวิดเดิม',
                203: 'มีข้อผิดพลาด โปรดทำรายการอีกครั้ง'
            };
            this.notify(res.data.status === 200 ? "success" : "error", message[res.data.status]);
        });
    };

    async opencliaminvite(evt, cityName) {
        const token = localStorage.getItem('auth_token');
        if (!token) return;

        const decoded = jwt_decode(token);
        const Username = decoded.message.playerid;

        await instance.post("/api/v1/cliaminvite", {
            userid: Username,
            System: Systems
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            if (res.data.status === 200) {
                this.rechecklogin(Username, token);
                this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
            } else {
                this.notify("error", res.data.status === 201 ? 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน' : 'ไม่สามารถทำรายการได้');
            }
        });
    }

    handleClick = async (e, price) => {
        if (price <= this.state.balance) {
            this.setState({ withdrawamount: price });
        } else {
            this.notify("warning", 'ยอดถอนของท่านไม่เพียงพอในการถอน');
        }
    };

    handleClickreposit = async (e, price) => {
        this.setState({ depositamount: price });
    };

    redeempro = async (e, proid) => {
        e.preventDefault();
        const token = localStorage.getItem('auth_token');
        if (!token) return;

        const decoded = jwt_decode(token);
        const Username = decoded.message.playerid;

        await instance.post("/api/v1/redeempromotion", {
            userid: Username,
            System: Systems,
            Proid: proid
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({ showmodal: false });
            } else {
                const messages = {
                    201: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                    202: 'ท่านใช้โปรมั่นชั่นนี้ไปแล้ว',
                    204: 'ท่านใช้สิทธวันนี้ครบแล้ว'
                };
                this.notify("error", messages[res.data.status]);
            }
        });
    };


    // Handle tab change
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab }, () => {
            this.fetchPoyList(tab, this.state.username);
        });
    };

    // Handle dropdown filter change
    handleFilterChange = (event) => {
        const filter = event.target.value;
        this.setState({ selectedFilter: filter }, () => {
            this.fetchPoyList(this.state.activeTab, this.state.username);
        });
    };


    toggleDropdown = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    toggleDropdownxx = (id) => {
        this.setState((prevState) => ({
            activeDropdown: prevState.activeDropdown === id ? null : id,
        }));
    };
    handleOutsideClick = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    };
    handleSelection = (text, index) => {
        this.setState({ selectedText: text, isOpen: false, filters: index });
    };


    redeem = (e, gameIdx, partner) => {
        const userAgent = navigator.userAgent;
        this.checklogin();

        if (!this.state.loginstate) {
            this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
            return;
        }

        if (this.state.username) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false,
            }).then((res) => {
                if (res.data.status === 200) {
                    const launcher = res.data.message.launcher;
                    if (launcher) {
                        if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                            window.location.href = launcher.mobile;
                        } else {
                            isMobile
                                ? window.open(launcher.mobile)
                                : window.open(launcher.desktop, '_blank', 'height=1000,width=1000');
                        }
                    } else {
                        Swal.fire({
                            title: 'error!',
                            text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                            icon: 'error',
                            confirmButtonText: 'ตกลง',
                        });
                    }
                }
            });
        }
    };

    redeemlotto = (e, _id, roomid) => {
        const userAgent = navigator.userAgent;
        this.checklogin();

        if (!this.state.loginstate) {
            this.notify("error", 'คุณยังไม่ได้เข้าสู่ระบบ');
            return;
        }

        if (this.state.username) {
            instance.post("/api/v1/tablauncherlotto", {
                roomid: roomid,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false,
            }).then((res) => {
                if (res.data.status === 200) {
                    const launcher = res.data.message.launcher;
                    if (launcher) {
                        if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                            window.location.href = launcher.mobile;
                        } else {
                            isMobile
                                ? window.open(launcher.mobile)
                                : window.open(launcher.desktop, '_blank', 'height=1000,width=1000');
                        }
                    } else {
                        Swal.fire({
                            title: 'error!',
                            text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                            icon: 'error',
                            confirmButtonText: 'ตกลง',
                        });
                    }
                }
            });
        }
    };

    couponredeem = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('auth_token');
        if (token) {
            const decoded = jwt_decode(token);
            const Username = decoded.message.playerid;
            await instance.post("/api/v1/usercoupon", {
                User_id: Username,
                System: Systems,
                couponkey: this.state.couponkey,
                type: 1,
            }).then((res) => {
                if (res.data.status === 200) {
                    this.notify("success", 'ดำเนินการใช้คูปองเรียบร้อย');
                } else {
                    this.notify("warning", 'ไม่มี Key นี้ในระบบ');
                }
            });
        } else {
            this.notify("warning", 'คุณไม่ได้ล๊อคอิน');
        }
    };

    copy = async (e, accnumber) => {
        toast.success("คัดลอกเรียบร้อย", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        navigator.clipboard.writeText(accnumber);
    };

    callgetthemesv = async (Systems) => {
        await instance.post("/api/v1/getthemesv", { System: Systems }).then((res) => {
            if (res.data.status === 200) {
                document.body.setAttribute("data-theme", res.data.ThmeseV);
                this.setState({ ThmeseV: res.data.ThmeseV });
            }
        });
    };

    getgame = async (typepage, partner) => {
        await instance.post("/api/v1/tabgamelist", {
            state: "999",
            System: Systems,
            partner: partner,
            game: typepage,
        }).then((res) => {
            if (res.data.status === 200) {
                this.setState({ listitallgame: res.data.message });
            }
        });
    };

    getcallprovidor = async () => {
        const requests = [
            { state: "2", key: "categorylist" },
            { state: "3", key: "slotlist" },
            { state: "5", key: "sportlist" },
            { state: "8", key: "fishinglist" },
        ];

        await Promise.all(
            requests.map(({ state, key }) =>
                instance.post("/api/v1/tabgamelist", { state, System: Systems }).then((res) => {
                    if (res.data.status === 200) {
                        this.setState({ [key]: res.data.message });
                    }
                })
            )
        );
    };
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval); // ล้าง interval เมื่อ component ถูก unmount
        }
        document.removeEventListener("click", this.handleOutsideClick);
    }
    startCountdown = (lotteryData) => {
        this.interval = setInterval(() => {
            const newCountdowns = {};
            lotteryData.filter((item) => item.status === 1).slice(0, 5).forEach((item) => {
                if (!item.roomid) {
                    console.error("Item is missing an ID:", item);
                    return; // ข้ามรายการที่ไม่มี id
                }
                //console.log(item.drawTime);
                const drawTime = new Date(item.drawTime).getTime();
                const now = new Date().getTime();
                const diff = drawTime - now;

                if (diff > 0) {
                    const hours = Math.floor(diff / (1000 * 60 * 60)).toString().padStart(2, "0");
                    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, "0");
                    const seconds = Math.floor((diff % (1000 * 60)) / 1000).toString().padStart(2, "0");
                    newCountdowns[item.roomid] = `${hours}:${minutes}:${seconds}`;
                } else {
                    newCountdowns[item.roomid] = "หมดเวลา";
                }
            });

            this.setState({ countdowns: newCountdowns });
        }, 1000);
    };



    checktab = (e, index) => {
        const isDepositTab = index === 2 && this.state.qrcode;
        this.setState({
            tabselectdeposit: isDepositTab ? 0 : index,
            tabselectsec: isDepositTab ? 1 : 0,
        });
    };


    checkuseradvice = async () => {
        console.log("checkuseradvice")
        try {
            const response = await instance.post("/api/v1/checkuseradvice", {
                User_id: this.state.username,
                page: 1,
                System: Systems,
            });
            console.log(response.data)
            if (response.data.status === 200) {

                this.setState({ useradvice: response.data.message.useradvicelist });
            }
        } catch (error) {
            console.error("Error fetching user advice:", error);
        }
    };


    rechecklogin = async (Username, token) => {
        try {

            //const { webnamex } = this.state
            //  console.log(1406, webnamex);
            //console.log(3);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Update: userhash,
                Function: 'rechecklogin-App.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                // //console.log(res.data);
                if (res.data.status === 200) {
                    // this.calllisthistory(Username);
                    //console.log(443, res.data);
                    localStorage.setItem('auth_token', res.data.token)
                    var rankall = res.data.tblrankall;
                    const found = rankall.find(obj => {
                        return obj.checked === 1;
                    });

                    let sumcash = 0
                    for (let x = 0; x < res.data.usercashoutlist.length; x++) {
                        sumcash += res.data.usercashoutlist[x].amount
                    }
                    this.setState({
                        tblrankall: res.data.tblrankall,
                        linkaff: linkaff,
                        buttoncliam: (found === undefined) ? true : false,
                        tblnextrank: res.data.tblnextrank,
                        rankpic: (res.data.tblrank.image == null || res.data.tblrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (res.data.tblrank.picrack == null || res.data.tblrank.picrack == "") ? "https://betner.co/build/rank/" + res.data.tblrank.image : res.data.tblrank.picrack,
                        rankname: res.data.tblrank.name,
                        rankob: res.data.tblrank,
                        tblprofile: res.data.message,
                        usercashoutlist: res.data.usercashoutlist,
                        sumcash: sumcash,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            ////////console.log(ex);
        }

    }
    checkaccdeposit = (listItem) => {
        this.setState({ mainacc: listItem });
    };

    handleSearch = (e) => {
        const searchQuery = e.target.value.toLowerCase();
        this.setState({ searchQuery });

        if (!searchQuery) {
            // ถ้าไม่มีข้อความค้นหา ให้ใช้ข้อมูลเดิม
            this.setState({ filteredGroupedLotteryData: null });
            return;
        }

        // กรองข้อมูลจาก `groupedLotteryData`
        const filteredData = Object.keys(this.state.groupedLotteryData).reduce(
            (acc, type) => {
                const filteredItems = this.state.groupedLotteryData[type].filter((item) =>
                    item.name.toLowerCase().includes(searchQuery)
                );
                if (filteredItems.length > 0) {
                    acc[type] = filteredItems;
                }
                return acc;
            },
            {}
        );

        this.setState({ filteredGroupedLotteryData: filteredData });
    };

    handleSearchx = (e) => {
        this.setState({ searchQuery: e.target.value });
    };

    fetchPoyListxx = async (playerid) => {
        this.setState({ isLoading: true, error: null });
        try {
            const response = await instanceHuay.post("/api/v1/getpoylist", {
                playerid: playerid,
                system: Systems.toLowerCase(),
            });
            if (response.status === 200 && response.data.success) {
                this.setState({ numberSets: response.data.data, isLoading: false });
            } else {
                this.setState({ isLoading: false });
            }
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };


    handleItemsPerPageChange = (e) => {
        this.setState({ itemsPerPage: parseInt(e.target.value, 10) });
    };

    deleteNumberSet = async (id) => {
        try {
            const response = await instance.post('/api/v1/deletepoylist', { id });

            if (response.status === 200 && response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'ลบสำเร็จ',
                    text: 'รายการถูกลบเรียบร้อยแล้ว!',
                });
                // อัปเดตสถานะของหน้าหลังลบ
                this.setState((prevState) => ({
                    numberSets: prevState.numberSets.filter((set) => set._id !== id),
                }));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'ไม่สามารถลบได้',
                    text: response.data.message || 'เกิดข้อผิดพลาด!',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'ข้อผิดพลาด',
                text: error.message || 'ไม่สามารถลบรายการนี้ได้!',
            });
        }
    };


    checkpro = async (e, proid) => {
        try {
            const token = localStorage.getItem('auth_token');
            const Username = token ? jwt_decode(token).message.playerid : "";

            const response = await instance.post(
                "/api/v1/checkpromotion",
                { userid: Username, System: Systems, Proid: proid },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.status === 200) {
                if (response.data.type === 0) {
                    this.setState({ showmodal: true, prodetail: response.data.message.tblpromotions[0] });
                } else if (response.data.type === 1) {
                    this.setState({ showmodal: true, proderedeem: response.data.message.tblpromotionredeems });
                }
            } else if (response.data.status === 201) {
                this.setState({
                    showmodal: true,
                    prodetail: [],
                    picnonpro: "/media/cache/strip/202109/promotion/7AB3F352-6DE2-44CC-A5FC-D4359FCB2122.png",
                });
            } else if (response.data.status === 202) {
                this.notify("success", 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย');
            }
        } catch (error) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                localStorage.clear();
                this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            } else {
                console.error("Error checking promotion:", error);
            }
        }
    };

    validateLogout = () => {
        localStorage.removeItem('auth_token');
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
        return true;
    };

    logout = () => {
        const isValid = this.validateLogout();
        if (isValid) {
            window.location.reload();
        } else {
            this.setState({ errors: true, formSubmitted: true });
            window.location.reload();
        }
    };

    waitnotify = () => {
        return new Promise(resolve => setTimeout(resolve, 5000));
    };

    notify = async (mode, msg) => {
        const options = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        };

        switch (mode) {
            case "success":
                await toast.success(msg, options);
                break;
            case "warning":
                await toast.warning(msg, options);
                break;
            case "error":
                await toast.error(msg, options);
                break;
            case "info":
                await toast.info(msg, options);
                break;
            default:
                break;
        }

        await this.waitnotify();
    };

    openClaimCashOut = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            const Username = jwt_decode(token).message.playerid;

            const response = await instance.post(
                "/api/v1/cliamcashout",
                { userid: Username, System: Systems },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.status === 200) {
                this.rechecklogin(Username, token);
                this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
            } else if (response.data.status === 201) {
                this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
            } else if (response.data.status === 202) {
                this.notify("error", 'ไม่สามารถทำรายการได้');
            }
        } catch (error) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                localStorage.clear();
                this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            } else {
                console.error("Error during cashout claim:", error);
            }
        }
    };

    render() {
        const {
            // Group 1: UI State
            activeTab,
            poyDetail,
            selectedFilter,
            isOpen,
            selectedText,
            loading,
            error,
            tabselect,
            countdownMain,
            countdowns,
            itemsPerPage,
            searchQuery,

            // Group 2: Data State
            poyLists,
            buyListDetail,
            numberSets,
            groupedLotteryData,
            lotteryData,
            lottofeature,
            pathname,
            categorylist,
            fishinglist,
            sportlist,
            slotlist,
            lottolist,
            listitallgame,

            // Group 3: User State
            loginstate,
            userid,
            username,
            point,
            firstname,
            lastname,
            balance,
            proderedeemlist,
            linkaff,
            tblprofile,
            limit,
            lang,
            tabregis,
            accnumber,
            paymentgateway,
            lotteryResultData,
            usercashoutlist,
            mainacc,
            trueaccount,
            listacc,
        } = this.state;
        const { isInstallable } = this.state;
        console.log("usercashoutlist", usercashoutlist)
        // Additional Processing
        const filteredData = lotteryResultData.filter(
            (item) => item.productCode === "TH" || item.productCode === "AOM" || item.productCode === "BAAC"
        );
        const foreignLotteries = lotteryResultData.filter(
            (item) => item.productCode === "TH" && item.productCode === "AOM"
        );
        const limits = limit;
        const accnumberx = accnumber ? accnumber[0] : null;
        const filteredSets = numberSets.filter((set) =>
            set?.poyname?.toLowerCase().includes(searchQuery.toLowerCase()) // ใช้ `poyname` แทน `name`
        );
        let { updatedTableData = [], updatedTableData2 = [], updatedTableData3 = [], updatedTableData4 = [] } = null || {};

        if (tabselect == 33) {
            const ensureTableData = (data, rows = 6, columns = 50) => {
                const defaultCell = { number: '', color: '' };

                // Ensure each row has the correct number of columns
                const formattedRows = data.map(row => {
                    if (!row) return Array(columns).fill(defaultCell);
                    return [...row, ...Array(columns - row.length).fill(defaultCell)].slice(0, columns);
                });

                // Ensure there are the correct number of rows
                const formattedTable = [
                    ...formattedRows,
                    ...Array(rows - formattedRows.length).fill(Array(columns).fill(defaultCell))
                ].slice(0, rows);

                return formattedTable;
            };
            const tableData = [
            ];
            updatedTableData = ensureTableData(tableData);


            const ensureTableData2 = (data, rows = 6, columns = 50) => {
                const defaultCell = { number: '', color: '' };

                // Ensure each row has the correct number of columns
                const formattedRows = data.map(row => {
                    if (!row) return Array(columns).fill(defaultCell);
                    return [...row, ...Array(columns - row.length).fill(defaultCell)].slice(0, columns);
                });

                // Ensure there are the correct number of rows
                const formattedTable = [
                    ...formattedRows,
                    ...Array(rows - formattedRows.length).fill(Array(columns).fill(defaultCell))
                ].slice(0, rows);

                return formattedTable;
            };
            const tableData2 = [
            ];
            updatedTableData2 = ensureTableData2(tableData2);


            const ensureTableData3 = (data, rows = 6, columns = 50) => {
                const defaultCell = { number: '', color: '' };

                // Ensure each row has the correct number of columns
                const formattedRows = data.map(row => {
                    if (!row) return Array(columns).fill(defaultCell);
                    return [...row, ...Array(columns - row.length).fill(defaultCell)].slice(0, columns);
                });

                // Ensure there are the correct number of rows
                const formattedTable = [
                    ...formattedRows,
                    ...Array(rows - formattedRows.length).fill(Array(columns).fill(defaultCell))
                ].slice(0, rows);

                return formattedTable;
            };
            const tableData3 = [
            ];
            updatedTableData3 = ensureTableData(tableData3);


            const ensureTableData4 = (data, rows = 6, columns = 50) => {
                const defaultCell = { number: '', color: '' };

                // Ensure each row has the correct number of columns
                const formattedRows = data.map(row => {
                    if (!row) return Array(columns).fill(defaultCell);
                    return [...row, ...Array(columns - row.length).fill(defaultCell)].slice(0, columns);
                });

                // Ensure there are the correct number of rows
                const formattedTable = [
                    ...formattedRows,
                    ...Array(rows - formattedRows.length).fill(Array(columns).fill(defaultCell))
                ].slice(0, rows);

                return formattedTable;
            };
            const tableData4 = [
            ];
            updatedTableData4 = ensureTableData4(tableData4);

        }
        const maxRows = 5;
        const filledRows = lotteryData.filter((item) => item.status === 1).slice(0, maxRows).map((item, index) => (
            <div
                key={item.roomid} // ใช้ roomid เป็น key
                style={{ width: "100%", height: "auto" }}
                className="ng-star-inserted"
            >
                <div className={`row mx-3 mt-2 mb-0 ${index === 0 ? "bg-near-close-lotto-detail" : "bg-near-close-lotto-detail-white"}`}>
                    <div className="col-7 ng-star-inserted">
                        <div className="row">
                            <div className="remain-time-main col-6 py-1 px-1 ng-star-inserted">
                                <svg
                                    className='pr-1'
                                    width="22.08"
                                    fill="#000000"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 345.57 345.57"
                                    xmlSpace="preserve"
                                >
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                    <g id="SVGRepo_iconCarrier">
                                        <g>
                                            <g>
                                                <path d="M172.785,0h-15.583v82.164h31.156V32.018c71.392,7.773,126.035,67.902,126.035,140.768 c0,78.096-63.531,141.626-141.608,141.626c-78.08,0-141.608-63.53-141.608-141.626c0-31.963,10.386-62.123,30.024-87.211 l5.491-6.995L42.145,59.37l-5.479,6.996C12.679,96.993,0,133.796,0,172.785C0,268.054,77.507,345.57,172.785,345.57 c95.269,0,172.785-77.504,172.785-172.785C345.57,77.507,268.054,0,172.785,0z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                {countdowns[item.roomid] || "กำลังโหลด..."}
                            </div>
                            <div className={`pl-1 col-6 pl-2  ${index === 0 ? "lotto-name-main-white ng-star-inserted" : "lotto-name-main-black ng-star-inserted"}`}>
                                {item.name}
                            </div>
                        </div>
                    </div>
                    <a
                        onClick={(e) => this.redeemlotto(e, item._id, item.roomid)}
                        role="button"
                        className="image-btn-effect2 col-5 px-0 ng-star-inserted"
                        style={{ alignSelf: "center" }}
                    >
                        <div
                            className="row ng-star-inserted"
                            style={{ justifyContent: "right" }}
                        >
                            <div
                                className="col-3 px-0 ng-star-inserted"
                                style={{ alignSelf: "center", textAlignLast: "right" }}
                            >
                                <img className="flag-near-close-lotto" src={item.flagimage} alt={item.name} />
                            </div>
                            <div className={`col-5 px-3 mx-3  ${index === 0 ? " bg-link-product-main image-btn-effect2" : "bg-link-product-main-green"}`}>
                                <div className="px-0 flag-word-main">แทงเลย</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        ));
        const emptyRows = Array.from({ length: maxRows - lotteryData.length }, (_, i) => (
            <div
                key={`empty-${i}`}
                style={{ width: "100%", height: "auto" }}
                className="ng-star-inserted"
            >
                <div className="row mx-3 mt-2 mb-0 bg-near-close-lotto-detail-white">
                    <div className="col-7 ng-star-inserted">
                        <div className="row">
                            <div className="remain-left-red-none col-6 py-1 px-1 ng-star-inserted">
                                <i className="far fa-alarm-clock pr-1" style={{ fontSize: "large" }} />
                                00:00:00
                            </div>
                            <div className="pl-1 col-6 px-0 lotto-name-main-none ng-star-inserted">
                                -----
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
        if (loading || loginstate == null) {
            return (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh", zIndex: 999 }}>
                    <ClipLoader size={50} color={"#123abc"} loading={loading || loginstate == null} />
                </div>
            );
        }


        const dataToDisplay =
            this.state.filteredGroupedLotteryData || groupedLotteryData;
        // สร้างแถวที่มีข้อมูล
        const filledRowsFeature = lottofeature.map((item, index) => (
            <div key={item.roomid} className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 ng-star-inserted">
                <div className="rounded border-top-lotto">
                    <div className="top">
                        <a href="#" data-toggle="modal" data-target="#loginPopup">
                            <img
                                className="img-top-lotto-login"
                                src={item.image || "/build/web/igame-index-lobby-wm/img/login-th.png"}
                                alt={item.name}
                            />
                        </a>
                        <div className="detail-top-lotto">
                            <div className="d-flex flex-row justify-content-between my-0">
                                <button className="col-8 btn btn-period-text-login p-0 d-flex" style={{ justifyContent: "space-around" }}>
                                    <div style={{ fontWeight: "bold" }}>งวดวันที่</div>
                                </button>
                                <button className="col-4 btn btn-remain-text-login p-0">
                                    เหลือเวลา
                                </button>
                            </div>
                            <div className="d-flex flex-row">
                                {item.isAvailable ? (
                                    <div className="lotto-ramian-time-login time-government" style={{ color: "green" }}>
                                        <i className="fas fa-check pr-1" style={{ fontSize: "medium", alignSelf: "center" }} />
                                        <span style={{ fontSize: "1.1rem" }}>เปิดรับแทง</span>
                                    </div>
                                ) : (
                                    <div className="lotto-ramian-time-login time-government" style={{ color: "gray" }}>
                                        <i className="fas fa-ban pr-1" style={{ fontSize: "medium", alignSelf: "center" }} />
                                        <span style={{ fontSize: "1.1rem" }}>ยังไม่รับแทง</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="bg-flag-on-top-lotto" >
                        <img className="flag-on-top-lotto"
                            src={item.flagimage || "/build/web/igame-index-lobby-wm/img/TH.png"}
                            alt="flag"
                        />
                    </div>
                    <a href="#" data-toggle="modal" data-target="#loginPopup">
                        <div className="name-top-lotto-login ng-star-inserted">{item.name}</div>
                    </a>
                </div>
            </div>
        ));

        const filledRowsx = lotteryData.filter((item) => item.status === 1).slice(0, maxRows).map((item, index) => (
            <div className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 ng-star-inserted" key={index}>
                <div className="rounded border-top-lotto">
                    <div className="top">
                        <a href="#" data-toggle="modal" data-target="#loginPopup">

                            <img
                                className="img-top-lotto-login"
                                src={item.image}
                                alt={item.name}
                            />

                        </a>
                        <div className="detail-top-lotto">
                            <div className="d-flex flex-row justify-content-between my-0">
                                <button className="col-8 btn btn-period-text-login p-0 d-flex" style={{ justifyContent: "space-around" }}>
                                    <div style={{ fontWeight: "bold" }}>งวดวันที่</div>
                                </button>
                                <button className="col-4 btn btn-remain-text-login p-0">
                                    เหลือเวลา
                                </button>
                            </div>
                            <div className="d-flex flex-row">
                                {item.isAvailable ? (
                                    <div className="lotto-remain-time-login time-government ng-star-inserted" style={{ color: "green" }}>
                                        <i className="fas fa-check pr-1" style={{ fontSize: "medium", alignSelf: "center" }} />
                                        <span style={{ fontSize: "1.1rem" }}>เปิดรับแทง</span>
                                    </div>
                                ) : (
                                    <div className="lotto-ramian-time-login time-government ng-star-inserted" style={{ color: "gray" }}>
                                        <i className="fas fa-ban pr-1" style={{ fontSize: "medium", alignSelf: "center" }} />
                                        <span style={{ fontSize: "1.1rem" }}>ยังไม่รับแทง</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="bg-flag-on-top-lotto">
                        <img className="flag-on-top-lotto" src={item.flagimage || "/build/web/igame-index-lobby-wm/img/TH.png"} />
                    </div>
                    <a href="#" data-toggle="modal" data-target="#loginPopup">
                        <div
                            className="name-top-lotto-login ng-star-inserted"
                        >
                            {item.name}
                        </div>
                    </a>
                </div>
            </div>
        ));
        // สร้างแถวเปล่า (Empty Rows) หากจำนวนแถวน้อยกว่า 5
        const emptyRowsx = Array.from({ length: maxRows - filledRows.length }, (_, i) => (
            <div
                key={`empty-${i}`}
                style={{ width: "100%", height: "auto" }}
                className="ng-star-inserted"
            >
                <div className="d-flex mx-0 mx-lg-3 mt-1 mt-lg-2 mb-0 bg-near-close-lotto-detail-close bg-near-close-lotto-detail">
                    <div className="space-box-white-remain-time-close ng-star-inserted">
                        <div className="lotto-name-main-none">
                            -----
                        </div>
                        <div className="bg-main-remain-time">
                            <div className="remain-time-main-none ng-star-inserted">
                                <i
                                    className="far fa-alarm-clock pr-1"
                                    style={{ fontSize: "large" }}
                                />
                                00:00:00
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));


        const filledRowsMobile = lotteryData.filter((item) => item.status === 1).slice(0, maxRows).map((item, index) => (
            <div
                key={item.roomid} // ใช้ roomid เป็น key
                style={{ width: "100%", height: "auto" }}
                className="ng-star-inserted"
            >
                <div

                    className="d-flex mx-0 mx-lg-3 mt-1 mt-lg-2 mb-0 bg-near-close-lotto-detail-1"
                >
                    <div

                        className="space-box-white-remain-time ng-star-inserted"
                    >
                        <div className="lotto-name-main-white">
                            {item.name}{" "}
                        </div>
                        <div className="bg-main-remain-time">
                            <div

                                className="remain-left-red ng-star-inserted"
                            >
                                <svg
                                    className='pr-1'
                                    width="22.08"
                                    fill="#000000"
                                    version="1.1"
                                    id="Capa_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 345.57 345.57"
                                    xmlSpace="preserve"
                                >
                                    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                    <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <g>
                                            {" "}
                                            <g>
                                                {" "}
                                                <g>
                                                    {" "}
                                                    <path d="M172.785,0h-15.583v82.164h31.156V32.018c71.392,7.773,126.035,67.902,126.035,140.768 c0,78.096-63.531,141.626-141.608,141.626c-78.08,0-141.608-63.53-141.608-141.626c0-31.963,10.386-62.123,30.024-87.211 l5.491-6.995L42.145,59.37l-5.479,6.996C12.679,96.993,0,133.796,0,172.785C0,268.054,77.507,345.57,172.785,345.57 c95.269,0,172.785-77.504,172.785-172.785C345.57,77.507,268.054,0,172.785,0z" />{" "}
                                                </g>{" "}
                                                <g>
                                                    {" "}
                                                    <path d="M147.949,199.257c5.645,6.768,13.577,10.924,22.314,11.704c1.009,0.096,2.017,0.144,3.008,0.144 c16.858,0,31.264-13.174,32.777-29.97c0.792-8.749-1.874-17.297-7.512-24.073c-5.645-6.776-13.571-10.938-22.332-11.736 c-2.88-0.252-5.801-0.099-8.905,0.511l-95.167-80.65l69.908,102.736c-0.879,2.558-1.414,4.945-1.625,7.269 C139.624,183.939,142.299,192.49,147.949,199.257z" />{" "}
                                                </g>{" "}
                                            </g>{" "}
                                        </g>{" "}
                                    </g>
                                </svg>

                                {countdowns[item.roomid] || "กำลังโหลด..."}
                            </div>


                        </div>
                    </div>


                    <a

                        onClick={(e) => this.redeemlotto(e, item._id, item.roomid)}
                        role="button"
                        className="image-btn-effect2 ng-star-inserted"
                        style={{ alignSelf: "center" }}
                    >
                        <div

                            style={{ justifyContent: "space-around", display: "flex" }}
                        >
                            <div className="ng-star-inserted">
                                <div

                                    className="d-flex px-1 image-btn-effect3 bg-link-product-main blink1"
                                >
                                    <div

                                        style={{ alignSelf: "center" }}
                                        className="ng-star-inserted"
                                    >
                                        <img

                                            className="flag-near-close-lotto"
                                            src={item.flagimage}
                                        />
                                    </div>


                                    <div

                                        className="col-8 px-0 flag-word-main"
                                    >
                                        {" "}
                                        แทงเลย{" "}
                                    </div>
                                </div>
                                <div

                                    className="close-time-near-close-lotto"
                                >
                                    ปิดรับ 23:00
                                </div>
                            </div>


                        </div>
                    </a>

                </div>
            </div>
        ));
        const emptyRowsMobile = Array.from({ length: maxRows - filledRows.length }, (_, i) => (
            <div
                key={`empty-${i}`}
                style={{ width: "100%", height: "auto" }}
                className="ng-star-inserted"
            >
                <div className="d-flex mx-0 mx-lg-3 mt-1 mt-lg-2 mb-0 bg-near-close-lotto-detail-close bg-near-close-lotto-detail">
                    <div className="space-box-white-remain-time-close ng-star-inserted">
                        <div className="lotto-name-main-none">
                            -----
                        </div>
                        <div className="bg-main-remain-time">
                            <div className="remain-time-main-none ng-star-inserted">
                                <i
                                    className="far fa-alarm-clock pr-1"
                                    style={{ fontSize: "large" }}
                                />
                                00:00:00
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));


        // console.log(filledRowsMobile)

        return (
            <>
                <div id="app" style={{ minHeight: "calc(100% - 60px)", overflow: "hidden", display: tabselect == 0 || tabselect == 42 || tabselect == 43 || tabselect == 41 || tabselect == 40 || tabselect == 37 || tabselect == 38 || tabselect == 39 || tabselect == 15 || tabselect == 9 || tabselect == 1 || tabselect == 2 || tabselect == 14 || tabselect == 17 || tabselect == 27 || tabselect == 25 || tabselect == 3 || tabselect == 16 || tabselect == 32 || tabselect == 33 || tabselect == 36 || tabselect == 34 || tabselect == 35 || tabselect == 28 ? "" : "none" }} >
                    <div className="custom-bg" />
                    <div className="bg-app">
                        <div className="mobile-viewx" style={{ display: isMobile ? "" : "none" }}  >
                            <Slider {...settings}>
                                <div>
                                    <img
                                        alt="ผลหวยฮานอย vip พิเศษ"
                                        className="d-block w-100 border-banner-register"
                                        src="https://cdn.javisx.com/image/d05ca367-9209-43bc-a372-cb23f5fb5f9b.jpg"
                                    />
                                </div>
                                <div>
                                    <img
                                        alt="ผลหวยฮานอย vip พิเศษ"
                                        className="d-block w-100 border-banner-register"
                                        src="https://cdn.javisx.com/image/ed032646-2f7d-4cb9-83ba-9c2e7505cd55.jpg"
                                    />
                                </div>
                                <div>
                                    <img
                                        alt="ผลหวยฮานอย vip พิเศษ"
                                        className="d-block w-100 border-banner-register"
                                        src="https://cdn.javisx.com/image/347caed9-c446-42ec-9b03-a1bff067ec28.jpg"
                                    />
                                </div>
                            </Slider>
                        </div>

                        <div className="container px-0" hidden={this.state.loginstate}>
                            <div
                                className="pc-view row p-5 p-md-0 px-xs-2 justify-content-center"
                            >
                                <div
                                    className="col-sm-12 col-md-12 col-lg-3 py-1 pc-view"
                                    style={{
                                        alignSelf: "center",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <img

                                        src="/build/web/igame-index-lobby-wm/img/logo.png"
                                        className="mt-0"
                                        style={{ height: "3.2rem", width: "auto" }}
                                        alt="Lotto888"
                                        title="Lotto888"
                                    />
                                </div>
                                <div

                                    className="col-sm-12 col-md-8 col-lg-9 px-4"
                                    style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                    <form

                                        noValidate=""
                                        id="login2"
                                        autoComplete="off"
                                        role="form"
                                        acceptCharset="utf-8"
                                        className="form-login form-horizontal ng-dirty ng-touched ng-valid"
                                        data-gtm-form-interact-id={0}
                                    >
                                        <div className="row form-middle">
                                            <div className="col-lg" />
                                            <div

                                                className="col-sm-12 col-md-6 col-lg-3 p-1 mt-1"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="dvImageTextBox">
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/telephone.svg"
                                                        />
                                                        <input
                                                            id="username"
                                                            type="number"
                                                            name="username"
                                                            inputMode="text"
                                                            onChange={this.onChange}
                                                            placeholder="เบอร์โทรศัพท์"
                                                            className="form-control login-btn ng-valid ng-dirty ng-touched"
                                                            data-gtm-form-interact-field-id={0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div

                                                className="col-sm-12 col-md-6 col-lg-3 p-1 mt-1"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="d-flex mb-0">
                                                        <div

                                                            className="dvImageTextBox box-pwd position-relative"
                                                        >
                                                            <img

                                                                src="/build/web/igame-index-lobby-wm/img/lock.svg"
                                                                className="lock-icon"
                                                            />
                                                            <input
                                                                type="password"
                                                                id="password"
                                                                name="password"
                                                                onChange={this.onChange}
                                                                placeholder="รหัสผ่าน"
                                                                required=""
                                                                className="form-control ng-dirty ng-valid ng-touched"
                                                                style={{
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                    paddingLeft: 35
                                                                }}
                                                                data-gtm-form-interact-field-id={1}
                                                            />
                                                        </div>
                                                        <div className="input-group-append">
                                                            <div

                                                                className="input-group-text style-eye"
                                                            >
                                                                <i

                                                                    className="fa fa-eye-slash"
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-2 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.login(e)
                                                    }}
                                                    type="submit"
                                                    className="btn btn-gradiant-login btn-block"
                                                >
                                                    {" "}
                                                    เข้าสู่ระบบ{" "}
                                                </a>
                                            </div>
                                            <div className="col-6 col-lg-2 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/register")
                                                    }}
                                                    type="button"
                                                    className="btn btn-login-white btn-block d-flex justify-content-center"
                                                >
                                                    {" "}
                                                    สมัครสมาชิก{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-2 custombglogin">
                                            <div className="col-lg pc-view" />
                                            <div

                                                className="col-sm-12 col-md-7 col-lg-6 rule pc-view"
                                            >
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#ModalRule"
                                                    className="m-2"
                                                >
                                                    กฏกติกา
                                                </a>
                                                <a

                                                    href="#"
                                                    className="m-2"
                                                >
                                                    ลืมรหัสผ่าน
                                                </a>
                                            </div>
                                            <div

                                                className="col-lg-4 submenulogin pc-view px-1 m-0"
                                            >
                                                <div

                                                    className="btoutline choke-btn-font"
                                                >
                                                    <a

                                                        target="_blank"
                                                        className="btn btn-login-register-admin btn-block"
                                                        href={this.state.linelink}
                                                    >
                                                        {" "}
                                                        สมัครสมาชิกผ่านแอดมิน{" "}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="mobile-view row px-5 pt-0 pb-0">
                                <div

                                    className="col-sm-12 col-md-4 col-lg-3 mt-0 mb-0 text-center py-2 py-md-0"
                                >
                                    <img

                                        src="https://cdn.javisx.com/image/4c0ea74d-05a2-447e-a121-95cc85ce9eba.png"
                                        className="logo-app"
                                        alt="Lotto888"
                                        title="Lotto888"
                                    />
                                </div>
                                <div

                                    className="col-sm-12 col-md-8 col-lg-9 px-4 mt-0"
                                >
                                    <form

                                        noValidate=""
                                        id="login3"
                                        autoComplete="off"
                                        role="form"
                                        acceptCharset="utf-8"
                                        className="form-login form-horizontal ng-untouched ng-pristine ng-valid"
                                    >
                                        <div

                                            className="row form-middle"
                                            style={{ marginTop: "-15px" }}
                                        >
                                            <div className="col" />
                                            <div

                                                className="col-sm-12 col-md-4 col-lg-3 p-1 mt-3"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="dvImageTextBox">
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/telephone.svg"
                                                            className="mx-1"
                                                        />
                                                        <input

                                                            id="username"
                                                            type="number"
                                                            name="username"
                                                            inputMode="text"
                                                            onChange={this.onChange}

                                                            placeholder="เบอร์โทรศัพท์"
                                                            className="form-control login-btn ng-untouched ng-pristine ng-valid"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div

                                                className="col-sm-12 col-md-4 col-lg-3 p-1 mt-1"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="d-flex mb-0">
                                                        <div

                                                            className="dvImageTextBox box-pwd position-relative"
                                                        >
                                                            <img

                                                                src="/build/web/igame-index-lobby-wm/img/lock.svg"
                                                                className="lock-icon"
                                                            />
                                                            <input

                                                                type="password"
                                                                id="password"
                                                                name="password"
                                                                onChange={this.onChange}
                                                                placeholder="รหัสผ่าน"
                                                                required=""
                                                                className="form-control ng-dirty ng-valid ng-touched"
                                                                style={{
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                    paddingLeft: 35
                                                                }}
                                                                data-gtm-form-interact-field-id={1}
                                                            />
                                                        </div>
                                                        <div className="input-group-append">
                                                            <div

                                                                className="input-group-text style-eye"
                                                            >
                                                                <i

                                                                    className="fa fa-eye-slash"
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 custombglogin">
                                                <div

                                                    className="rule"
                                                    style={{ justifyContent: "space-between", display: "flex" }}
                                                >
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#ModalRule"
                                                        className="m-2"
                                                    >
                                                        กฏกติกา
                                                    </a>
                                                    <a className="m-2">
                                                        ลืมรหัสผ่าน
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.login(e)
                                                    }}
                                                    type="submit"
                                                    className="btn btn-gradiant-login-m btn-block"
                                                >
                                                    {" "}
                                                    เข้าสู่ระบบ{" "}
                                                </a>
                                            </div>
                                            <div

                                                className="col-12 p-1"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <div className="uline" />
                                            </div>
                                            <div className="col-12 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/register")
                                                    }}
                                                    type="button"
                                                    className="btn btn-login-white-m btn-block"
                                                >
                                                    {" "}
                                                    สมัครสมาชิก{" "}
                                                </a>
                                            </div>
                                            <div className="col-12 p-1 mt-1">
                                                <a

                                                    target="_blank"
                                                    className="btn btn-login-register-admin-m btn-block"
                                                    href={this.state.linelink}
                                                >
                                                    {" "}
                                                    สมัครสมาชิกผ่านแอดมิน{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row mt-1 mb-2 custombglogin">
                                            <div className="col pc-view" />
                                            <div

                                                className="col-sm-12 col-md-7 col-lg-6 rule pc-view"
                                            >
                                                <a className="m-2">
                                                    ลืมรหัสผ่าน
                                                </a>
                                            </div>
                                            <div

                                                className="col-lg-4 submenulogin pc-view px-1 m-0"
                                            >
                                                <div

                                                    className="btoutline choke-btn-font"
                                                >
                                                    <a

                                                        target="_blank"
                                                        className="btn btn-login-register-admin btn-block"
                                                        href={this.state.linelink}
                                                    >
                                                        {" "}
                                                        สมัครสมาชิกผ่านแอดมิน{" "}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div

                                id="ModalRule"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="ModalRule"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-lg"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div

                                            className="modal-header bg-theme-primary"
                                        >
                                            <span className="modal-title">
                                                กฏและกติกา
                                            </span>
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                className="text-white close"
                                            >
                                                <i className="far fa-times" />
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p >
                                                <span className="pl-3">
                                                    ยินดีต้อนรับสู่หวยออนไลน์
                                                </span>{" "}
                                                เพื่อให้ท่านทราบถึงสิทธิประโยชน์ของสมาชิกกรุณาอ่านข้อตกลงอย่างละเอียดก่อนที่จะลงทะเบียนสมัครเป็นสมาชิกและใช้บริการ{" "}
                                                <a href="https:">
                                                    Lotto888
                                                </a>{" "}
                                                เมื่อสมัครสมาชิกแล้วทางเว็บจะถือว่า สมาชิกได้อ่านและยอมรับ{" "}
                                                <span className="text-primary">
                                                    " นโยบายและเงื่อนไขการให้บริการของเว็บไซต์"
                                                </span>
                                            </p>
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        การรักษาผลประโยชน์และช่วยเหลือการเดิมพันออนไลน์
                                                    </u>
                                                </strong>
                                                <br />
                                            </p>
                                            <ul className="pl-3">
                                                <li >
                                                    ถ้าสมาชิกหลุดออกจากเว็บไซต์ระหว่างการเดิมพัน
                                                    บัญชีของท่านจะไม่มีผลกระทบต่อผลลัพธ์ของการเดิมพัน{" "}
                                                </li>
                                                <li >
                                                    ไม่ต้องวิตกกังวล ถ้าสมาชิกออกจากเว็บไซด์ระหว่างการเดิมพัน
                                                    สมาชิกจะถูกออกจากระบบช่วงเวลาหนึ่ง
                                                    จากนั้นสมาชิกสามารถเข้าสู่ระบบ{" "}
                                                    <a href="https:">
                                                        Lotto888
                                                    </a>{" "}
                                                    ได้อีกครั้งตามปกติ{" "}
                                                </li>
                                            </ul>
                                            <p />
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        การให้ความคุ้มครอง
                                                    </u>
                                                </strong>
                                                <br />
                                                <a href="https:">
                                                    Lotto888
                                                </a>{" "}
                                                ได้มีการจัดเตรียม ระบบที่มีประสิทธิภาพ
                                                ความรวดเร็วและการบริการลูกค้าที่เป็นมิตร
                                                ถ้าหากท่านมีคำถามหรือคำแนะนำใด ๆ
                                                กรุณาอย่าลังเลใจที่จะโทรศัพท์มาทางฝ่ายบริการลูกค้า
                                                ซึ่งจะมีให้บริการตลอด 24 ชั่วโมง
                                                ทุกวันไม่มีวันหยุดและทางเราจะตอบกลับอย่างรวดเร็วที่สุด{" "}
                                                <a href="https:">
                                                    Lotto888
                                                </a>{" "}
                                                ขอรับรองว่าจะรักษาข้อมูลส่วนตัวของท่านไว้เป็นความลับอย่างปลอดภัย{" "}
                                            </p>
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        ข้อตกลงในการเดิมพัน
                                                    </u>
                                                </strong>
                                                <br />
                                            </p>
                                            <ul className="pl-3">
                                                <li >
                                                    เพื่อหลีกเลี่ยงปัญหาต่างๆในขณะที่ใช้งานเว็บไซต์
                                                    สมาชิกกรุณาอ่านกฎกติกาของบริษัทอย่างละเอียดและระมัดระวัง
                                                    เมื่อสมาชิกเข้าสู่หน้าเดิมพันทางบริษัทจะถือว่าท่านได้ยอมรับข้อตกลงของทาง{" "}
                                                    <a href="https:">
                                                        Lotto888
                                                    </a>
                                                </li>
                                                <li >
                                                    การเดิมพันจะต้องอยู่ใน{" "}
                                                    <span className="text-success">
                                                        "ระยะเวลาที่กำหนด"
                                                    </span>{" "}
                                                    มิฉะนั้นจะถือว่าการเดิมครั้งนั้นเป็น{" "}
                                                    <span className="text-danger">
                                                        "โมฆะ"{" "}
                                                    </span>{" "}
                                                    ถ้าการเดิมพันได้มีการยกเลิกหรือหยุดพักชั่วคราว
                                                    เนื่องจากเหตุผลใดๆก็ตามก่อนการเล่น
                                                    การเดิมพันครั้งนั้นจะถือว่าเป็น{" "}
                                                    <span className="text-danger">
                                                        "โมฆะ"{" "}
                                                    </span>
                                                    และทางบริษัทจะคืนเครดิตให้กับสมาชิก{" "}
                                                </li>
                                                <li >
                                                    เป็นความรับผิดชอบของสมาชิกที่จะคอยดูผลลัพธ์
                                                    จากช่องหน้าต่างที่แสดงให้เห็นถึงผลการเดิมพันแพ้หรือชนะ
                                                    ทางบริษัทจะยึดถือข้อมูล{" "}
                                                    <span className="text-primary">
                                                        "รายละเอียดการวางเดิมพัน"
                                                    </span>{" "}
                                                    ของสมาชิก ในกรณีที่สมาชิกมีความสงสัย
                                                    และต้องการตรวจสอบข้อมูลในการคำนวณของการเดิมพันนั้นๆ{" "}
                                                </li>
                                                <li >
                                                    ถ้าหากเกิดความผิดพลาดของระบบระหว่างการเดิมพันหรือความผิดพลาดโดยไม่ได้เจตนาของพนักงาน
                                                    ทางบริษัทขอสงวนสิทธ์ที่จะแก้ไขผลลัพธ์ให้ถูกต้อง
                                                    และจะมีการพิมพ์ข้อความที่ได้มีการแก้ไขเผยแพร่ไว้ที่แถบตัววิ่งในหน้าเว็บไซต์
                                                    บริษัทจะไม่มีการแจ้งสมาชิกเป็นการส่วนตัว{" "}
                                                </li>
                                                <li >
                                                    เป็นความรับชอบของสมาชิกที่จะทำให้แน่ใจว่าชื่อผู้ใช้และรหัสผ่านสำหรับเข้าเว็บไซต์นั้นถูกต้อง
                                                    และกรุณาเปลี่ยนรหัสผ่านของสมาชิกอย่างน้อยเดือนละครั้ง
                                                    เพื่อความปลอดภัย
                                                    ถ้าหากท่านพบหรือสงสัยว่ามีผู้อื่นได้เข้าไปเล่นในบัญชีของท่านโดยไม่รับอนุญาต
                                                    กรุณา แจ้งตัวแทนของท่านทันทีเพื่อเปลี่ยนรหัสส่วนตัว
                                                    (หากมีการเดิมพันก่อนที่จะเปลี่ยนรหัสเดิมจะถือว่าการเดิมพันนั้นเป็นผล){" "}
                                                </li>
                                                <li >
                                                    ก่อนเริ่มการเดิมพันแต่ละครั้ง
                                                    ท่านสมาชิกควรจะตรวจสอบวงเงินของท่านเสียก่อน
                                                    ถ้าหากท่านมีข้อสงสัยใดๆ เกี่ยวกับวงเงิน
                                                    กรุณาแจ้งตัวแทนของท่านสมาชิกทันที{" "}
                                                </li>
                                                <li >
                                                    ในกรณีที่เหตุการณ์ไม่คาดคิดเกิดขึ้น อย่างเช่น
                                                    ข้อมูลสูญหายเนื่องจากระบบอินเตอร์เน็ตขัดข้อง
                                                    ทางบริษัทจะมีการประกาศให้รู้ถึงสาเหตุ และวิธีการแก้ปัญหา{" "}
                                                </li>
                                                <li >
                                                    ชั่วโมงการให้บริการ 24 ชั่วโมง/วัน และไม่มีวันหยุด
                                                </li>
                                                <li >
                                                    กรณีตรวจพบการใช้ตั้งแต่ 1 ยูสเซอร์ขึ้นไปเพื่อแทงทบ, แทงหวย 2
                                                    ตัว เกิน 50 หมายเลข/แทงหวย 3 ตัว เกิน 250 หมายเลข
                                                    ต่อหวยงวดนั้นๆ ไม่ว่าจะแยกหรือรวมบิล
                                                    หรือทางบริษัทตัดสินแล้วว่าการเดิมพันผิดปกติ
                                                    ทางบริษัทมีสิทธิ์ในการระงับการใช้งานและยึดเครดิตของสมาชิกโดยไม่ต้องแจ้งล่วงหน้า
                                                </li>
                                                <li >
                                                    คำตัดสินของบริษัทถือเป็นที่สิ้นสุด
                                                </li>
                                            </ul>
                                            <p />
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        ระบบการรักษาความปลอดภัย
                                                    </u>
                                                </strong>
                                                <br />
                                            </p>
                                            <ul className="pl-3">
                                                <li >
                                                    ข้อมูลทางด้านบัญชีเพื่อวัตถุประสงค์เฉพาะบุคคล
                                                    ข้อมูลที่ทางสมาชิกให้มาในการเปิดบัญชีจะถูกเก็บเป็นความลับขั้นสูงสุด
                                                    ทางบริษัทจะไม่เผยแพร่ข้อมูลนี้ให้กับบุคคลที่สามหรือองค์กรอื่นๆ
                                                    ล่วงรู้ ไม่ว่าจะเป็นบัญชีอีเมล์ หรือข้อมูลอื่นๆ
                                                    ที่ทางสมาชิกกรอกในใบสมัคร
                                                    ข้อมูลเหล่านี้จะใช้ในจุดมุ่งหมายที่ตั้งใจเอาไว้เท่านั้น เช่น
                                                    การส่งเช็คเงินสดสำหรับผู้ชนะหรือข้อมูลอื่นๆ ตามที่สมาชิกร้องขอ{" "}
                                                </li>
                                                <li >
                                                    {" "}
                                                    ความปลอดภัยทางบริษัทได้ใช้วิธีที่มีประสิทธิภาพมากที่สุดเพื่อทำให้ท่านแน่ใจในความปลอดภัยของข้อมูลของท่าน
                                                    ทางเราจะพยายามให้ดีที่สุดที่จะรักษาความถูกต้องแน่นอน
                                                    และความลับเฉพาะของข้อมูล
                                                    เพื่อป้องกันไม่ให้ข้อมูลรั่วไหลหรือนำไปใช้อย่างไม่ถูกต้อง
                                                    ถ้าท่านมีข้อสงสัยใดๆ ที่เกี่ยวข้องกับการรักษาความลับ
                                                    ความปลอดภัยของข้อมูล กรุณาติดต่อฝ่ายบริการลูกค้า
                                                    พวกเรายินดีให้บริการ 24 ชั่วโมง/วัน ทุกวันไม่มีวันหยุด{" "}
                                                </li>
                                            </ul>
                                            <p />
                                            <p >
                                                บริษัทมีความยินดีเป็นอย่างยิ่งที่ได้รับใช้ท่าน
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ฉันเข้าใจและยอมรับ
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div

                                id="ModalRate"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="ModalRate"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-lg"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div

                                            className="modal-header bg-theme-primary"
                                        >
                                            <span className="modal-title">
                                                อัตราการจ่าย
                                            </span>
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                className="text-white close"
                                            >
                                                <i className="far fa-times" />
                                            </button>
                                        </div>
                                        <div

                                            className="modal-body text-center ng-star-inserted"
                                        >
                                            <img

                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                    height: "inherit !important"
                                                }}
                                                src="https://s3.365huay.net/banner/TAOKAELOTTO/1696683195283.jpg"
                                            />
                                            <br />
                                        </div>


                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ปิด
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav id="sidebar" hidden={this.state.loginstate === null || this.state.loginstate}>
                        <div id="dismiss">
                            <i

                                className="fas fa-backspace fa-flip-horizontal"
                            />
                        </div>
                        <div className="sidebar-header">
                            <div className="align-center text-center">
                                <img

                                    src="/build/web/igame-index-lobby-wm/img/user-128.png"
                                    alt="หวยฮานอย"
                                    width={110}
                                    height={100}
                                    className="rounded-circle"
                                />
                                <br />
                                <span className="username choke-font-size">
                                    {username}
                                </span>
                                <br />
                                <i className="fas fa-coins" />
                                <span

                                    data-id="credit_balance"
                                    className="badge badge-pill badge-danger thb"
                                    style={{ marginLeft: 5, border: "3px solid #016F62" }}
                                >
                                    <NumericFormat value={balance} displayType={'text'} thousandSeparator={true} /> ฿
                                </span>
                            </div>
                        </div>
                        <ul className="list-unstyled components">
                            <li >
                                <a

                                    onClick={(e) => {
                                        this.checkurl(e, "/setting-profile")
                                    }} role="button"


                                >
                                    <i className="fas fa-user-cog" />{" "}
                                    ตั้งค่าบัญชีผู้ใช้
                                </a>
                            </li>
                            <li >
                                <a

                                    onClick={(e) => {
                                        this.checkurl(e, "/setting-bank")
                                    }} role="button"

                                >
                                    <i className="fas fa-money-check" />{" "}
                                    บัญชีธนาคาร
                                </a>
                            </li>
                            <li >
                                <a href="/howto" >
                                    <i className="fas fa-info-circle" /> วิธีใช้งาน
                                </a>
                            </li>
                            <li >
                                <a

                                    href="#"
                                    data-toggle="modal"
                                    data-target="#contactbox"
                                >
                                    <i className="fas fa-handshake" />{" "}
                                    ศูนย์ช่วยเหลือ
                                </a>
                            </li>
                        </ul>
                        <ul className="list-unstyled CTAs">
                            <li >
                                <a href="#" className="logout">
                                    <i className="fas fa-sign-out-alt" />{" "}
                                    ออกจากระบบ{" "}
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="sticky-top" hidden={this.state.loginstate === null || !this.state.loginstate}>
                        <div className="topnavbar">
                            <div className="container px-0">
                                <div

                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="d-flex align-items-center">
                                        <a

                                            onClick={(e) => {
                                                this.checkurl(e, "/")
                                            }} role="button"

                                            title="Lotto888"
                                        >
                                            <div className="memberlogo">
                                                <div className="logomember">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                        id="logofull"
                                                        className="pc-view"
                                                        alt="Lotto888"
                                                        title="Lotto888"
                                                    />
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                        id="logosymbol"
                                                        className="mobile-view ml-3"
                                                        alt="Lotto888"
                                                        title="Lotto888"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div

                                        className="d-flex flex-row justify-content-between align-items-center"
                                    >
                                        <div

                                            id="menu-pc"
                                            className="d-flex justify-content-between align-items-center pc-view"
                                        >
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/")
                                                }} role="button"

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="lotto"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                หน้าแรก{" "}
                                            </a>
                                            <a
                                                onClick={(e) => {
                                                    this.checkurl(e, "/deposit")
                                                }} role="button"

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="refill"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                ฝากเงิน{" "}
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/lotto")
                                                }} role="button"

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="lottery"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                แทงหวย{" "}
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/result-all")
                                                }}
                                                role="button"

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="award"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                ผลหวย{" "}
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/game-sl")
                                                }}
                                                role="button"

                                                data-original-title=""
                                            >
                                                {" "}
                                                เกมส์{" "}
                                            </a>
                                        </div>
                                        <div

                                            className="d-flex flex-row bg-card-head justify-content-between align-items-center text-dark mr-2"
                                        >
                                            <div

                                                className="col-9"
                                                style={{ alignSelf: "center" }}
                                            >
                                                <div

                                                    className="row px-2 py-1 username-text-head"
                                                >
                                                    <i className="fas fa-user mr-1 align-self-center"
                                                        style={{ fontSize: "0.6rem" }}
                                                    />
                                                    {username}{" "}
                                                </div>
                                                <div

                                                    className="row py-1 px-2"
                                                    style={{
                                                        background: "white",
                                                        fontSize: "1rem",
                                                        fontWeight: 600,
                                                        borderRadius: "0px 0px 0px 5px"
                                                    }}
                                                >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/coin-stack.svg"
                                                        className="pr-1"
                                                    />{" "}
                                                    <NumericFormat value={balance} displayType={'text'} thousandSeparator={true} />{" "}
                                                </div>
                                            </div>
                                            <div className="col-3 px-1 align-self-center image-btn-effect2" >
                                                <div className="dropdown">
                                                    <a
                                                        onClick={(e) => {
                                                            if (this.state.showmenu) {
                                                                this.setState({
                                                                    showmenu: false,
                                                                });
                                                            }
                                                            else {
                                                                this.setState({
                                                                    showmenu: true,
                                                                });
                                                            }
                                                        }}
                                                        id="menu-profile"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        className="p-0"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/settings-outline-badged.svg"
                                                            style={{ height: 23, margin: 3 }}
                                                        />
                                                    </a>
                                                    <div
                                                        aria-labelledby="menu-profile"
                                                        id="menu-profile-dropdown"
                                                        className="dropdown-menu show handlemenu"
                                                        hidden={this.state.showmenu}
                                                        x-placement="bottom-start"
                                                        x-out-of-boundaries=""
                                                    >
                                                        <a
                                                            onClick={(e) => {
                                                                this.checkurl(e, "/setting-profile")
                                                            }} role="button"


                                                            className="dropdown-item"
                                                        >
                                                            <i className="fas fa-user-cog" />{" "}
                                                            ตั้งค่าบัญชีผู้ใช้{" "}
                                                        </a>
                                                        <a

                                                            onClick={(e) => {
                                                                this.checkurl(e, "/setting-bank")
                                                            }} role="button"

                                                            className="dropdown-item"
                                                        >
                                                            <i

                                                                className="fas fa-money-check"
                                                            />{" "}
                                                            บัญชีธนาคาร{" "}
                                                        </a>
                                                        <a

                                                            href="/howto"

                                                            className="dropdown-item"
                                                        >
                                                            <i

                                                                className="fas fa-info-circle"
                                                            />{" "}
                                                            วิธีใช้งาน{" "}
                                                        </a>
                                                        <a

                                                            href={this.state.linelink}
                                                            data-toggle="modal"
                                                            data-target="#contactbox"
                                                            className="dropdown-item"
                                                        >
                                                            <i className="fas fa-handshake" />{" "}
                                                            ศูนย์ช่วยเหลือ{" "}
                                                        </a>
                                                        <div className="dropdown-divider" />
                                                        <a

                                                            href="/logout"
                                                            className="dropdown-item"
                                                        >

                                                            <i

                                                                className="fas fa-sign-out-alt"
                                                            />{" "}
                                                            ออกจากระบบ{" "}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-outlet />
                    <div id="main" className="ng-star-inserted" hidden={this.state.loginstate === null || this.state.loginstate}>
                        <div id="app">
                            <mdb-carousel

                                className="pc-view carousel slide carousel-fade ng-star-inserted"
                            >
                                <div tabIndex={0} className="carousel carousel-fade pc-view slide">



                                    <div className="carousel-inner">


                                        <Slider {...settings}>
                                            <div
                                                data-slick-index={-1}
                                                aria-hidden="true"
                                                tabIndex={-1}
                                                className=" -slide-inner-wrapper  -slick-item slick-slide slick-cloned banner-colosal ng-star-inserted"
                                            >
                                                <img

                                                    alt="ผลหวยฮานอย vip พิเศษ"
                                                    className="d-block w-100 border-banner-register"
                                                    src="https://cdn.javisx.com/image/d05ca367-9209-43bc-a372-cb23f5fb5f9b.jpg"
                                                />
                                            </div>
                                            <div
                                                data-slick-index={-2}
                                                aria-hidden="true"
                                                tabIndex={-2}
                                                className=" -slide-inner-wrapper  -slick-item slick-slide slick-cloned banner-colosal ng-star-inserted"
                                            >
                                                <img

                                                    alt="ผลหวยฮานอย vip พิเศษ"
                                                    className="d-block w-100 border-banner-register"
                                                    src="https://cdn.javisx.com/image/ed032646-2f7d-4cb9-83ba-9c2e7505cd55.jpg"
                                                />
                                            </div>
                                            <div
                                                data-slick-index={-3}
                                                aria-hidden="true"
                                                tabIndex={-3}
                                                className=" -slide-inner-wrapper  -slick-item slick-slide slick-cloned banner-colosal ng-star-inserted"
                                            >
                                                <img

                                                    alt="ผลหวยฮานอย vip พิเศษ"
                                                    className="d-block w-100 border-banner-register"
                                                    src="https://cdn.javisx.com/image/347caed9-c446-42ec-9b03-a1bff067ec28.jpg"
                                                />
                                            </div>

                                        </Slider >


                                    </div>


                                </div>
                            </mdb-carousel>
                            <div className="mt-3 mb-0 pc-view" hidden={this.state.loginstate} >
                                <div className="slick- draggable" style={{ padding: "0px 2px" }}>
                                    <div
                                        className="flicking-viewport"
                                        style={{
                                            userSelect: "none",
                                            WebkitUserDrag: "none",
                                            touchAction: "pan-y"
                                        }}
                                    >
                                        <Slider {...settingsx}>
                                            <div data-slick-index={-8}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ngg2/image/naga-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NAGA GAMES
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-7}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/gmw.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            GMW
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-6}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rlx/image/relax-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RELAX GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-5}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/neg/image/neg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NETENT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-4}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ftg/image/slot_ftg_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FUNTA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-3}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/asp/image/20230420_asp_icon.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ASPECT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-2}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rtg/image/rtg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RED TIGER
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-1}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spp/image/slot_spp_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SIMPLE PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={0}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/nsp/image/nsp3.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NEXT SPIN
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={1}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spg/image/slot_spg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SPADE GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={2}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kkg/icons/kkgaming.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KK GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={3}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kag/image/slot_ka_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={4}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pkg/image/slot_pg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            POCKET GAMES SOFT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={5}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jlg/image/hot-jili.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JILI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={6}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/fch/image/slot-fachahoti.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FACHAI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={7}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/onp/onlyplay.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ONLYPLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={8}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jkg/image/joker_slot_20220705.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JOKER GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={9}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/cq9/image/cq9_slot_20221004.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            CQ9 GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={10}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/bng/images/bng-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BNG
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={11}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pmp/image/20230109-pmp-slot.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            PRAGMATIC PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={12}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/btg/images/bt-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={13}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/haba-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            HABANERO
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={14}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ngg2/image/naga-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NAGA GAMES
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={15}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-current slick-active slick-center"
                                                aria-hidden="false"

                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/gmw.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            GMW
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={16}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"

                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rlx/image/relax-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RELAX GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={17}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"

                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/neg/image/neg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NETENT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={18}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"

                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ftg/image/slot_ftg_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FUNTA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={19}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/asp/image/20230420_asp_icon.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ASPECT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={20}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rtg/image/rtg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RED TIGER
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={21}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spp/image/slot_spp_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SIMPLE PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={22}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/nsp/image/nsp3.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NEXT SPIN
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={23}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spg/image/slot_spg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SPADE GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={24}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kkg/icons/kkgaming.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KK GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={25}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kag/image/slot_ka_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={26}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pkg/image/slot_pg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            POCKET GAMES SOFT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={27}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jlg/image/hot-jili.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JILI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={28}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/fch/image/slot-fachahoti.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FACHAI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={29}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/onp/onlyplay.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ONLYPLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={30}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jkg/image/joker_slot_20220705.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JOKER GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={31}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/cq9/image/cq9_slot_20221004.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            CQ9 GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={32}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/bng/images/bng-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BNG
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={33}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pmp/image/20230109-pmp-slot.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            PRAGMATIC PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={34}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/btg/images/bt-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={35}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/haba-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            HABANERO
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={36}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ngg2/image/naga-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NAGA GAMES
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={37}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/gmw.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            GMW
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={38}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rlx/image/relax-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RELAX GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={39}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/neg/image/neg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NETENT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={40}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ftg/image/slot_ftg_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FUNTA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={41}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/asp/image/20230420_asp_icon.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ASPECT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={42}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rtg/image/rtg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RED TIGER
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={43}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"

                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spp/image/slot_spp_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SIMPLE PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center bg-promote-login px-3 mt-3 mb-2 py-3 mobile-view" >
                                <div className="col-4">
                                    <div className="row content-promote">
                                        <a

                                            href="#"
                                            data-toggle="modal"
                                            data-target="#loginPopup"
                                            className="col-3 bg-promote-center-m"
                                        >
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/shield.svg"
                                                className="img-promote-center-m"
                                            />
                                        </a>
                                        <div

                                            className="col-9 p-0"
                                            style={{ paddingRight: 0 }}
                                        >
                                            <div

                                                className="text-yellow-login-m offset-1"
                                            >
                                                กลุ่มเลขเด็ด
                                            </div>
                                            <div

                                                className="offset-1"
                                                style={{ color: "white", fontSize: "0.5rem" }}
                                            >
                                                เลขเด็ดเลขดังประจำวัน
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="row content-promote">
                                        <a

                                            href="#"
                                            data-toggle="modal"
                                            data-target="#loginPopup"
                                            className="col-3 bg-promote-center-m"
                                        >
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/ticket.svg"
                                                className="img-promote-center-m"
                                            />
                                        </a>
                                        <div

                                            className="col-9 p-0"
                                            style={{ paddingRight: 0 }}
                                        >
                                            <div

                                                className="text-yellow-login-m offset-1"
                                            >
                                                วิธีสมัครสมาชิก
                                            </div>
                                            <div

                                                className="offset-1"
                                                style={{ color: "white", fontSize: "0.5rem" }}
                                            >
                                                ขั้นตอนการสมัครสมาชิก
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="row content-promote">
                                        <a

                                            target="_blank"
                                            className="col-3 bg-promote-center-m"
                                            href={this.state.linelink}
                                        >
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/people-fill.svg"
                                                className="img-promote-center-m"
                                            />
                                        </a>
                                        <div

                                            className="col-9 p-0"
                                            style={{ paddingRight: 0 }}
                                        >
                                            <div

                                                className="text-yellow-login-m offset-1"
                                            >
                                                สมัครผ่านเเอดมิน
                                            </div>
                                            <div

                                                className="offset-1"
                                                style={{ color: "white", fontSize: "0.5rem" }}
                                            >
                                                ให้แอดมินช่วยสมัครสมาชิก
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-view p-0 m-0 " hidden={this.state.loginstate}>
                                <div className="slick- draggable" style={{ padding: "0px 2px", display: "none" }}>
                                    <div className="flicking-viewport"
                                        style={{

                                            userSelect: "none",
                                            WebkitUserDrag: "none",
                                            touchAction: "pan-y"
                                        }}
                                    >
                                        <Slider {...settingsx}>
                                            <div data-slick-index={-8}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ngg2/image/naga-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NAGA GAMES
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-7}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/gmw.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            GMW
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-6}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rlx/image/relax-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RELAX GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-5}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/neg/image/neg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NETENT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-4}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ftg/image/slot_ftg_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FUNTA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-3}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/asp/image/20230420_asp_icon.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ASPECT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-2}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rtg/image/rtg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RED TIGER
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={-1}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spp/image/slot_spp_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SIMPLE PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={0}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/nsp/image/nsp3.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NEXT SPIN
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={1}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spg/image/slot_spg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SPADE GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={2}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kkg/icons/kkgaming.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KK GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={3}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kag/image/slot_ka_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={4}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pkg/image/slot_pg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            POCKET GAMES SOFT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={5}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jlg/image/hot-jili.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JILI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={6}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/fch/image/slot-fachahoti.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FACHAI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={7}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/onp/onlyplay.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ONLYPLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={8}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jkg/image/joker_slot_20220705.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JOKER GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={9}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/cq9/image/cq9_slot_20221004.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            CQ9 GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={10}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/bng/images/bng-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BNG
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={11}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pmp/image/20230109-pmp-slot.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            PRAGMATIC PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={12}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/btg/images/bt-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={13}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/haba-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            HABANERO
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={14}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ngg2/image/naga-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NAGA GAMES
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={15}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/gmw.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            GMW
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={16}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-current slick-active slick-center"
                                                aria-hidden="false"
                                                style={{ width: 0 }}
                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rlx/image/relax-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RELAX GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={17}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"
                                                style={{ width: 0 }}
                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/neg/image/neg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NETENT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={18}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"
                                                style={{ width: 0 }}
                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ftg/image/slot_ftg_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FUNTA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={19}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-active"
                                                aria-hidden="false"
                                                style={{ width: 0 }}
                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={0}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/asp/image/20230420_asp_icon.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ASPECT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={20}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rtg/image/rtg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RED TIGER
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={21}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={0}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spp/image/slot_spp_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SIMPLE PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={22}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/nsp/image/nsp3.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NEXT SPIN
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={23}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spg/image/slot_spg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SPADE GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={24}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kkg/icons/kkgaming.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KK GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={25}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/kag/image/slot_ka_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            KA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={26}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pkg/image/slot_pg_20220712.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            POCKET GAMES SOFT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={27}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jlg/image/hot-jili.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JILI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={28}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/fch/image/slot-fachahoti.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FACHAI GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={29}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/onp/onlyplay.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ONLYPLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={30}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/jkg/image/joker_slot_20220705.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            JOKER GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={31}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/cq9/image/cq9_slot_20221004.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            CQ9 GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={32}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/bng/images/bng-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BNG
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={33}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/pmp/image/20230109-pmp-slot.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            PRAGMATIC PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={34}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/btg/images/bt-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            BT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={35}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/haba-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            HABANERO
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={36}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ngg2/image/naga-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NAGA GAMES
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={37}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/hbn/images/gmw.gif"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            GMW
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={38}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rlx/image/relax-notag.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RELAX GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={39}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/neg/image/neg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            NETENT
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={40}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/ftg/image/slot_ftg_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            FUNTA GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={41}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/asp/image/20230420_asp_icon.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            ASPECT GAMING
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={42}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/rtg/image/rtg_logo_20230411.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            RED TIGER
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div


                                                data-slick-index={43}
                                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned slick-cloned"
                                                aria-hidden="true"
                                                style={{ width: 0 }}
                                                tabIndex={-1}
                                            >
                                                <div className="position-relative">
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#loginPopup"
                                                        tabIndex={-1}
                                                    >
                                                        <img

                                                            className="img-fluid img-provider-name-login"
                                                            src="https://storage.9coon.com/9coon/spp/image/slot_spp_20220712.png"
                                                        />
                                                        <div

                                                            className="text-provider-name-login shadow-sm text-center"
                                                        >
                                                            SIMPLE PLAY
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-result-login-topic py-2 mt-3 mx-2 mb-1 mobile-view" >
                                ผลหวยล่าสุด
                            </div>
                            <div className="col-12 p-1 mobile-view" style={{ textAlign: "-webkit-center" }}
                            >
                                <div className="uline-result-app" />
                            </div>
                            <div

                                className="row mt-2 px-4 py-2 py-md-3 d-md-none d-block"
                                style={{ borderRadius: 10 }}
                            >
                                <div className="bg-result-login-inner-m">
                                    <div className="col-12 pr-1">
                                        <div

                                            className="px-0 py-2 mt-2"
                                            style={{ borderRadius: 10, marginBottom: 15 }}
                                        >
                                            <div

                                                className="d-flex px-2 py-2 header-th-login"
                                            >
                                                <div >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/TH.png"
                                                        className="flag-on-top-lotto"
                                                    />{" "}
                                                    หวยรัฐบาลไทย
                                                </div>
                                                <div >วันพุธ 16/10/67</div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div

                                                className="col-12 th-result-login-game th-result-login-only px-4 py-2"
                                            >
                                                <div className="row">
                                                    <div className="col-8 p-0 pr-1">
                                                        <div >รางวัลที่ 1</div>
                                                        <div className="th-result-login-bg">
                                                            482962
                                                        </div>
                                                    </div>
                                                    <div className="col-4 p-0">
                                                        <div style={{ whiteSpace: "nowrap" }}>
                                                            2 ตัวล่าง
                                                        </div>
                                                        <div className="th-result-login-bg">
                                                            00
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 p-0 pr-1">
                                                        <div style={{ whiteSpace: "nowrap" }}>
                                                            3 ตัวหน้า
                                                        </div>
                                                        <div className="th-result-login-bg3">
                                                            648, 561
                                                        </div>
                                                    </div>
                                                    <div className="col-6 p-0">
                                                        <div style={{ whiteSpace: "nowrap" }}>
                                                            3 ตัวล่าง
                                                        </div>
                                                        <div className="th-result-login-bg3">
                                                            919, 493
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div

                                                className="col-12 px-0 py-2"
                                                style={{ color: "white" }}
                                            >
                                                <div className="d-flex px-2 px-lg-0 pt-0">
                                                    <div

                                                        className="col-6 col-xl-5 col-md-7 p-0 offset-0 offset-xl-1"
                                                    />
                                                    <div

                                                        className="th-result-login-game col-3 px-0"
                                                    >
                                                        3 ตัวบน
                                                    </div>
                                                    <div

                                                        className="th-result-login-game col-3 px-0"
                                                    >
                                                        2 ตัวล่าง
                                                    </div>
                                                </div>
                                                <div

                                                    className="d-flex px-0 py-2"
                                                    style={{ justifyContent: "space-between" }}
                                                >
                                                    <div

                                                        className="col-6 col-xl-5 col-md-7 p-0 offset-0 offset-xl-1"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/AOM.png"
                                                            className="flag-on-top-lotto-m"
                                                        />{" "}
                                                        หวยออมสิน
                                                    </div>
                                                    <div

                                                        className="col-3"
                                                        style={{ textAlign: "center", fontWeight: "bold" }}
                                                    >
                                                        356
                                                    </div>
                                                    <div

                                                        className="col-3"
                                                        style={{ textAlign: "center", fontWeight: "bold" }}
                                                    >
                                                        32
                                                    </div>
                                                </div>
                                                <div

                                                    className="d-flex px-0 py-2"
                                                    style={{ justifyContent: "space-between" }}
                                                >
                                                    <div

                                                        className="col-6 col-xl-5 col-md-7 p-0 offset-0 offset-xl-1"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/BAAC.png"
                                                            className="flag-on-top-lotto-m"
                                                        />{" "}
                                                        หวย ธกส
                                                    </div>
                                                    <div

                                                        className="col-3"
                                                        style={{ textAlign: "center", fontWeight: "bold" }}
                                                    >
                                                        002
                                                    </div>
                                                    <div

                                                        className="col-3"
                                                        style={{ textAlign: "center", fontWeight: "bold" }}
                                                    >
                                                        62
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="top-result-lotto-bg pc-view mt-3">
                                <div

                                    className="row justify-content-center px-md-5"
                                >
                                    <div style={{ width: "9%" }} />
                                    <div

                                        className="top-lotto-text p-2"
                                        style={{ width: "82%" }}
                                    >
                                        หวยเด่นยอดนิยม
                                    </div>
                                    <div style={{ width: "9%" }} />
                                    {filledRowsFeature}


                                    {/*                                     
                                    <div

                                        className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 ng-star-inserted"
                                    >
                                        <div className="rounded border-top-lotto">
                                            <div className="top">
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#loginPopup"
                                                >
                                                    <img

                                                        className="img-top-lotto-login"
                                                        src="/build/web/igame-index-lobby-wm/img/login-th.png"
                                                    />
                                                </a>
                                                <div className="detail-top-lotto">
                                                    <div

                                                        className="d-flex flex-row justify-content-between my-0"
                                                    >
                                                        <button

                                                            className="col-8 btn btn-period-text-login p-0 d-flex"
                                                            style={{ justifyContent: "space-around" }}
                                                        >
                                                            <div style={{ fontWeight: "bold" }}>
                                                                งวดวันที่
                                                            </div>{" "}
                                                        </button>
                                                        <button

                                                            className="col-4 btn btn-remain-text-login p-0"
                                                        >
                                                            {" "}
                                                            เหลือเวลา
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-row">

                                                        <div

                                                            className="lotto-ramian-time-login time-government ng-star-inserted"
                                                            style={{ color: "gray" }}
                                                        >
                                                            <i

                                                                className="fas fa-ban pr-1"
                                                                style={{ fontSize: "medium", alignSelf: "center" }}
                                                            />
                                                            <span

                                                                style={{ fontSize: "1.1rem" }}
                                                            >
                                                                ยังไม่รับแทง
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-flag-on-top-lotto">
                                                <img

                                                    className="flag-on-top-lotto"
                                                    src="/build/web/igame-index-lobby-wm/img/TH.png"
                                                />
                                            </div>
                                            <a

                                                href="#"
                                                data-toggle="modal"
                                                data-target="#loginPopup"
                                            >
                                                <div

                                                    className="name-top-lotto-login ng-star-inserted"
                                                >
                                                    หวยรัฐบาลไทย
                                                </div>



                                            </a>
                                        </div>
                                    </div>
                                    <div

                                        className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 ng-star-inserted"
                                    >
                                        <div className="rounded border-top-lotto">
                                            <div className="top">
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#loginPopup"
                                                >
                                                    <img

                                                        className="img-top-lotto-login"
                                                        src="/build/web/igame-index-lobby-wm/img/login-la.png"
                                                    />
                                                </a>
                                                <div className="detail-top-lotto">
                                                    <div

                                                        className="d-flex flex-row justify-content-between my-0"
                                                    >
                                                        <button

                                                            className="col-8 btn btn-period-text-login p-0 d-flex"
                                                            style={{ justifyContent: "space-around" }}
                                                        >
                                                            <div style={{ fontWeight: "bold" }}>
                                                                งวดวันที่
                                                            </div>{" "}
                                                            21/10/67
                                                        </button>
                                                        <button

                                                            className="col-4 btn btn-remain-text-login p-0"
                                                        >
                                                            {" "}
                                                            เหลือเวลา
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        <div

                                                            className="lotto-ramian-time-login text-success time-government ng-star-inserted"
                                                        >
                                                            <i

                                                                className="far fa-alarm-clock pr-1"
                                                                style={{ fontSize: "medium", alignSelf: "center" }}
                                                            />
                                                            <span

                                                                className="countdown"
                                                                style={{ fontSize: "1.1rem" }}
                                                            >
                                                                07:20:04
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-flag-on-top-lotto">
                                                <img

                                                    className="flag-on-top-lotto"
                                                    src="/build/web/igame-index-lobby-wm/img/LA.png"
                                                />
                                            </div>
                                            <a

                                                href="#"
                                                data-toggle="modal"
                                                data-target="#loginPopup"
                                            >

                                                <div

                                                    className="name-top-lotto-login ng-star-inserted"
                                                >
                                                    หวยลาวพัฒนา
                                                </div>


                                            </a>
                                        </div>
                                    </div>
                                    <div

                                        className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 ng-star-inserted"
                                    >
                                        <div className="rounded border-top-lotto">
                                            <div className="top">
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#loginPopup"
                                                >
                                                    <img

                                                        className="img-top-lotto-login"
                                                        src="/build/web/igame-index-lobby-wm/img/login-vn.png"
                                                    />
                                                </a>
                                                <div className="detail-top-lotto">
                                                    <div

                                                        className="d-flex flex-row justify-content-between my-0"
                                                    >
                                                        <button

                                                            className="col-8 btn btn-period-text-login p-0 d-flex"
                                                            style={{ justifyContent: "space-around" }}
                                                        >
                                                            <div style={{ fontWeight: "bold" }}>
                                                                งวดวันที่
                                                            </div>{" "}
                                                            21/10/67
                                                        </button>
                                                        <button

                                                            className="col-4 btn btn-remain-text-login p-0"
                                                        >
                                                            {" "}
                                                            เหลือเวลา
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        <div

                                                            className="lotto-ramian-time-login text-success time-government ng-star-inserted"
                                                        >
                                                            <i

                                                                className="far fa-alarm-clock pr-1"
                                                                style={{ fontSize: "medium", alignSelf: "center" }}
                                                            />
                                                            <span

                                                                className="countdown"
                                                                style={{ fontSize: "1.1rem" }}
                                                            >
                                                                05:05:04
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-flag-on-top-lotto">
                                                <img

                                                    className="flag-on-top-lotto"
                                                    src="/build/web/igame-index-lobby-wm/img/VN.png"
                                                />
                                            </div>
                                            <a

                                                href="#"
                                                data-toggle="modal"
                                                data-target="#loginPopup"
                                            >


                                                <div

                                                    className="name-top-lotto-login ng-star-inserted"
                                                >
                                                    หวยฮานอย
                                                </div>

                                            </a>
                                        </div>
                                    </div>
                                    <div

                                        className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 ng-star-inserted"
                                    >
                                        <div className="rounded border-top-lotto">
                                            <div className="top">
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#loginPopup"
                                                >
                                                    <img

                                                        className="img-top-lotto-login"
                                                        src="/build/web/igame-index-lobby-wm/img/login-hc2.png"
                                                    />
                                                </a>
                                                <div className="detail-top-lotto">
                                                    <div

                                                        className="d-flex flex-row justify-content-between my-0"
                                                    >
                                                        <button

                                                            className="col-8 btn btn-period-text-login p-0 d-flex"
                                                            style={{ justifyContent: "space-around" }}
                                                        >
                                                            <div style={{ fontWeight: "bold" }}>
                                                                งวดวันที่
                                                            </div>{" "}
                                                            21/10/67
                                                        </button>
                                                        <button

                                                            className="col-4 btn btn-remain-text-login p-0"
                                                        >
                                                            {" "}
                                                            เหลือเวลา
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        <div

                                                            className="lotto-ramian-time-login text-success time-government ng-star-inserted"
                                                        >
                                                            <i

                                                                className="far fa-alarm-clock pr-1"
                                                                style={{ fontSize: "medium", alignSelf: "center" }}
                                                            />
                                                            <span

                                                                className="countdown"
                                                                style={{ fontSize: "1.1rem" }}
                                                            >
                                                                06:05:04
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-flag-on-top-lotto">
                                                <img

                                                    className="flag-on-top-lotto"
                                                    src="/build/web/igame-index-lobby-wm/img/HC2.png"
                                                />
                                            </div>
                                            <a

                                                href="#"
                                                data-toggle="modal"
                                                data-target="#loginPopup"
                                            >


                                                <div

                                                    className="name-top-lotto-login ng-star-inserted"
                                                >
                                                    หวยฮานอย VIP
                                                </div>

                                            </a>
                                        </div>
                                    </div>
                                    <div

                                        className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 ng-star-inserted"
                                    >
                                        <div className="rounded border-top-lotto">
                                            <div className="top">
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#loginPopup"
                                                >
                                                    <img

                                                        className="img-top-lotto-login"
                                                        src="/build/web/igame-index-lobby-wm/img/login-hc.png"
                                                    />
                                                </a>
                                                <div className="detail-top-lotto">
                                                    <div

                                                        className="d-flex flex-row justify-content-between my-0"
                                                    >
                                                        <button

                                                            className="col-8 btn btn-period-text-login p-0 d-flex"
                                                            style={{ justifyContent: "space-around" }}
                                                        >
                                                            <div style={{ fontWeight: "bold" }}>
                                                                งวดวันที่
                                                            </div>{" "}
                                                            21/10/67
                                                        </button>
                                                        <button

                                                            className="col-4 btn btn-remain-text-login p-0"
                                                        >
                                                            {" "}
                                                            เหลือเวลา
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-row">
                                                        <div

                                                            className="lotto-ramian-time-login text-success time-government ng-star-inserted"
                                                        >
                                                            <i

                                                                className="far fa-alarm-clock pr-1"
                                                                style={{ fontSize: "medium", alignSelf: "center" }}
                                                            />
                                                            <span

                                                                className="countdown"
                                                                style={{ fontSize: "1.1rem" }}
                                                            >
                                                                04:05:04
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-flag-on-top-lotto">
                                                <img

                                                    className="flag-on-top-lotto"
                                                    src="/build/web/igame-index-lobby-wm/img/HC.png"
                                                />
                                            </div>
                                            <a

                                                href="#"
                                                data-toggle="modal"
                                                data-target="#loginPopup"
                                            >


                                                <div

                                                    className="name-top-lotto-login ng-star-inserted"
                                                >
                                                    หวยฮานอย พิเศษ
                                                </div>

                                            </a>
                                        </div>
                                    </div> */}

                                </div>
                                <div className="bg-promote-login pc-view">
                                    <div

                                        className="row justify-content-center px-5 mt-4 mb-3 px-lg-3"
                                    >
                                        <div

                                            className="col-3 col-md-4 col-lg-3 col-xl-3"
                                        >
                                            <div

                                                className="row px-4 py-3 content-promote"
                                            >
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#loginPopup"
                                                    className="col-3 bg-promote-center"
                                                >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/shield.svg"
                                                        className="img-promote-center"
                                                    />
                                                </a>
                                                <div

                                                    className="col-6 col-md-9"
                                                    style={{ paddingRight: 0 }}
                                                >
                                                    <div className="text-yellow-login">
                                                        กลุ่มเลขเด็ด
                                                    </div>
                                                    <div className="txt-login-desc3">
                                                        เลขเด็ดเลขดังประจำวัน
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div

                                            className="col-3 col-md-4 col-lg-3 col-xl-3"
                                        >
                                            <div

                                                className="row px-4 py-3 content-promote"
                                            >
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#loginPopup"
                                                    className="col-3 bg-promote-center"
                                                >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/ticket.svg"
                                                        className="img-promote-center"
                                                    />
                                                </a>
                                                <div

                                                    className="col-6 col-md-9"
                                                    style={{ paddingRight: 0 }}
                                                >
                                                    <div className="text-yellow-login">
                                                        วิธีสมัครสมาชิก
                                                    </div>
                                                    <div className="txt-login-desc3">
                                                        ขั้นตอนการสมัครสมาชิก
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div

                                            className="col-3 col-md-4 col-lg-3 col-xl-3"
                                        >
                                            <a

                                                target="_blank"
                                                href={this.state.linelink}
                                            >
                                                <div

                                                    className="row px-4 py-3 content-promote"
                                                >
                                                    <div

                                                        className="col-3 bg-promote-center"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/people-fill.svg"
                                                            className="img-promote-center"
                                                        />
                                                    </div>
                                                    <div

                                                        className="col-6 col-md-9"
                                                        style={{ paddingRight: 0 }}
                                                    >
                                                        <div className="text-yellow-login">
                                                            สมัครผ่านเเอดมิน
                                                        </div>
                                                        <div className="txt-login-desc3">
                                                            ให้แอดมินช่วยสมัครสมาชิก
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div

                                        className="d-flex justify-content-center px-5 pc-view"
                                    >
                                        <div className="col-9 bg-result-login">
                                            <div className="d-flex">
                                                <div className="col-9 result-login-topic">
                                                    {" "}
                                                    ผลหวยล่าสุด{" "}
                                                    <div

                                                        className="row mt-3"
                                                        style={{ backgroundColor: "#060000", borderRadius: 10 }}
                                                    >
                                                        <div

                                                            className="col-12 pr-0 bg-result-login-inner"
                                                        >
                                                            <div

                                                                className="px-2 py-2 mt-2"
                                                                style={{ marginBottom: 15 }}
                                                            >
                                                                <div

                                                                    className="d-flex px-2 py-2 header-th-login"
                                                                >
                                                                    <div >
                                                                        <img

                                                                            src="/build/web/igame-index-lobby-wm/img/TH.png"
                                                                            className="flag-on-top-lotto"
                                                                        />{" "}
                                                                        หวยรัฐบาลไทย
                                                                    </div>
                                                                    <div >วันพุธ 16/10/67</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex px-2">
                                                                <div

                                                                    className="col-6 th-result-login-game th-result-login-only px-4 py-2"
                                                                >
                                                                    <div className="row">
                                                                        <div

                                                                            className="col-8 p-0 pr-1"
                                                                        >
                                                                            <div >รางวัลที่ 1</div>
                                                                            <div

                                                                                className="th-result-login-bg"
                                                                            >
                                                                                482962
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4 p-0">
                                                                            <div

                                                                                style={{ whiteSpace: "nowrap" }}
                                                                            >
                                                                                2 ตัวล่าง
                                                                            </div>
                                                                            <div

                                                                                className="th-result-login-bg"
                                                                            >
                                                                                00
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div

                                                                            className="col-6 p-0 pr-1"
                                                                        >
                                                                            <div

                                                                                style={{ whiteSpace: "nowrap" }}
                                                                            >
                                                                                3 ตัวหน้า
                                                                            </div>
                                                                            <div

                                                                                className="th-result-login-bg3"
                                                                            >
                                                                                648, 561
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 p-0">
                                                                            <div

                                                                                style={{ whiteSpace: "nowrap" }}
                                                                            >
                                                                                3 ตัวล่าง
                                                                            </div>
                                                                            <div

                                                                                className="th-result-login-bg3"
                                                                            >
                                                                                919, 493
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 px-0">
                                                                    <div

                                                                        className="d-flex px-2 px-lg-0 pt-0"
                                                                    >
                                                                        <div

                                                                            className="col-6 col-xl-5 col-md-7 p-0 offset-0 offset-xl-1"
                                                                        />
                                                                        <div

                                                                            className="th-result-login-game col-3 px-0"
                                                                        >
                                                                            3 ตัวบน
                                                                        </div>
                                                                        <div

                                                                            className="th-result-login-game col-3 px-0"
                                                                        >
                                                                            2 ตัวล่าง
                                                                        </div>
                                                                    </div>
                                                                    <div

                                                                        className="d-flex px-2 py-2 px-lg-0 px-xl-2"
                                                                        style={{ justifyContent: "space-between" }}
                                                                    >
                                                                        <div

                                                                            className="col-6 col-xl-5 col-md-7 p-0 offset-0 offset-xl-1"
                                                                        >
                                                                            <img

                                                                                src="/build/web/igame-index-lobby-wm/img/AOM.png"
                                                                                className="flag-on-top-lotto"
                                                                            />{" "}
                                                                            หวยออมสิน
                                                                        </div>
                                                                        <div

                                                                            className="col-3"
                                                                            style={{ textAlign: "center" }}
                                                                        >
                                                                            356
                                                                        </div>
                                                                        <div

                                                                            className="col-3"
                                                                            style={{ textAlign: "center" }}
                                                                        >
                                                                            32
                                                                        </div>
                                                                    </div>
                                                                    <div

                                                                        className="d-flex px-2 py-2 px-lg-0 px-xl-2"
                                                                        style={{ justifyContent: "space-between" }}
                                                                    >
                                                                        <div

                                                                            className="col-6 col-xl-5 col-md-7 p-0 offset-0 offset-xl-1"
                                                                        >
                                                                            <img

                                                                                src="/build/web/igame-index-lobby-wm/img/BAAC.png"
                                                                                className="flag-on-top-lotto"
                                                                            />{" "}
                                                                            หวย ธกส
                                                                        </div>
                                                                        <div

                                                                            className="col-3"
                                                                            style={{ textAlign: "center" }}
                                                                        >
                                                                            002
                                                                        </div>
                                                                        <div

                                                                            className="col-3"
                                                                            style={{ textAlign: "center" }}
                                                                        >
                                                                            62
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div

                                                    className="col-3 result-login-topic-th"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    นับถอยหลังหวยดัง{" "}
                                                    <div

                                                        className="d-flex justify-content-center mt-4 bg-topic-countdown-th-login"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/TH.png"
                                                            className="flag-on-top-lotto"
                                                        />
                                                        <div >หวยรัฐบาลไทย </div>
                                                    </div>
                                                    <div

                                                        className="px-3 px-xl-4 pt-3 mx-2 mx-xl-3 px-lg-2 px-md-1"
                                                    >
                                                        <div

                                                            className="bg-countdown-th"
                                                        >
                                                            <div className="text-countdown-th">
                                                                {countdownMain.days}
                                                            </div>
                                                            <div

                                                                className="text-countdown-th-name-close"
                                                            >
                                                                วัน
                                                            </div>
                                                        </div>
                                                        <div className="vl-close" />
                                                        <div

                                                            className="bg-countdown-th"
                                                        >
                                                            <div className="text-countdown-th">
                                                                {countdownMain.hours}
                                                            </div>
                                                            <div

                                                                className="text-countdown-th-name"
                                                            >
                                                                ชั่วโมง
                                                            </div>
                                                        </div>
                                                        <div className="vl-close" />
                                                        <div

                                                            className="bg-countdown-th"
                                                        >
                                                            <div className="text-countdown-th">
                                                                {countdownMain.minutes}
                                                            </div>
                                                            <div

                                                                className="text-countdown-th-name"
                                                            >
                                                                นาที
                                                            </div>
                                                        </div>
                                                        <div className="vl" />
                                                        <div

                                                            className="bg-countdown-th"
                                                        >
                                                            <div className="text-countdown-th">
                                                                {countdownMain.seconds}
                                                            </div>
                                                            <div

                                                                className="text-countdown-th-name"
                                                            >
                                                                วินาที
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                        className="justify-content-center mt-3"
                                                    >
                                                        <a

                                                            href="#"
                                                            data-toggle="modal"
                                                            data-target="#loginPopup"
                                                        >
                                                            <a
                                                                onClick={(e) => {
                                                                    this.login(e)
                                                                }}
                                                                type="submit"
                                                                className="btn btn-gradiant-login-th btn-block"
                                                            >
                                                                {" "}
                                                                เข้าสู่ระบบ{" "}
                                                            </a>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div

                            id="myModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModal"
                            className="modal fade"
                            style={{ display: "none" }}
                            aria-hidden="true"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-md"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 5 }}
                                >
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">
                                            อัปเดตล่าสุด
                                        </h5>
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="text-white close"
                                        >
                                            <i className="far fa-times" />
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">

                                        <img

                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                height: "inherit !important"
                                            }}
                                            src="https://s3.365huay.net/contents/TAOKAELOTTO/1729237318164.jpg"
                                            className="ng-star-inserted"
                                        />

                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "-1px 2px 5px black"
                                            }}
                                        >
                                            ปิด
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mainlogin" className="ng-star-inserted" hidden={!(this.state.loginstate && tabselect == 0)}>
                        <div

                            id="section-content"
                            className="container"
                            style={{ marginBottom: 10 }}
                        >
                            <div className="mt-0 mt-md-3 p-0 p-md-2 pc-view">
                                <div

                                    className="row main-remain-money mx-md-0 mx-lg-2 py-2 px-0 px-lg-2"
                                >
                                    <div

                                        className="col-12 col-xl-7 col-md-6 order-1 order-md-0 pc-view"
                                    >
                                        <div className="d-flex justify-content-between">
                                            <div className="text-white-balance">
                                                ยอดเงินคงเหลือ
                                            </div>
                                            <div className="py-1">
                                                <a

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/setting-profile")
                                                    }} role="button"


                                                    className="px-3 py-2"
                                                >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/setting-main.png"
                                                        className="image-btn-effect3 setting-main-icon"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="text-balance-main py-1">
                                            {" "}
                                            <NumericFormat value={balance} displayType={'text'} thousandSeparator={true} />{" "}
                                        </div>
                                        <div

                                            className="d-flex"
                                            style={{ alignItems: "center", justifyContent: "space-around" }}
                                        >
                                            <div className="text-white-balance p-0">
                                                USER :
                                            </div>
                                            <div className="bg-username col-6">
                                                {username}
                                            </div>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.checkurl(e, "/statement"); // ใช้ได้ถ้าอยู่ใน class component
                                                }}
                                                role="button"
                                                className="col-4 bg-statement image-btn-effect3"
                                            >
                                                รายงานการเงิน
                                            </a>

                                        </div>
                                    </div>
                                    <div

                                        className="col-12 col-xl-5 col-md-6 order-0 order-md-0"
                                        style={{ alignSelf: "center" }}
                                    >
                                        <div

                                            className="d-flex py-sm-3"
                                            style={{ textAlign: "-webkit-center", justifyContent: "center" }}
                                        >
                                            <div

                                                className="justify-content-center mr-2 px-2 bg-topic-countdown-th-main"
                                                style={{
                                                    textAlign: "center",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <div >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/TH.png"
                                                        className="flag-on-top-lotto-m pr-1"
                                                    />
                                                    <div

                                                        className="text-countdown-th-name"
                                                        style={{ whiteSpace: "nowrap" }}
                                                    >
                                                        หวยไทย{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-countdown-th-main">
                                                <div className="text-countdown-th-main">
                                                    {countdownMain.days}
                                                </div>
                                                <div

                                                    className="text-countdown-th-name-main"
                                                >
                                                    วัน
                                                </div>
                                            </div>
                                            <div className="ulineCountdown" />
                                            <div className="bg-countdown-th-main">
                                                <div className="text-countdown-th-main">
                                                    {countdownMain.hours}
                                                </div>
                                                <div

                                                    className="text-countdown-th-name-main"
                                                >
                                                    ชั่วโมง
                                                </div>
                                            </div>
                                            <div className="ulineCountdown" />
                                            <div className="bg-countdown-th-main">
                                                <div className="text-countdown-th-main">
                                                    {countdownMain.minutes}
                                                </div>
                                                <div

                                                    className="text-countdown-th-name-main"
                                                >
                                                    นาที
                                                </div>
                                            </div>
                                            <div className="ulineCountdown" />
                                            <div className="bg-countdown-th-main">
                                                <div className="text-countdown-th-main">
                                                    {countdownMain.seconds}
                                                </div>
                                                <div

                                                    className="text-countdown-th-name-main"
                                                >
                                                    วินาที
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div

                                        className="d-flex py-2 px-2 mobile-view"
                                        style={{ justifyContent: "space-around", width: "100%" }}
                                    >
                                        <div

                                            className="col-6 pr-0"
                                            style={{ color: "white", fontSize: "1.1rem" }}
                                        >
                                            <div > USER : {username}</div>
                                            <div className="remain-money-text-m">
                                                {" "}
                                                ยอดเงินคงเหลือ{" "}
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div

                                                className="row"
                                                style={{ justifyContent: "flex-end" }}
                                            >
                                                <a

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/statement")
                                                    }}
                                                    role="button"

                                                    className="bg-statement mr-1"
                                                >
                                                    {" "}
                                                    รายงานการเงิน{" "}
                                                </a>
                                                <a

                                                    onClick={(e) => {
                                                        this.checkurl(e, "/setting-profile")
                                                    }} role="button"


                                                    className="bg-setting-main px-2 py-2"
                                                >
                                                    <i className="fas fa-cog" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div

                                        className="col-12 text-balance-main py-2 mobile-view"
                                        style={{ textAlign: "center" }}
                                    >
                                        {" "}
                                        <NumericFormat value={balance} displayType={'text'} thousandSeparator={true} />{" "}
                                    </div>
                                    <div

                                        className="d-flex mt-1 mx-2 broadcast-row mb-1 mobile-view"
                                    >
                                        <div className="broadcast-icon text-center">
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/icon-broadcast.svg"
                                                style={{ width: 15, height: "auto", marginTop: "-5px" }}
                                            />
                                        </div>
                                        <div className="txt-notice-chok px-3">
                                            <marquee behavior="scroll" direction="left" style={{ color: "white" }}>
                                                ยินดีต้อนรับทุกท่านสู่ lotto888 เว็บหวยออนไลน์ที่มาแรงที่สุดในขณะนี้
                                            </marquee>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-main-button-m row">
                                    <div className="mobile-view col-12 px-3 py-2">
                                        <div className="line-main-m" />
                                    </div>
                                    <div className="mobile-view row px-2 py-1 mx-1">
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/lotto")
                                            }} role="button" >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-huay-m.png"
                                                    className="image-btn-effect2"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                        </div>
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/sport")
                                            }}
                                                role="button" >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-sport-m.png"
                                                    className="image-btn-effect2"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                        </div>
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/game-sl")
                                            }}
                                                role="button"
                                            >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-slot-m.png"
                                                    className="image-btn-effect2"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                        </div>
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/games")
                                            }}
                                                role="button"
                                            >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-minigame-m.png"
                                                    className="image-btn-effect2"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobile-view row main-remain-money">
                                    <div className="col-12 px-3 py-1">
                                        <div className="d-flex">
                                            <div className="col-6 px-0 pr-1">
                                                <a onClick={(e) => {
                                                    this.checkurl(e, "/deposit")
                                                }}

                                                    role='button' >
                                                    <div className="deposit d-flex">
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/deposit.svg"
                                                            className="pr-1"
                                                        />{" "}
                                                        ฝากเงิน{" "}
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-6 px-0 pl-1">
                                                <a onClick={(e) => {
                                                    this.checkurl(e, "/withdraw")
                                                }} role="button" >
                                                    <div className="withdraw d-flex">
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/withdraw.svg"
                                                            className="pr-1"
                                                        />{" "}
                                                        ถอนเงิน{" "}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <a

                                            onClick={(e) => {
                                                this.checkurl(e, "/request-all")
                                            }} role="button"

                                        >
                                            <div

                                                className="col-12 px-3 py-2 request-all my-2"
                                            >
                                                {" "}
                                                รายการฝาก/ถอน{" "}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div

                                    className="mobile-view bg-main-button-m row px-3 pb-4"
                                >
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, "/poy")
                                        }}
                                        role="button"
                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-poy.svg"
                                                    className="img-button"
                                                />
                                                <div >โพยหวย </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a

                                        href="#"
                                        data-dismiss="modal"
                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-chat.svg"
                                                    className="img-button"
                                                />
                                                <div >แชทสด </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, "/numbersets")
                                        }} role="button"
                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-number-set.svg"
                                                    className="img-button"
                                                />
                                                <div >สร้างเลขชุด </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a

                                        onClick={(e) => {
                                            this.checkurl(e, "/result-all")
                                        }}
                                        role="button"

                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-result.svg"
                                                    className="img-button"
                                                />
                                                <div >ตรวจผลหวย </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a

                                        href={this.state.linelink}
                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-line.png"
                                                    className="img-button"
                                                />
                                                <div >กลุ่มเลขเด็ด </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, "/affiliate")
                                        }} role="button"
                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-aff.svg"
                                                    className="img-button"
                                                />
                                                <div >แนะนำเพื่อน </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a

                                        href="/howto"

                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-tutorial.svg"
                                                    className="img-button"
                                                />
                                                <div >สอนใช้งาน </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a

                                        href="javascript:void(0);"
                                        className="col-3 px-0 py-2 pr-2"
                                    >
                                        <div className="bg-aura-btn">
                                            <div className="box-btn image-btn-effect2">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/btn-app.svg"
                                                    className="img-button"
                                                />
                                                <div >ติดตั้ง APP </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="col-12 image-btn-effect2">
                                        <a
                                            onClick={(e) => {
                                                this.setState({ showModalcontactbox: true });
                                            }} role="button"
                                            className="report-main-m row px-3"
                                        >
                                            <div className="dot mr-2" />
                                            <div style={{ color: "white" }}>แจ้งปัญหาฝาก/ถอน</div>
                                        </a>
                                    </div>
                                </div>
                                <div

                                    className="row bg-huay-almost-close py-2 mobile-view pb-4 mx-0 my-2"
                                >
                                    <div />
                                    <div

                                        className="head-huay-almost-close mx-3 my-0 py-1"
                                    >
                                        {" "}
                                        ตารางหวยเร็วๆนี้
                                    </div>
                                    <div

                                        style={{ width: "100%", height: "auto" }}
                                        className="ng-star-inserted"
                                    >
                                        <div

                                            className="d-flex mx-1 mt-1 mb-0 bg-near-close-lotto-detail"
                                        >
                                            <div

                                                style={{ flexGrow: 1 }}
                                                className="ng-star-inserted"
                                            >
                                                <div >
                                                    <div

                                                        className="remain-left-red ng-star-inserted"
                                                    >
                                                        <i

                                                            className="far fa-alarm-clock pr-1"
                                                            style={{ fontSize: "large" }}
                                                        />
                                                        00:06:50{" "}
                                                    </div>


                                                </div>
                                                <div className="lotto-name-main">
                                                    หวยลาวทูไนท์{" "}
                                                </div>
                                            </div>


                                            <a

                                                href="/lottery-goverment?pid=333701"
                                                className="image-btn-effect2 ng-star-inserted"
                                                style={{ alignSelf: "center" }}
                                            >
                                                <div

                                                    style={{ justifyContent: "space-around", display: "flex" }}
                                                >
                                                    <div className="ng-star-inserted">
                                                        <div

                                                            className="d-flex px-1 bg-link-product-main"
                                                        >
                                                            <div

                                                                style={{ alignSelf: "center" }}
                                                                className="ng-star-inserted"
                                                            >
                                                                <img

                                                                    className="flag-near-close-lotto"
                                                                    src="/build/web/igame-index-lobby-wm/img/LATN.png"
                                                                />
                                                            </div>


                                                            <div

                                                                className="col-8 px-0 flag-word-main"
                                                            >
                                                                {" "}
                                                                แทงเลย{" "}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </a>

                                        </div>
                                    </div>

                                </div>
                                <div

                                    className="row mx-0 py-2 px-0 px-lg-2 mt-2 pc-view"
                                >
                                    <div className="col-5 bg-main-button px-1">
                                        <div className="row px-4 py-2">
                                            <div

                                                className="d-flex mt-0 mb-1 broadcast-row"
                                            >
                                                <div className="txt-notice-chok px-3">
                                                    <marquee behavior="scroll" direction="left" style={{ color: "white" }}>
                                                        ยินดีต้อนรับทุกท่านสู่ lotto888 เว็บหวยออนไลน์ที่มาแรงที่สุดในขณะนี้
                                                    </marquee>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row px-4 py-1">
                                            <div className="col-6 px-0 pr-1">
                                                <a href="#"
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/deposit")
                                                    }}

                                                    role='button' >
                                                    <div

                                                        className="deposit d-flex image-btn-effect2"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/deposit.svg"
                                                            className="pr-1"
                                                        />{" "}
                                                        ฝากเงิน{" "}
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-6 px-0 pl-1">
                                                <a href="#"
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/withdraw")
                                                    }} role="button" >
                                                    <div

                                                        className="withdraw d-flex image-btn-effect2"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/withdraw.svg"
                                                            className="pr-1"
                                                        />{" "}
                                                        ถอนเงิน{" "}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row px-4 py-1">
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    this.checkurl(e, "/request-all")
                                                }} role="button"

                                                className="col-12 request-all image-btn-effect2"
                                            >
                                                {" "}
                                                รายการฝาก/ถอน{" "}
                                            </a>
                                        </div>
                                        <div className="row px-4">
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    this.checkurl(e, "/poy")
                                                }}
                                                role="button"
                                                className="col-3 px-0 py-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-poy.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    this.handleClickChatScript(e)
                                                }}
                                                role="button"
                                                className="col-3 px-0 py-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-chat.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    this.checkurl(e, "/numbersets")
                                                }} role="button"
                                                className="col-3 px-0 py-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-number-set.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    this.checkurl(e, "/result-all")
                                                }}
                                                role="button"

                                                className="col-3 px-0 py-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-result.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a

                                                href={this.state.linelink}
                                                className="col-3 px-0 pb-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-line.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    this.checkurl(e, "/uploadslip")
                                                }}
                                                role="button"
                                                className="col-3 px-0 pb-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/06_41_07.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    this.checkurl(e, "/affiliate")
                                                }}
                                                role="button"
                                                className="col-3 px-0 pb-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-aff.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a

                                                href="/howto"
                                                className="col-3 px-0 pb-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-tutorial.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <a
                                                href="#"
                                                onClick={this.handleInstallClick}
                                                role="button"
                                                className="col-3 px-0 pb-2"
                                            >
                                                <div className="px-1">
                                                    <div >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/btn-app.png"
                                                            className="img-button image-btn-effect3"
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-12 px-4 image-btn-effect2">
                                            <a
                                                onClick={(e) => {
                                                    this.setState({ showModalcontactbox: true });
                                                }} role="button"
                                                className="report-main row"
                                            >
                                                <div className="dot mr-2" />
                                                <div style={{ color: "white" }} >แจ้งปัญหาฝาก/ถอน</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-7">
                                        <div className="row ml-1 mb-2">
                                            <div className="col-3 px-1">
                                                <a onClick={(e) => {
                                                    this.checkurl(e, "/lotto")
                                                }} role="button" >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/banner-huay.png"
                                                        className="image-btn-effect2"
                                                        style={{ width: "100%", height: "auto" }}
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-3 px-1">
                                                <a onClick={(e) => {
                                                    this.checkurl(e, "/sport")
                                                }}
                                                    role="button" >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/banner-sport.png"
                                                        className="image-btn-effect2"
                                                        style={{ width: "100%", height: "auto" }}
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-3 px-1">
                                                <a onClick={(e) => {
                                                    this.checkurl(e, "/game-sl")
                                                }} role="button" >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/banner-slot.png"
                                                        className="image-btn-effect2"
                                                        style={{ width: "100%", height: "100%" }}
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-3 px-1">
                                                <a onClick={(e) => {
                                                    this.checkurl(e, "/games")
                                                }}
                                                    role="button"
                                                >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/banner-minigame.png"
                                                        className="image-btn-effect2"
                                                        style={{ width: "100%", height: "100%" }}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row ml-1 bg-huay-almost-close py-3">
                                            <div />
                                            <div _ngcontent-gov-c45="" className="head-huay-almost-close mx-3 my-0">
                                                {" "}
                                                ตารางหวยเร็วๆนี้
                                            </div>

                                            {filledRows}
                                            {emptyRows}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-0 mt-md-2 p-0">
                                <div className="row mx-0 pt-2 px-0 mobile-view">
                                    <div

                                        className="col-12 col-md-7 order-1 order-md-0 px-0 pt-0 pb-2 pb-md-0 my-1"
                                    >
                                        <div

                                            className="d-flex pt-sm-1 pb-0"
                                            style={{ textAlign: "-webkit-center", justifyContent: "center" }}
                                        >
                                            <div

                                                className="countdown-th-circle d-flex mb-1"
                                            >
                                                <div

                                                    className="text-countdown-th-main-name mx-1"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/TH.png"
                                                        className="flag-on-countdown-m"
                                                    />
                                                </div>
                                                <div className="mx-1">
                                                    <div className="bg-countdown-th-main">
                                                        <div className="text-countdown-th-main">
                                                            {countdownMain.days}
                                                        </div>
                                                    </div>
                                                    <div

                                                        className="text-countdown-th-name-main2"
                                                    >
                                                        วัน
                                                    </div>
                                                </div>
                                                <div className="mx-1">
                                                    <div className="bg-countdown-th-main">
                                                        <div className="text-countdown-th-main">
                                                            {countdownMain.hours}
                                                        </div>
                                                    </div>
                                                    <div

                                                        className="text-countdown-th-name-main2"
                                                    >
                                                        ชั่วโมง
                                                    </div>
                                                </div>
                                                <div className="mx-1">
                                                    <div className="bg-countdown-th2-main">
                                                        <div className="text-countdown-th-main">
                                                            {countdownMain.minutes}
                                                        </div>
                                                    </div>
                                                    <div

                                                        className="text-countdown-th-name-main"
                                                    >
                                                        นาที
                                                    </div>
                                                </div>
                                                <div className="mx-1">
                                                    <div className="bg-countdown-th2-main">
                                                        <div className="text-countdown-th-main">
                                                            {countdownMain.seconds}
                                                        </div>
                                                    </div>
                                                    <div

                                                        className="text-countdown-th-name-main"
                                                    >
                                                        วินาที
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div

                                    className="mobile-view d-flex text-white"
                                    style={{ fontSize: "1.3rem" }}
                                >
                                    <div className="user-txt-m mr-1 px-1">
                                        USER
                                    </div>
                                    {username}{" "}
                                </div>
                                <div className="mobile-view position-relative my-2">
                                    <a

                                        onClick={(e) => {
                                            this.checkurl(e, "/setting-profile")
                                        }} role="button"


                                        className="bg-balance-m"
                                    >
                                        <img

                                            src="/build/web/igame-index-lobby-wm/img/card-remain.png"
                                            className="image-btn-effect2"
                                            style={{ width: "66%" }}
                                        />
                                    </a>
                                    <div

                                        className="text-balance-main text-remain-money-m px-3 pb-0"
                                    >
                                        <img

                                            src="/build/web/igame-index-lobby-wm/img/coin-bag-solid.svg"
                                            className="mr-1"
                                            style={{ alignSelf: "center", height: 12 }}
                                        />
                                        ยอดเงินคงเหลือ{" "}
                                        <div

                                            className="mt-1"
                                            style={{ fontSize: "1.8rem" }}
                                        >
                                            <NumericFormat value={balance} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                    </div>
                                </div>
                                <div

                                    className="mx-0 my-0 pt-0 pb-2 d-flex mobile-view"
                                >
                                    <div className="uline-app-m align-self-center" />
                                </div>
                                <div

                                    className="d-flex mt-1 broadcast-row mb-1 mx-0 mobile-view"
                                >
                                    <div className="broadcast-icon text-center pl-2">
                                        <img

                                            src="/build/web/igame-index-lobby-wm/img/icon-boardcast.svg"
                                            style={{ alignSelf: "center", width: 43 }}
                                        />
                                    </div>
                                    <div className="txt-notice-main px-2">
                                        <marquee behavior="scroll" direction="left" style={{ color: "white" }}>
                                            ยินดีต้อนรับทุกท่านสู่ lotto888 เว็บหวยออนไลน์ที่มาแรงที่สุดในขณะนี้
                                        </marquee>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mobile-view row px-2 py-1 mx-1">
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/lotto")
                                            }} role="button" >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-huay-m.png"
                                                    className="image-btn-effect2 card-3main-m"
                                                />
                                            </a>
                                        </div>
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/sport")
                                            }}
                                                role="button" >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-sport-m.png"
                                                    className="image-btn-effect2 card-3main-m"
                                                />
                                            </a>
                                        </div>
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/game-sl")
                                            }} role="button" >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-slot-m.png"
                                                    className="image-btn-effect2 card-3main-m"
                                                />
                                            </a>
                                        </div>
                                        <div className="col-6 p-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/games")
                                            }}
                                                role="button"
                                            >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/banner-minigame-m.png"
                                                    className="image-btn-effect2 card-3main-m"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div

                                    className="mobile-view row px-3 my-1 align-items-stretch"
                                >
                                    <div className="col-7 px-0 pr-1">
                                        <div className="pb-1">
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/deposit")
                                            }}

                                                role='button' >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/deposit-m.svg"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                        </div>
                                        <div >
                                            <a onClick={(e) => {
                                                this.checkurl(e, "/withdraw")
                                            }} role="button" >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/withdraw-m.svg"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-5 px-0">
                                        <div

                                            className="bg-report-statement p-1 h-100 row mx-0 align-items-center"
                                        >
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/statement")
                                                }} role="button"
                                                className="px-0 btn-main2 col-12"
                                            >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/statement-m.svg"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/statement")
                                                }}
                                                role="button"
                                                className="px-0 btn-main2 col-12 pt-1"
                                            >
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/statement-m.svg"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div

                                    className="mx-2 mt-3 pt-0 pb-1 d-flex w-100 mobile-view pr-3"
                                >
                                    <div

                                        className="text-white pr-1"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        USER MENU
                                    </div>
                                    <div className="uline-app-m align-self-center" />
                                </div>
                            </div>
                            <div

                                className="mobile-view bg-main-btn-m"
                                style={{ flexDirection: "column" }}
                            >
                                <div className="px-3 pb-2">
                                    <div className="row px-2">
                                        <a
                                            onClick={(e) => {
                                                this.checkurl(e, "/poy")
                                            }}
                                            role="button"
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-poy.png"
                                                        className="img-button image-btn-effect3"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                this.handleClickChatScript(e)
                                            }}
                                            role="button"
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-chat.png"
                                                        className="img-button"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                this.checkurl(e, "/numbersets")
                                            }}
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-number-set.png"
                                                        className="img-button"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <a

                                            onClick={(e) => {
                                                this.checkurl(e, "/result-all")
                                            }}
                                            role="button"
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-result.png"
                                                        className="img-button"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <a

                                            href={this.state.linelink}
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-line.png"
                                                        className="img-button"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                this.checkurl(e, "/affiliate")
                                            }}
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-aff.png"
                                                        className="img-button"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                this.checkurl(e, "/howto")
                                            }}
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-tutorial.png"
                                                        className="img-button"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <a

                                            onClick={this.handleInstallClick}
                                            role="button"
                                            className="col-3 px-0 py-2"
                                        >
                                            <div >
                                                <div className="image-btn-effect2">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/btn-app.png"
                                                        className="img-button"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                        <div className="col-12 px-3 image-btn-effect2">
                                            <a

                                                href="#"
                                                data-toggle="modal"
                                                data-target="#contactbox"
                                                className="report-main-m row px-3"
                                            >
                                                <div >แจ้งปัญหา 24 ชม.</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div

                                    className="mx-3 my-0 pt-0 pb-2 d-flex px-3 w-100"
                                >
                                    <div

                                        className="text-white pr-1"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        หวยเร็วๆนี้
                                    </div>
                                    <div className="uline-app-m align-self-center" />
                                </div>
                                <div className="row mx-2 px-2 py-2 pb-4">
                                    {filledRowsMobile}
                                    {emptyRowsMobile}
                                    {/* {filledRows}
                                    {emptyRowsx} */}
                                    {/* {lotteryData.slice(0, 5).map((item, index) => (
                                        <div
                                            key={item.roomid} // ใช้ roomid เป็น key
                                            style={{ width: "100%", height: "auto" }}
                                            className="ng-star-inserted"
                                        >
                                            <div

                                                className="d-flex mx-0 mx-lg-3 mt-1 mt-lg-2 mb-0 bg-near-close-lotto-detail-1"
                                            >
                                                <div

                                                    className="space-box-white-remain-time ng-star-inserted"
                                                >
                                                    <div className="lotto-name-main-white">
                                                        {item.name}{" "}
                                                    </div>
                                                    <div className="bg-main-remain-time">
                                                        <div

                                                            className="remain-left-red ng-star-inserted"
                                                        >
                                                            <svg
                                                                className='pr-1'
                                                                width="22.08"
                                                                fill="#000000"
                                                                version="1.1"
                                                                id="Capa_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                viewBox="0 0 345.57 345.57"
                                                                xmlSpace="preserve"
                                                            >
                                                                <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                                                <g id="SVGRepo_iconCarrier">
                                                                    {" "}
                                                                    <g>
                                                                        {" "}
                                                                        <g>
                                                                            {" "}
                                                                            <g>
                                                                                {" "}
                                                                                <path d="M172.785,0h-15.583v82.164h31.156V32.018c71.392,7.773,126.035,67.902,126.035,140.768 c0,78.096-63.531,141.626-141.608,141.626c-78.08,0-141.608-63.53-141.608-141.626c0-31.963,10.386-62.123,30.024-87.211 l5.491-6.995L42.145,59.37l-5.479,6.996C12.679,96.993,0,133.796,0,172.785C0,268.054,77.507,345.57,172.785,345.57 c95.269,0,172.785-77.504,172.785-172.785C345.57,77.507,268.054,0,172.785,0z" />{" "}
                                                                            </g>{" "}
                                                                            <g>
                                                                                {" "}
                                                                                <path d="M147.949,199.257c5.645,6.768,13.577,10.924,22.314,11.704c1.009,0.096,2.017,0.144,3.008,0.144 c16.858,0,31.264-13.174,32.777-29.97c0.792-8.749-1.874-17.297-7.512-24.073c-5.645-6.776-13.571-10.938-22.332-11.736 c-2.88-0.252-5.801-0.099-8.905,0.511l-95.167-80.65l69.908,102.736c-0.879,2.558-1.414,4.945-1.625,7.269 C139.624,183.939,142.299,192.49,147.949,199.257z" />{" "}
                                                                            </g>{" "}
                                                                        </g>{" "}
                                                                    </g>{" "}
                                                                </g>
                                                            </svg>

                                                            {countdowns[item.roomid] || "กำลังโหลด..."}
                                                        </div>


                                                    </div>
                                                </div>


                                                <a

                                                    onClick={(e) => this.redeemlotto(e, item._id, item.roomid)}
                                                    role="button"
                                                    className="image-btn-effect2 ng-star-inserted"
                                                    style={{ alignSelf: "center" }}
                                                >
                                                    <div

                                                        style={{ justifyContent: "space-around", display: "flex" }}
                                                    >
                                                        <div className="ng-star-inserted">
                                                            <div

                                                                className="d-flex px-1 image-btn-effect3 bg-link-product-main blink1"
                                                            >
                                                                <div

                                                                    style={{ alignSelf: "center" }}
                                                                    className="ng-star-inserted"
                                                                >
                                                                    <img

                                                                        className="flag-near-close-lotto"
                                                                        src="/build/web/igame-index-lobby-wm/img/LATN.png"
                                                                    />
                                                                </div>


                                                                <div

                                                                    className="col-8 px-0 flag-word-main"
                                                                >
                                                                    {" "}
                                                                    แทงเลย{" "}
                                                                </div>
                                                            </div>
                                                            <div

                                                                className="close-time-near-close-lotto"
                                                            >
                                                                ปิดรับ 23:00
                                                            </div>
                                                        </div>


                                                    </div>
                                                </a>

                                            </div>
                                        </div>
                                    ))} */}
                                </div>
                            </div>
                            <div

                                id="myModal"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="myModal"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-md"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div className="modal-header bg-theme-primary">
                                            <h5 className="modal-title">
                                                อัปเดตล่าสุด
                                            </h5>
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                className="text-white close"
                                            >
                                                <i className="far fa-times" />
                                            </button>
                                        </div>
                                        <div className="modal-body text-center">


                                        </div>
                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ปิด{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div

                                id="surveyModal"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="surveyModal"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-md"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div className="modal-header bg-theme-primary">
                                            <h5 className="modal-title">
                                                แบบสำรวจ
                                            </h5>
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                className="text-white close"
                                            >
                                                <span aria-hidden="true">
                                                    ×
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mx-2 my-3">
                                                ท่านรู้จักเว็บไซท์ของเรามาจากทางช่องทางใด?
                                            </div>

                                        </div>
                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ปิด
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div

                                id="appModal"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="myModal"
                                aria-hidden="true"
                                data-backdrop="static"
                                data-keyboard="false"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-lg"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div className="modal-header bg-theme-primary">
                                            <h5 className="modal-title">
                                                ติดตั้งแอปฯ
                                            </h5>
                                        </div>
                                        <div className="modal-body">


                                        </div>
                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                            >
                                                ตกลง
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div

                                id="linebox"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="linebox"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-lg"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div className="modal-header bg-theme-primary">
                                            <h5 className="modal-title">
                                                กลุ่มเลขเด็ด
                                            </h5>
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                className="text-white close"
                                            >
                                                <span aria-hidden="true">
                                                    ×
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row p-0 m-0">
                                                <div

                                                    className="col-6 col-sm-6 col-md-4 text-center mb-2 group-yeekee ng-star-inserted"
                                                    style={{ cursor: "pointer" }}
                                                    data-index={0}
                                                >
                                                    <img

                                                        className="mt-2"
                                                        style={{ width: "auto", height: 140 }}
                                                        src="https://s3.365huay.net/guideGroup/TAOKAELOTTO/1678269003372.jpg"
                                                    />
                                                    <div className="text-theme-primary mt-2">
                                                        เถ้าเเก่ ให้โชค
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ปิด
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ngx-spinner

                                bdcolor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="timer"

                                className="ng-tns-c12-13"
                            >

                            </ngx-spinner>
                            <div

                                id="openNewBrowserMain"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="myModal"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-lg"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div className="modal-header bg-theme-primary">
                                            <h5 className="modal-title">
                                                เบราว์เซอร์ไม่รองรับ กรุณาทำตามขั้นตอนต่อไปนี้
                                            </h5>
                                        </div>
                                        <div className="modal-body">

                                        </div>
                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ตกลง
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="gamesl" hidden={!(this.state.loginstate && tabselect == 9)} className="ng-star-inserted">
                        <div id="section-content" className="container p-1">
                            <div className="bar-back">
                                <div

                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <a onClick={(e) => {
                                        this.checkurl(e, "/")
                                    }} role="button"

                                    >
                                        <i className="fas fa-chevron-left" /> หน้าหลัก
                                    </a>
                                    <a
                                        onClick={(e) => {
                                            this.checkurl(e, "/game-report")
                                        }} role="button"
                                        className="btn btn-theme-primary btn-sm float-right btn-game-history"
                                    >
                                        ประวัติการเล่น
                                    </a>
                                </div>
                            </div>

                            <div

                                className="p-0 w-100 main-content align-self-stretch"
                            >

                                <div

                                    id="liveProviders-section"
                                    className="text-theme-primary rounded p-2 px-3 xtarget col-lotto ng-star-inserted"
                                >
                                    <div className="d-flex justify-content-center">
                                        <img

                                            src="/build/web/igame-index-lobby-wm/img/bg-casino.png"
                                            className="header"
                                        />
                                        <div className="game-head-title">
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/casino.svg"
                                                className="icon-game"
                                            />
                                            คาสิโนออนไลน์
                                        </div>
                                    </div>
                                    <div />
                                    <div className="row boxgame">
                                        {categorylist.map((listitem, i) => (
                                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 p-2 ng-star-inserted"
                                            >
                                                <div className="rounded ng-star-inserted">
                                                    <a role="button" onClick={(e) => {
                                                        this.checkurl(e, "/gamelist/casino/" + listitem.partner)
                                                    }}>
                                                        <img

                                                            className="img-game"
                                                            src={listitem.image}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>

                                <div id="slotProviders-section"
                                    className="text-theme-primary rounded p-2 px-3 xtarget col-lotto ng-star-inserted"
                                >
                                    <div className="d-flex justify-content-center">
                                        <img

                                            src="/build/web/igame-index-lobby-wm/img/bg-slot.png"
                                            className="header"
                                        />
                                        <div className="game-head-title">
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/slot.svg"
                                                className="icon-game"
                                            />
                                            สล็อต
                                        </div>
                                    </div>
                                    <div className="row boxgame boxgame-slot">

                                        {slotlist.map((listitem, i) => (
                                            <div

                                                className="col-6 col-md-4 col-lg-4 col-xl-4 p-2 ng-star-inserted"
                                            >
                                                <div className="rounded ng-star-inserted">
                                                    <a role="button" onClick={(e) => {
                                                        this.checkurl(e, "/gamelist/slot/" + listitem.partner)
                                                    }}>
                                                        <img

                                                            className="img-game"
                                                            src={listitem.image}
                                                        />
                                                    </a>
                                                </div>


                                            </div>
                                        ))}

                                    </div>
                                </div>


                                <div

                                    id="fishProviders-section"
                                    className="text-theme-primary rounded p-2 px-3 xtarget col-lotto ng-star-inserted"
                                >
                                    <div className="d-flex justify-content-center">
                                        <img

                                            src="/build/web/igame-index-lobby-wm/img/bg-fish.png"
                                            className="header"
                                        />
                                        <div className="game-head-title">
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/fish.svg"
                                                className="icon-game"
                                            />
                                            ยิงปลา
                                        </div>
                                    </div>
                                    <div className="row boxgame boxgame-fish">
                                        {fishinglist.map((listitem, i) => (
                                            <div

                                                className="col-6 col-md-4 col-lg-4 col-xl-4 p-2 ng-star-inserted"
                                            >
                                                <div className="rounded ng-star-inserted">
                                                    <a role="button" onClick={(e) => {
                                                        this.checkurl(e, "/gamelist/fishing/" + listitem.partner)
                                                    }}>
                                                        <img

                                                            className="img-game"
                                                            src={listitem.image}
                                                        />
                                                    </a>
                                                </div>


                                            </div>

                                        ))}
                                    </div>
                                </div>


                            </div>

                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"

                            className="ng-tns-c12-19"
                        >

                        </ngx-spinner>
                        <div

                            id="openNewBrowser"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModal"
                            aria-hidden="true"
                            className="modal fade"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-lg"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">
                                            เบราว์เซอร์ไม่รองรับ กรุณาทำตามขั้นตอนต่อไปนี้
                                        </h5>
                                    </div>
                                    <div className="modal-body">

                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "-1px 2px 5px black"
                                            }}
                                        >
                                            ตกลง{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="gamesport" hidden={!(this.state.loginstate && tabselect == 14)} className="ng-star-inserted">
                        <div id="section-content" className="container p-1">
                            <div className="bar-back">
                                <div

                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <a onClick={(e) => {
                                        this.checkurl(e, "/")
                                    }} role="button" >
                                        <i className="fas fa-chevron-left" /> หน้าหลัก
                                    </a>
                                    <a

                                        href="javascript:void(0)"
                                        className="btn btn-theme-primary btn-sm float-right btn-sport-history"
                                    >
                                        ประวัติการเล่น
                                    </a>
                                </div>
                            </div>

                            <div

                                className="p-0 w-100 main-content align-self-stretch"
                            >

                                <div

                                    className="text-theme-primary rounded p-2 px-3 xtarget col-lotto"
                                >
                                    <div className="d-flex justify-content-center">
                                        <img

                                            src="/build/web/igame-index-lobby-wm/img/bg-card.png"
                                            className="header"
                                        />
                                        <div className="game-head-title">
                                            <img

                                                src="/build/web/igame-index-lobby-wm/img/sport.svg"
                                                className="icon-game"
                                            />
                                            กีฬา
                                        </div>
                                    </div>
                                    <div />
                                    <div className="row boxgame boxgame-card">

                                        {sportlist.map((listitem, i) => (
                                            <div className="col-6 col-md-4 col-lg-4 col-xl-4 p-2 ng-star-inserted"
                                            >
                                                <div className="rounded ng-star-inserted">
                                                    <a onClick={(e) => {
                                                        this.checkurl(e, "/gamelist/sport/" + listitem.partner)
                                                    }}>
                                                        <img

                                                            className="img-game"
                                                            src={listitem.image}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-enp-c12=""
                            className="ng-tns-c12-9"
                        >

                        </ngx-spinner>
                        <div

                            id="openNewBrowser"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModal"
                            aria-hidden="true"
                            className="modal fade"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-lg"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">
                                            เบราว์เซอร์ไม่รองรับ กรุณาทำตามขั้นตอนต่อไปนี้
                                        </h5>
                                    </div>
                                    <div className="modal-body">

                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "-1px 2px 5px black"
                                            }}
                                        >
                                            ตกลง{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="detailModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModal"
                            aria-hidden="true"
                            data-backdrop="static"
                            data-keyboard="false"
                            className="modal fade"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-lg"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">
                                            รายละเอียด
                                        </h5>
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="text-white close"
                                        >
                                            <span aria-hidden="true">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <div

                                            className="bg-white border rounded mt-2"
                                            style={{ overflowX: "scroll" }}
                                        >
                                            <table

                                                id="numbersets"
                                                className="datatable table table-hover table-striped table-sm"
                                            >
                                                <thead >
                                                    <tr >
                                                        <th nowrap="">
                                                            เวลาที่ทำรายการ
                                                        </th>
                                                        <th nowrap="">
                                                            ผู้ให้บริการ
                                                        </th>
                                                        <th nowrap="">
                                                            เลขที่ตั๋ว
                                                        </th>
                                                        <th nowrap="">
                                                            อีเวนต์
                                                        </th>
                                                        <th nowrap="">
                                                            ยอดยกมา
                                                        </th>
                                                        <th nowrap="">
                                                            เครดิต
                                                        </th>
                                                        <th nowrap="">
                                                            ยอดคงเหลือ
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody >

                                                    <tr className="ng-star-inserted">
                                                        <td

                                                            colSpan={99}
                                                            className="text-center"
                                                        >
                                                            ไม่มีรายการ
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                        >
                                            ปิด
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="deposit" hidden={!(this.state.loginstate && tabselect == 1)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/")
                                }} role="button" >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch"
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div className="lotto-title">
                                        <h4 >
                                            <i className="fas fa-donate" />
                                            &nbsp;<b >แจ้งเติมเครดิต</b>
                                        </h4>
                                    </div>
                                </div>
                                <div

                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-1 mb-5 xtarget col-lotto"
                                >


                                    <div

                                        className="row w-100 p-0 m-0 d-flex justify-content-center"
                                        hidden=""
                                    >


                                    </div>
                                    <div

                                        className="row w-100 p-0 m-0 d-flex justify-content-center"
                                    >
                                        <div

                                            id="destep1"
                                            className="row col-12 col-sm-12 col-md-6 py-2 px-0 my-1 border rounded"
                                            hidden=""
                                        >
                                            <div className="col-12 px-1">
                                                <h6 className="font-weight-normal">
                                                    บัญชีธนาคาร{" "}
                                                </h6>
                                            </div>
                                            <div className="col-12">
                                                <label className="ng-star-inserted">
                                                    <i className="fas fa-university" />{" "}
                                                    บัญชีธนาคารของลูกค้า
                                                </label>

                                                <div className="mb-2 ng-star-inserted">
                                                    <div

                                                        className="alert row"
                                                    >
                                                        <img

                                                            with={70}
                                                            height={70}
                                                            src={accnumber !== null ? accnumberx.img_path : ""}
                                                        />
                                                        <div

                                                            className="pc-view"
                                                            style={{
                                                                fontSize: "110%",
                                                                alignSelf: "center",
                                                                textShadow: "1px 1px 0px rgb(255 255 255 / 30%)",
                                                                paddingLeft: 10
                                                            }}
                                                        >
                                                            {" "}
                                                            {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""} : {accnumber !== null ? accnumberx.fullname_th : ""}{" "}
                                                        </div>
                                                        <div

                                                            className="mobile-view"
                                                            style={{
                                                                fontSize: "110%",
                                                                alignSelf: "center",
                                                                textShadow: "1px 1px 0px rgb(255 255 255 / 30%)",
                                                                paddingLeft: 10
                                                            }}
                                                        >
                                                            {" "}
                                                            {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""} : {accnumber !== null ? accnumberx.fullname_th : ""}{" "}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-12" hidden="">
                                                <div className="text-danger mt-2">
                                                    {" "}
                                                    กรุณาโอนด้วยบัญชีที่ผูกไว้ในระบบเท่านั้น !!!!{" "}
                                                </div>
                                            </div>
                                            <div className="col-12 pt-2">
                                                <label

                                                    id="labelselectbank"
                                                    className="ng-star-inserted"
                                                >
                                                    <i className="fas fa-university" />{" "}
                                                    บัญชีธนาคารของเว็บ
                                                </label>

                                            </div>

                                            <div className="col-12 py-1 ng-star-inserted">
                                                {mainacc !== null && mainacc != undefined ?
                                                    <div className="border rounded bg-light">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div

                                                                    className="row py-1 px-3 justify-content-center"
                                                                >
                                                                    <div

                                                                        className="col-3 col-sm-3 col-md-4 col-lg-3 pt-2"
                                                                    >
                                                                        <img

                                                                            alt="หวยหุ้น"
                                                                            width="100%"
                                                                            id="bank2"
                                                                            className="detail-bank rounded ng-star-inserted"
                                                                            src={mainacc.img_path}
                                                                        // style={{ backgroundColor: "rgb(27, 165, 225)" }}
                                                                        />

                                                                    </div>
                                                                    <div

                                                                        className="col-9 col-sm-9 col-md-8 col-lg-5 p-2 d-flex flex-column justify-content-center align-items-start"
                                                                    >
                                                                        <h6

                                                                            className="numacc pb-1"
                                                                            style={{ color: "rgb(27, 165, 225)" }}
                                                                        >
                                                                            {mainacc.fullname_th}
                                                                        </h6>
                                                                        <h6

                                                                            id="acc2"
                                                                            className="numacc pb-1"
                                                                            style={{ color: "rgb(27, 165, 225)" }}
                                                                        >
                                                                            {mainacc.accnumber.substring(0, 3) + "-" + mainacc.accnumber.substring(3, 9) + "-" + mainacc.accnumber.substring(9, 12)}
                                                                        </h6>
                                                                        <span>{mainacc.first_name_en + " " + mainacc.last_name_en}</span>
                                                                    </div>
                                                                    <button href={() => false} style={{ cursor: "pointer", height: 80 }}
                                                                        onClick={() => navigator.clipboard.writeText(mainacc.accnumber)}
                                                                        type="button"
                                                                        ngxclipboard=""
                                                                        className="btn btn-light btn-sm border border-secondary copyacc mr-3 d-flex flex-column align-items-center align-content-center p-2 pc-view"

                                                                    >
                                                                        <i className="fas fa-copy" />
                                                                        <div

                                                                            className="d-flex flex-grow-1 align-items-center"
                                                                        >
                                                                            คัดลอก
                                                                            <br />
                                                                            เลขบัญชี
                                                                        </div>
                                                                    </button>
                                                                    <button href={() => false} style={{ cursor: "pointer", width: "-webkit-fill-available", height: 45 }}
                                                                        onClick={() => navigator.clipboard.writeText(mainacc.accnumber)}
                                                                        type="button"
                                                                        ngxclipboard=""
                                                                        className="btn btn-light btn-sm border border-secondary d-flex flex-row align-items-center align-content-center mx-2 mobile-view"

                                                                    >
                                                                        <div

                                                                            className="d-flex flex-grow-1 align-items-center justify-content-center"
                                                                        >
                                                                            <i className="fas fa-copy" />
                                                                            &nbsp;คัดลอกเลขบัญชี
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>


                                        </div>
                                        <div className="w-100" />
                                    </div>
                                </div>


                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-ovj-c12=""
                            className="ng-tns-c12-13"
                        >

                        </ngx-spinner>
                    </div>
                    <div id="withdraw" hidden={!(this.state.loginstate && tabselect == 2)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/")
                                }} role="button" >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch ng-star-inserted"
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div className="lotto-title">
                                        <h4 >
                                            <i className="fas fa-vote-yea" />
                                            &nbsp;<b >แจ้งถอนเงิน</b>
                                        </h4>
                                    </div>
                                </div>
                                <div

                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 xtarget col-lotto"
                                >
                                    <form

                                        noValidate=""
                                        action="member/withdraw"
                                        encType="application/x-www-form-urlencoded"
                                        id="withdraw"
                                        role="form"
                                        method="post"
                                        acceptCharset="utf-8"
                                        className="ng-untouched ng-pristine ng-valid"
                                    >
                                        <div className="form-row">
                                            <div className="col-12">
                                                <label >
                                                    <i className="fas fa-money-check" />{" "}
                                                    เลือกบัญชีธนาคารของท่าน
                                                    <br />
                                                    <small >
                                                        <p style={{ color: "red" }}>
                                                            *กรุณาเลือกบัญชีด้านล่างตามที่ท่านต้องการ
                                                        </p>
                                                    </small>
                                                </label>
                                            </div>
                                            <div

                                                className="col-6 col-sm-6 col-md-3 col-lg-2 text-center ng-star-inserted"
                                            >
                                                <input

                                                    type="radio"
                                                    name="bank2"
                                                    className="input-hidden"
                                                    id="014-2192593953"
                                                    defaultValue={2192593953}
                                                    data-acc={firstname}
                                                    data-name={firstname}
                                                    data-bank={accnumber !== null ? accnumberx.img_path : ""}
                                                />
                                                <label

                                                    className="bank-radio"
                                                    htmlFor="014-2192593953"
                                                >
                                                    <img

                                                        className="icon-bank bnk48_011"
                                                        src={accnumber !== null ? accnumberx.img_path : ""}
                                                        alt={accnumber !== null ? accnumberx.img_path : ""}
                                                    />
                                                    <br />
                                                    <span >ธนาคาร{accnumber !== null ? accnumberx.fullname_th : ""}</span>
                                                    <span className="badge badge-dark">
                                                        {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                    </span>
                                                    <span >  {firstname}</span>
                                                </label>
                                            </div>

                                            <div className="border-bottom w-100 my-2" />
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <label >
                                                    <i className="fas fa-coins" />{" "}
                                                    จำนวนเงินที่ถอนได้
                                                </label>
                                                <br />
                                                <div

                                                    className="alert alert-success py-2"
                                                    style={{ height: "3rem" }}
                                                >
                                                    <h3

                                                        data-id="credit_balance"
                                                        className="thb text-success text-center my-0 w-100 ng-star-inserted"
                                                    >
                                                        {" "}
                                                        <NumericFormat value={balance} displayType={'text'} thousandSeparator={true} /> ฿
                                                    </h3>


                                                </div>
                                                <small className="text-secondary">
                                                    จำนวนเงินในกระเป๋าอาจจะมีบางส่วนถูกล็อคสำหรับการแทงขั้นต่ำ
                                                </small>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <label >
                                                    <i className="fas fa-hand-holding-usd" />{" "}
                                                    จำนวนเงินที่ต้องการถอน
                                                </label>
                                                <br />
                                                <div

                                                    className="input-group"
                                                    style={{ height: "3rem" }}
                                                >
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            ฿
                                                        </span>
                                                    </div>
                                                    <input

                                                        id="withdraw_amount"
                                                        onChange={this.onChange}
                                                        name="withdrawamount"
                                                        value={this.state.withdrawamount}
                                                        type="number"
                                                        autoComplete="off"
                                                        placeholder="ระบุเฉพาะตัวเลข"
                                                        className="form-control form-control-lg money-withdraw ng-untouched ng-pristine ng-valid withdrawamount"

                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            onClick={(e) => this.setState({ withdrawamount: balance })}
                                                            type="button"
                                                            id="totalwithdraw"
                                                            className="btn btn-warning"
                                                        >
                                                            ทั้งหมด
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div  className="col-12 col-md-12">
                                                <label  className="mt-2">
                                                    <i  className="far fa-star" /> หมายเหตุ
                                                </label>
                                                <textarea
                                                    
                                                    name="note"
                                                    cols={30}
                                                    rows={2}
                                                    className="form-control ng-untouched ng-pristine ng-valid"
                                                    defaultValue={""}
                                                />
                                            </div> */}
                                            <div

                                                className="col-12 pt-2 text-note-withdraw"
                                                style={{ fontSize: "0.8rem" }}
                                            >
                                                <div >
                                                    - ลูกค้าต้องมียอดเดิมพัน 10% ของยอดฝาก
                                                    และไม่ติดยอดเดิมพันโปรโมชั่น จึงจะสามารถถอนเครดิตได้ทั้งหมด
                                                </div>
                                                <div >
                                                    - กรณีแทงหวย ระบบจะนับยอดเดิมพัน เฉพาะหวยที่ออกผลแล้วเท่านั้น
                                                </div>
                                                <div >- ยอดถอนขั้นต่ำ 300 บาท</div>
                                                <div

                                                    className="text-danger underline ng-star-inserted"
                                                    style={{ fontWeight: 600 }}
                                                >
                                                    - จำนวนการถอนต่อวัน 3 ครั้ง (คงเหลือ 3 ครั้ง)
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div

                                    className="bg-white p-2 rounded shadow-sm w-100 mb-5"
                                >
                                    <div className="row">
                                        <div className="col pr-1">
                                            <button

                                                className="btn btn-secondary btn-block"
                                            >
                                                ยกเลิก
                                            </button>
                                        </div>
                                        <div className="col pl-1">
                                            <button
                                                onClick={(e) => this.submitwithdraw(e)}
                                                type="submit"
                                                className="btn btn-success btn-block btn-withdraw"
                                            >
                                                ถอนเงิน
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-enp-c12=""
                            className="ng-tns-c12-7"
                        >

                        </ngx-spinner>
                    </div>
                    <div id="lotto" hidden={!(this.state.loginstate && tabselect == 17)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div

                                className="bar-back d-flex justify-content-between align-items-center bg-main-custom"
                            >
                                <a

                                    onClick={(e) => {
                                        this.checkurl(e, "/")
                                    }} role="button"


                                    className="color-back-custom"
                                >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="py-2 px-1 w-100 main-content align-self-stretch lottery-render bg-main-custom"
                                style={{ backgroundColor: "#fff" }}
                            >

                                <div className="col-12 m-2 pl-0 pr-3">
                                    <div

                                        id="data-target-input"
                                        className="input-group date d-flex align-items-stretch"
                                    >
                                        <input
                                            onChange={this.handleSearch}
                                            type="text"
                                            name="dreamtext"
                                            id="dreamtext"
                                            placeholder="ค้นหารายชื่อสินค้า"
                                            className="form-control mb-0 input-search-lotto ng-untouched ng-pristine ng-valid"
                                            style={{
                                                backgroundColor: "#ffffff",
                                                color: "black",
                                                border: "1px solid #ced4da"
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button

                                                type="button"
                                                className="btn btn-outline-secondary"
                                                style={{
                                                    background: "white",
                                                    border: "1px solid #ced4da",
                                                    height: "100%",
                                                    color: "#6c757d"
                                                }}
                                            >
                                                <i className="fas fa-search" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div id="huayYeekee-section"
                                    className="p-2 xtarget ng-star-inserted"
                                >
                                    {Object.keys(dataToDisplay).map((type) => (
                                        <>
                                            <div className="section-title">{type}</div>
                                            <div className="row px-0 px-md-1 m-0">
                                                {dataToDisplay[type].map((item) => (


                                                    <div
                                                        key={item.roomid}
                                                        className={`col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1 ng-star-inserted ${item.status === 0 ? "lotto-close" : ""
                                                            }`}>

                                                        <a role="button" onClick={(e) => {
                                                            if (item.status !== 0) {
                                                                {
                                                                    type === "หวยยี่กี" ? (
                                                                        this.checkurl(e, "/yeekee5")

                                                                    ) : (
                                                                        this.redeemlotto(e, item._id, item.roomid)
                                                                    )
                                                                }
                                                            }
                                                        }}
                                                            className={item.status === 0 ? "disabled" : ""}>
                                                            <div className="d-flex bg-lottery-product">
                                                                <div
                                                                    className="col-4 p-0 flag-bg-product"
                                                                    style={{ background: `url("${item.image}")` }}
                                                                />
                                                                <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                    <div className="d-flex m-0 py-1">
                                                                        <div className="col-3 p-0">
                                                                            <div className="bg-flag-lottery-product huay-card-yeekee5">
                                                                                <img
                                                                                    className="flag-on-lottery-product"
                                                                                    src={item.flagimage}
                                                                                    alt={item.name}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-9 px-1">
                                                                            <div className="px-2 py-0 py-md-2 bg-product-name-lottery-yk5">
                                                                                <div className="huay-card-name-lotto">{item.name}</div>
                                                                                <div className="huay-card-period-lotto">
                                                                                    {new Date(item.drawTime).toLocaleDateString('th-TH', {
                                                                                        day: '2-digit',
                                                                                        month: '2-digit',
                                                                                        year: '2-digit',
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row m-0 py-1">
                                                                        <div className="lotto-time txt-24 time-government ng-star-inserted">
                                                                            {item.status === 0 ? (
                                                                                <div className="lotto-time txt-24 time-government">
                                                                                    <i className="fas fa-ban pr-1"></i>
                                                                                    <span>ยังไม่รับแทง</span>
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    {type === "หวยยี่กี" ? (
                                                                                        <>
                                                                                            <div className="txt-24-yk">
                                                                                                <i className="far fa-check-circle pr-1"></i>
                                                                                                <span id="offset">24 ชม.</span>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <i className="far fa-clock pr-1"></i>
                                                                                            <span id="countdown"> {this.calculateCountdown(item.drawTime, "full")}
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-row justify-content-between m-0 py-1 mb-1 bg-rule-product">
                                                                        <button
                                                                            className="btn btn-rate p-0 mx-1"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation(); // หยุดเหตุการณ์ที่ส่งต่อไปยัง <a>
                                                                                this.handleShowModal(item);
                                                                            }}
                                                                        >
                                                                            อัตราจ่าย
                                                                        </button>
                                                                        <button className="btn btn-rule p-0 mx-1" onClick={(e) => {
                                                                            e.stopPropagation(); // หยุดเหตุการณ์ที่ส่งต่อไปยัง <a>
                                                                            this.handleShowModalRule(item);
                                                                        }} > กติกา </button>
                                                                        <button className="btn btn-close-time p-0 mx-1 row">

                                                                            <>
                                                                                {type === "หวยยี่กี" ? (
                                                                                    <>
                                                                                        <button className="btn btn-close-time p-0 mx-1 ng-star-inserted" >
                                                                                            {" "}
                                                                                            เปิด 88 รอบ{" "}
                                                                                        </button>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <span className="close-period-text">
                                                                                            {" "}
                                                                                            ปิดรับ{" "}
                                                                                        </span>

                                                                                        <span>
                                                                                            {String(new Date(item.datetimeClose).getHours()).padStart(2, '0')}:
                                                                                            {String(new Date(item.datetimeClose).getMinutes()).padStart(2, '0')}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </>


                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div >


                                                ))}
                                            </div>
                                        </>
                                    ))}

                                </div>
                                {/* 
                                <div

                                    id="government-section"
                                    className="p-2 xtarget ng-star-inserted"
                                >
                                    <div className="section-title">
                                        {" "}
                                        กลุ่มหวยไทย{" "}
                                    </div>
                                    <div className="row px-0 px-md-1 m-0">
                                        <div

                                            className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1 ng-star-inserted"
                                        >
                                            <a href="javascript:void(0);">
                                                <div className="d-flex bg-lottery-product">
                                                    <div

                                                        className="col-4 p-0 flag-bg-product"
                                                        style={{ background: 'url("/build/web/igame-index-lobby-wm/img/AOM.png")' }}
                                                    />
                                                    <div

                                                        className="col-8 px-1 px-sm-1 px-md-1 py-1"
                                                    >
                                                        <div className="d-flex m-0 py-1">
                                                            <div className="col-3 p-0">
                                                                <div

                                                                    className="bg-flag-lottery-product huay-card huay-card-aom"
                                                                >
                                                                    <img

                                                                        className="flag-on-lottery-product"
                                                                        src="/build/web/igame-index-lobby-wm/img/AOM.png"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-9 px-1">
                                                                <div

                                                                    className="huay-card-product huay-card-product-aom pb-md-2 pt-md-1 px-2 py-0"
                                                                >
                                                                    <div

                                                                        className="huay-card-name-lotto"
                                                                    >
                                                                        หวยออมสิน
                                                                    </div>
                                                                    <div

                                                                        className="huay-card-period-lotto"
                                                                    >
                                                                        01/11/67
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="d-flex flex-column m-0 py-1"
                                                        >
                                                            <div className="p-0">
                                                                <div className="d-flex flex-row">
                                                                    <div

                                                                        className="lotto-time txt-24 time-government ng-star-inserted"
                                                                    >
                                                                        <i

                                                                            className="far fa-clock pr-1"
                                                                        />
                                                                        <span className="countdown">
                                                                            2 วัน 23:13:28
                                                                        </span>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="d-flex flex-row justify-content-between m-0 py-1 mb-1 bg-rule-product"
                                                        >
                                                            <button

                                                                className="btn btn-rate p-0 mx-1"
                                                            >
                                                                {" "}
                                                                อัตราจ่าย{" "}
                                                            </button>
                                                            <button

                                                                className="btn btn-rule p-0 mx-1"
                                                            >
                                                                {" "}
                                                                กติกา{" "}
                                                            </button>
                                                            <button

                                                                className="btn btn-close-time p-0 mx-1 row"
                                                            >
                                                                <span className="close-period-text">
                                                                    {" "}
                                                                    ปิดรับ{" "}
                                                                </span>
                                                                <span > 10:00 </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div

                                            className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1 ng-star-inserted"
                                        >
                                            <a href="javascript:void(0);">
                                                <div className="d-flex bg-lottery-product">
                                                    <div

                                                        className="col-4 p-0 flag-bg-product"
                                                        style={{ background: 'url("/build/web/igame-index-lobby-wm/img/TH.png")' }}
                                                    />
                                                    <div

                                                        className="col-8 px-1 px-sm-1 px-md-1 py-1"
                                                    >
                                                        <div className="d-flex m-0 py-1">
                                                            <div className="col-3 p-0">
                                                                <div

                                                                    className="bg-flag-lottery-product huay-card huay-card-th"
                                                                >
                                                                    <img

                                                                        className="flag-on-lottery-product"
                                                                        src="/build/web/igame-index-lobby-wm/img/TH.png"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-9 px-1">
                                                                <div

                                                                    className="huay-card-product huay-card-product-th pb-md-2 pt-md-1 px-2 py-0"
                                                                >
                                                                    <div

                                                                        className="huay-card-name-lotto"
                                                                    >
                                                                        หวยรัฐบาลไทย
                                                                    </div>
                                                                    <div

                                                                        className="huay-card-period-lotto"
                                                                    >
                                                                        01/11/67
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="d-flex flex-column m-0 py-1"
                                                        >
                                                            <div className="p-0">
                                                                <div className="d-flex flex-row">
                                                                    <div

                                                                        className="lotto-time txt-24 time-government ng-star-inserted"
                                                                    >
                                                                        <i

                                                                            className="far fa-clock pr-1"
                                                                        />
                                                                        <span className="countdown">
                                                                            3 วัน 04:33:28
                                                                        </span>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="d-flex flex-row justify-content-between m-0 py-1 mb-1 bg-rule-product"
                                                        >
                                                            <button

                                                                className="btn btn-rate p-0 mx-1"
                                                            >
                                                                {" "}
                                                                อัตราจ่าย{" "}
                                                            </button>
                                                            <button

                                                                className="btn btn-rule p-0 mx-1"
                                                            >
                                                                {" "}
                                                                กติกา{" "}
                                                            </button>
                                                            <button

                                                                className="btn btn-close-time p-0 mx-1 row"
                                                            >
                                                                <span className="close-period-text">
                                                                    {" "}
                                                                    ปิดรับ{" "}
                                                                </span>
                                                                <span > 15:20 </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div

                                            className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1 lotto-close ng-star-inserted"
                                        >
                                            <a href="javascript:void(0);">
                                                <div className="d-flex bg-lottery-product">
                                                    <div

                                                        className="col-4 p-0 flag-bg-product"
                                                        style={{ background: 'url("/build/web/igame-index-lobby-wm/img/BAAC.png")' }}
                                                    />
                                                    <div

                                                        className="col-8 px-1 px-sm-1 px-md-1 py-1"
                                                    >
                                                        <div className="d-flex m-0 py-1">
                                                            <div className="col-3 p-0">
                                                                <div

                                                                    className="bg-flag-lottery-product huay-card huay-card-baac"
                                                                >
                                                                    <img

                                                                        className="flag-on-lottery-product"
                                                                        src="/build/web/igame-index-lobby-wm/img/BAAC.png"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-9 px-1">
                                                                <div

                                                                    className="huay-card-product huay-card-product-baac pb-md-2 pt-md-1 px-2 py-0"
                                                                >
                                                                    <div

                                                                        className="huay-card-name-lotto"
                                                                    >
                                                                        หวย ธ.ก.ส.
                                                                    </div>
                                                                    <div

                                                                        className="huay-card-period-lotto"
                                                                    >
                                                                        16/11/67
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="d-flex flex-column m-0 py-1"
                                                        >
                                                            <div className="p-0">
                                                                <div className="d-flex flex-row">

                                                                    <div

                                                                        className="lotto-time txt-24 time-government ng-star-inserted"
                                                                    >
                                                                        <i

                                                                            className="fas fa-ban pr-1"
                                                                        />
                                                                        <span >ยังไม่รับแทง</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="d-flex flex-row justify-content-between m-0 py-1 mb-1 bg-rule-product"
                                                        >
                                                            <button

                                                                className="btn btn-rate p-0 mx-1"
                                                            >
                                                                {" "}
                                                                อัตราจ่าย{" "}
                                                            </button>
                                                            <button

                                                                className="btn btn-rule p-0 mx-1"
                                                            >
                                                                {" "}
                                                                กติกา{" "}
                                                            </button>
                                                            <button

                                                                className="btn btn-close-time p-0 mx-1 row"
                                                            >
                                                                <span className="close-period-text">
                                                                    {" "}
                                                                    ปิดรับ{" "}
                                                                </span>
                                                                <span > 09:00 </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                </div> */}




                            </div>
                        </div>
                        <div

                            className="btn-group button-select-category"
                            style={{ opacity: 1, visibility: "visible", right: 10 }}
                        >
                            <button

                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className="btn dropdown-toggle"
                            >
                                <i className="fas fa-times" />
                                <i className="fas fa-search" />
                            </button>
                            <div

                                x-placement="top-start"
                                className="dropdown-menu"
                                style={{
                                    position: "absolute",
                                    transform: "translate3d(0px, -2px, 0px)",
                                    top: 0,
                                    left: 0,
                                    willChange: "transform"
                                }}
                            >
                                <a

                                    fragment="government-section"
                                    id="bt-government"
                                    className="dropdown-item"
                                    href="#/lottery#government-section"
                                >
                                    <i className="fas fa-crown" /> หวยไทย
                                </a>
                                <a

                                    fragment="foreign-section"
                                    id="bt-foreign"
                                    className="dropdown-item"
                                    href="#/lottery#foreign-section"
                                >
                                    <i className="far fa-flag-checkered" />{" "}
                                    หวยต่างประเทศ
                                </a>
                                <a

                                    fragment="huayOnline-section"
                                    id="bt-online"
                                    className="dropdown-item"
                                    href="#/lottery#huayOnline-section"
                                >
                                    <i className="fas fa-rss" /> หวยออนไลน์
                                </a>
                                <a

                                    fragment="foreignStock-section"
                                    id="bt-tock"
                                    className="dropdown-item"
                                    href="#/lottery#foreignStock-section"
                                >
                                    <i className="far fa-chart-line" /> หวยหุ้น
                                </a>
                                <a

                                    fragment="stockVip-section"
                                    id="bt-stockVip"
                                    className="dropdown-item"
                                    href="#/lottery#stockVip-section"
                                >
                                    <i className="fas fa-chart-bar" /> หวยหุ้นพิเศษ
                                </a>
                                <a

                                    fragment="huay4D-section"
                                    id="bt-4d"
                                    className="dropdown-item"
                                    hidden=""
                                    href="#/lottery#huay4D-section"
                                >
                                    <i className="fas fa-star" /> หวย 4D
                                </a>
                                <a

                                    fragment="huayYeekee-section"
                                    id="bt-yeekee"
                                    className="dropdown-item"
                                    href="#/lottery#huayYeekee-section"
                                >
                                    <i className="fas fa-trophy" /> หวยยี่กี
                                </a>
                                <a

                                    fragment="section-content"
                                    id="back-top"
                                    className="dropdown-item text-dark"
                                    href="#/lottery#section-content"
                                >
                                    <i className="fas fa-arrow-up" /> Back to top
                                </a>
                            </div>
                        </div>
                        <div

                            id="myModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModal"
                            aria-hidden="true"
                            className="modal fade"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-md"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">
                                            อัปเดตล่าสุด
                                        </h5>
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="text-white close"
                                        >
                                            <span aria-hidden="true">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">


                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "-1px 2px 5px black"
                                            }}
                                        >
                                            ปิด
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="price-modal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="price-modal"
                            aria-hidden="true"
                            className={`modal fade${this.state.showModalPrice ? ' show' : ''}`}
                            style={{
                                display: this.state.showModalPrice ? 'block' : 'none',
                                paddingRight: this.state.showModalPrice ? '10px' : '',
                                overflow: 'hidden', // เพื่อให้โมดอลไม่หลุดจากหน้าจอ
                            }}
                        >
                            <div
                                role="document"
                                className="modal-dialog modal-lg"
                                style={{
                                    maxHeight: '90vh', // จำกัดความสูงโมดอล
                                    overflowY: 'auto', // เพิ่ม scrollbar แนวตั้ง
                                }}
                            >
                                <div className="modal-content" style={{ borderRadius: 10 }}>
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">อัตราจ่าย</h5>
                                        <button
                                            onClick={this.handleCloseModal}
                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="text-white close"
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div
                                        className="modal-body"
                                        style={{
                                            overflowY: 'auto', // เพิ่ม scrollbar ในส่วนเนื้อหา
                                            maxHeight: '70vh', // จำกัดความสูงเนื้อหา
                                        }}
                                    >
                                        <div className="box__condition-info">
                                            <div className="row">
                                                {this.state.DynamicSetting.map((setting) => (
                                                    <div key={setting._id} className="col-md-12 mb-1 ng-star-inserted">
                                                        <h3>
                                                            {setting.key} จ่าย : {setting.value}
                                                        </h3>
                                                        <p>
                                                            แทงขั้นต่ำต่อครั้ง : {setting.minBet || 1}
                                                            <br />
                                                            แทงสูงสุดต่อครั้ง : {setting.maxBet || 0}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            onClick={this.handleCloseModal}
                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: '100%',
                                                boxShadow: '-1px 2px 5px black',
                                            }}
                                        >
                                            ปิด
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div

                            id="rule-modal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="rule-modal"
                            aria-hidden="true"
                            className={`modal fade${this.state.showModalRule ? ' show' : ''}`}
                            style={{
                                display: this.state.showModalRule ? 'block' : 'none',
                                paddingRight: this.state.showModalRule ? '10px' : '',
                            }}
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-lg"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">
                                            กติกา
                                        </h5>
                                        <button
                                            onClick={this.handleCloseModalRule}
                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="text-white close"
                                        >
                                            <span aria-hidden="true">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.rulehtml }} />
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            onClick={this.handleCloseModalRule}
                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "-1px 2px 5px black"
                                            }}
                                        >
                                            {" "}
                                            ฉันเข้าใจและยอมรับ{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-enp-c12=""
                            className="ng-tns-c12-11"
                        >

                        </ngx-spinner>
                    </div >
                    <div id="yeekee5" hidden={!(this.state.loginstate && tabselect == 33)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back bg-main-custom">
                                <a className="color-back-custom" >
                                    <i className="fas fa-chevron-left" /> แทงหวย
                                </a>
                            </div>
                            <div className="p-2 w-100 main-content align-self-stretch yeekee-render bg-main-custom">
                                <div className="col-12 rounded-top bg-dark text-light">

                                    <div className="d-flex align-items-center" style={{ height: 35, fontSize: "1.1rem" }}>
                                        สถิติย้อนหลัง
                                    </div>
                                    <div className="col-12 p-0">
                                        <div className="d-flex flex-row" style={{ backgroundColor: "white" }}>
                                            <div className="bg-theme-primary"
                                                style={{ width: "35px !important", height: 398 }}
                                            >
                                                <div className="d-flex flex-column">
                                                    <div style={{ height: 35 }} />
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            height: 180,
                                                            marginTop: 3,
                                                            borderTop: "white 1px solid",
                                                            writingMode: "vertical-rl",
                                                            transform: "scale(-1)"
                                                        }}
                                                    >
                                                        {" "}
                                                        2 บน{" "}
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            height: 178,
                                                            marginTop: 3,
                                                            borderTop: "white 1px solid",
                                                            writingMode: "vertical-rl",
                                                            transform: "scale(-1)"
                                                        }}
                                                    >
                                                        {" "}
                                                        2 ล่าง{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row px-0 mx-0" style={{ height: "400px", width: "calc(100% - 35px)" }}  >
                                                <div className="p-0 col-6"  >
                                                    <div className="bg-theme-primary text-light d-flex justify-content-center align-items-center"
                                                        style={{ height: 35, fontSize: "1rem", borderRight: "white 1px solid" }} >
                                                        <div className="circle bg-primary mx-3">
                                                            สูง
                                                        </div>
                                                        <div className="circle bg-danger mx-3">
                                                            ต่ำ
                                                        </div>
                                                    </div>
                                                    <div style={{ borderRight: "1px solid white" }}>
                                                        <div className="border tableDiv" style={{
                                                            marginBottom: "1px",
                                                            height: "180px",
                                                            overflowX: "scroll",
                                                            overflowY: "hidden",
                                                        }}>
                                                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                                <tbody>
                                                                    {updatedTableData && updatedTableData.map((row, rowIndex) => (
                                                                        <tr key={rowIndex} style={{ height: '27px' }}>
                                                                            {row.map((item, colIndex) => (
                                                                                <td
                                                                                    key={`${rowIndex}-${colIndex}`}
                                                                                    className="border-yeekee"
                                                                                    style={{ width: '27px', textAlign: 'center' }}
                                                                                >
                                                                                    <div
                                                                                        className={`circle bg-${item.color}`}
                                                                                        style={{
                                                                                            backgroundColor: item.color
                                                                                                ? item.color === 'primary'
                                                                                                    ? 'blue'
                                                                                                    : 'red'
                                                                                                : 'white', // Default to white if item.color is not provided
                                                                                            color: '#fff',
                                                                                            width: '24px',
                                                                                            height: '24px',
                                                                                            borderRadius: '50%',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                            margin: 'auto',
                                                                                        }}
                                                                                    >
                                                                                        {item.number}
                                                                                    </div>
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div style={{ borderRight: "1px solid white" }}>
                                                        <div className="border tableDiv" style={{
                                                            marginBottom: "1px",
                                                            height: "180px",
                                                            overflowX: "scroll",
                                                            overflowY: "hidden",
                                                        }}>
                                                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                                <tbody>
                                                                    {updatedTableData2 && updatedTableData2.map((row, rowIndex) => (
                                                                        <tr key={rowIndex} style={{ height: '27px' }}>
                                                                            {row.map((item, colIndex) => (
                                                                                <td
                                                                                    key={`${rowIndex}-${colIndex}`}
                                                                                    className="border-yeekee"
                                                                                    style={{ width: '27px', textAlign: 'center' }}
                                                                                >
                                                                                    <div
                                                                                        className={`circle bg-${item.color}`}
                                                                                        style={{
                                                                                            backgroundColor: item.color
                                                                                                ? item.color === 'primary'
                                                                                                    ? 'blue'
                                                                                                    : 'red'
                                                                                                : 'white', // Default to white if item.color is not provided
                                                                                            color: '#fff',
                                                                                            width: '24px',
                                                                                            height: '24px',
                                                                                            borderRadius: '50%',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                            margin: 'auto',
                                                                                        }}
                                                                                    >
                                                                                        {item.number}
                                                                                    </div>
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="p-0 col-6"  >
                                                    <div className="bg-theme-primary d-flex justify-content-center align-items-center"
                                                        style={{ height: 35, fontSize: "1.1rem", borderLeft: "white 1px solid" }}
                                                    >
                                                        <div className="circle bg-success mx-3">
                                                            คู่
                                                        </div>
                                                        <div className="circle bg-warning mx-3">
                                                            คี่
                                                        </div>
                                                    </div>
                                                    <div style={{ borderRight: "1px solid white" }}>
                                                        <div className="border tableDiv" style={{
                                                            marginBottom: "1px",
                                                            height: "180px",
                                                            overflowX: "scroll",
                                                            overflowY: "hidden",
                                                        }}>
                                                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                                <tbody>
                                                                    {updatedTableData3 && updatedTableData3.map((row, rowIndex) => (
                                                                        <tr key={rowIndex} style={{ height: '27px' }}>
                                                                            {row.map((item, colIndex) => (
                                                                                <td
                                                                                    key={`${rowIndex}-${colIndex}`}
                                                                                    className="border-yeekee"
                                                                                    style={{ width: '27px', textAlign: 'center' }}
                                                                                >
                                                                                    <div
                                                                                        className={`circle bg-${item.color}`}
                                                                                        style={{
                                                                                            backgroundColor: item.color
                                                                                                ? item.color === 'primary'
                                                                                                    ? 'blue'
                                                                                                    : 'red'
                                                                                                : 'white', // Default to white if item.color is not provided
                                                                                            color: '#fff',
                                                                                            width: '24px',
                                                                                            height: '24px',
                                                                                            borderRadius: '50%',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                            margin: 'auto',
                                                                                        }}
                                                                                    >
                                                                                        {item.number}
                                                                                    </div>
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div style={{ borderRight: "1px solid white" }}>
                                                        <div className="border tableDiv" style={{
                                                            marginBottom: "1px",
                                                            height: "180px",
                                                            overflowX: "scroll",
                                                            overflowY: "hidden",
                                                        }}>
                                                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                                <tbody>
                                                                    {updatedTableData4 && updatedTableData4.map((row, rowIndex) => (
                                                                        <tr key={rowIndex} style={{ height: '27px' }}>
                                                                            {row.map((item, colIndex) => (
                                                                                <td
                                                                                    key={`${rowIndex}-${colIndex}`}
                                                                                    className="border-yeekee"
                                                                                    style={{ width: '27px', textAlign: 'center' }}
                                                                                >
                                                                                    <div
                                                                                        className={`circle bg-${item.color}`}
                                                                                        style={{
                                                                                            backgroundColor: item.color
                                                                                                ? item.color === 'primary'
                                                                                                    ? 'warning'
                                                                                                    : 'success'
                                                                                                : 'white', // Default to white if item.color is not provided
                                                                                            color: '#fff',
                                                                                            width: '24px',
                                                                                            height: '24px',
                                                                                            borderRadius: '50%',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                            margin: 'auto',
                                                                                        }}
                                                                                    >
                                                                                        {item.number}
                                                                                    </div>
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div >
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                    <div id="yeekeenm" hidden={!(this.state.loginstate && tabselect == 34)} className="ng-star-inserted">
                    </div >
                    <div id="Poy" hidden={!(this.state.loginstate && tabselect == 35)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, `/`)
                                }} role="button">
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div
                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                style={{ minHeight: "calc(-190px + 100vh)" }}
                            >
                                <div className="border border-dark bg-white shadow-sm rounded mb-2">
                                    <div className="bg-dark text-white py-1 px-2">
                                        <i className="sn-icon sn-icon--horoscope2" /> ยอดแทงวันนี้{" "}
                                    </div>
                                    <div className="bg-light py-1 px-2">
                                        <div className="row px-3">
                                            <div className="col-6 col-sm-6 pl-0 pr-1">
                                                <div className="mr-1 mt-1 py-1 text-center w-100 rounded bg-primary text-white">
                                                    <small>ยอดรวม</small>
                                                    <h5 className="font-weight-normal thb">฿ {this.state.totalAmount || 0}</h5>
                                                </div>
                                            </div>
                                            <div className="col-3 col-sm-3 px-0">
                                                <div className="m-1 py-1 text-center border-left w-100">
                                                    <small>ออกผลแล้ว</small>
                                                    <h5 className="font-weight-light text-success">{this.state.completedCount || 0}</h5>
                                                </div>
                                            </div>
                                            <div className="col-3 col-sm-3 px-0">
                                                <div className="m-1 py-1 text-center border-left w-100">
                                                    <small>ยังไม่ออกผล</small>
                                                    <h5 className="font-weight-light text-primary">{this.state.pendingCount || 0}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border border-secondary bg-white shadow-sm rounded mb-5">
                                    <div className="row m-0 poy-all">
                                        {/* Dropdown for Filter */}
                                        <div className="col-12 col-sm-12 col-md-4 p-0 bg-dark">
                                            <div
                                                className={`dropdown bootstrap-select form-control ${isOpen ? "show dropup" : "dropup"}`}
                                                ref={(ref) => (this.dropdownRef = ref)}
                                            >
                                                <button
                                                    className="btn dropdown-toggle bs-placeholder btn-dark"
                                                    onClick={this.toggleDropdown}
                                                >
                                                    <div className="filter-option">
                                                        <div className="filter-option-inner">{selectedText}</div>
                                                    </div>
                                                    &nbsp;<span className="bs-caret">
                                                        <span className="caret"></span>
                                                    </span>
                                                </button>
                                                <div
                                                    className={`dropdown-menu ${isOpen ? "show" : ""}`}
                                                    style={{
                                                        maxHeight: "233.5px",
                                                        overflow: "hidden",
                                                        minHeight: 116,
                                                        position: "absolute",
                                                        willChange: "transform",
                                                        top: 0,
                                                        left: 0,
                                                        transform: "translate3d(0px, 38px, 0px)",
                                                    }}
                                                    x-placement="bottom-start"
                                                >
                                                    <ul className={`inner ${isOpen ? "show" : ""}`}>
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                onClick={() => this.handleSelection("หวยทั้งหมด", 1)}
                                                            >
                                                                รายการวันนี้
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                onClick={() => this.handleSelection("ย้อนหลังทั้งหมด", 2)}
                                                            >
                                                                ทั้งหมด
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Tab Menu */}
                                        <div className="col-3 col-sm-3 col-md-2 p-0">
                                            <button
                                                onClick={() => this.handleTabChange("poy-today")}
                                                className={`btn btn-primary btn-sm btn-block h-100 btn-poy ${activeTab === "poy-today" ? "active" : ""
                                                    }`}
                                            >
                                                <i className="fas fa-calendar-week d-none" />
                                                <div>
                                                    <span>โพย</span>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-3 col-sm-3 col-md-2 p-0">
                                            <button
                                                onClick={() => this.handleTabChange("poy-notyet")}
                                                className={`btn btn-warning btn-sm btn-block h-100 btn-poy ${activeTab === "poy-notyet" ? "active" : ""
                                                    }`}
                                            >
                                                <i className="fas fa-times-circle d-none" />
                                                <div>
                                                    <span>โพยหวย</span>
                                                    <span>ที่ยังไม่ออกผล</span>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-3 col-sm-3 col-md-2 p-0">
                                            <button
                                                onClick={() => this.handleTabChange("poy-success")}
                                                className={`btn btn-success btn-sm btn-block h-100 btn-poy ${activeTab === "poy-success" ? "active" : ""
                                                    }`}
                                            >
                                                <i className="fas fa-check-circle d-none" />
                                                <div>
                                                    <span>โพยหวย</span>
                                                    <span>ออกผลแล้ว</span>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-3 col-sm-3 col-md-2 p-0">
                                            <button
                                                onClick={() => this.handleTabChange("poy-history")}
                                                className={`btn btn-secondary btn-sm btn-block h-100 btn-poy ${activeTab === "poy-history" ? "active" : ""
                                                    }`}
                                            >
                                                <i className="fas fa-history d-none" />
                                                <div>
                                                    <span>โพย</span>
                                                    <span>ก่อนหน้า</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    {/* Content for "poy-today" */}
                                    <div
                                        className={`col-12 p-0 table-primary poy-content ${activeTab === "poy-today" ? "active" : "d-none"
                                            }`}
                                    >
                                        {/* Render poyLists for "poy-today" */}
                                        {poyLists.length > 0 ? (
                                            poyLists.map((poy, index) => (
                                                <div className="poy-list">
                                                    <div className="poy-list-head">
                                                        <small>โพยเลขที่</small>
                                                        <span> #{poy.track}</span>
                                                        <div
                                                            className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                                poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                                }`}
                                                        >
                                                            {poy.rewardStatus}
                                                        </div>
                                                    </div>
                                                    <div className="poy-list-content">
                                                        <div className="row">
                                                            <div className="col m-0 pl-2 pr-1 pb-1">
                                                                <div className="poy-type">
                                                                    <span>{poy.roomDetails.name}</span>
                                                                    <br />
                                                                    <span>{poy.roomDetails.type}</span>
                                                                    <br />
                                                                    <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                                </div>
                                                            </div>
                                                            <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                                <div className="d-flex justify-content-between">
                                                                    <small>เงินเดิมพัน</small>
                                                                    <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <small>ผลแพ้/ชนะ</small>
                                                                    <span>
                                                                        <span className="thb">฿ {poy.winAmount || 0}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 border-top m-0 pt-1">
                                                                <span className="badge">
                                                                    <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <span className="badge">
                                                                    <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: false,
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    role="button"
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, `/poydetail?track=${poy.track}`)
                                                                    }}
                                                                    className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                                >
                                                                    รายละเอียด <i className="fas fa-search" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                <span className="text-danger">ไม่มีรายการ</span>
                                            </div>
                                        )}
                                    </div>

                                    {/* Content for "poy-notyet" */}
                                    <div
                                        className={`col-12 p-0 table-danger poy-content ${activeTab === "poy-notyet" ? "active" : "d-none"
                                            }`}
                                    >
                                        {poyLists.length > 0 ? (
                                            poyLists.map((poy, index) => (
                                                <div className="poy-list">
                                                    <div className="poy-list-head">
                                                        <small>โพยเลขที่</small>
                                                        <span> #{poy.track}</span>
                                                        <div
                                                            className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                                poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                                }`}
                                                        >
                                                            {poy.rewardStatus}
                                                        </div>
                                                    </div>
                                                    <div className="poy-list-content">
                                                        <div className="row">
                                                            <div className="col m-0 pl-2 pr-1 pb-1">
                                                                <div className="poy-type">
                                                                    <span>{poy.roomDetails.name}</span>
                                                                    <br />
                                                                    <span>{poy.roomDetails.type}</span>
                                                                    <br />
                                                                    <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                                </div>
                                                            </div>
                                                            <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                                <div className="d-flex justify-content-between">
                                                                    <small>เงินเดิมพัน</small>
                                                                    <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <small>ผลแพ้/ชนะ</small>
                                                                    <span>
                                                                        <span className="thb">฿ {poy.winAmount || 0}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 border-top m-0 pt-1">
                                                                <span className="badge">
                                                                    <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <span className="badge">
                                                                    <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: false,
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    role="button"
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, `/poydetail?track=${poy.track}`)
                                                                    }}
                                                                    className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                                >
                                                                    รายละเอียด <i className="fas fa-search" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                <span className="text-danger">ไม่มีรายการ</span>
                                            </div>
                                        )}
                                    </div>

                                    {/* Content for "poy-success" */}
                                    <div
                                        className={`col-12 p-0 table-success poy-content ${activeTab === "poy-success" ? "active" : "d-none"
                                            }`}
                                    >
                                        {poyLists.length > 0 ? (
                                            poyLists.map((poy, index) => (
                                                <div className="poy-list">
                                                    <div className="poy-list-head">
                                                        <small>โพยเลขที่</small>
                                                        <span> #{poy.track}</span>
                                                        <div
                                                            className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                                poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                                }`}
                                                        >
                                                            {poy.rewardStatus}
                                                        </div>
                                                    </div>
                                                    <div className="poy-list-content">
                                                        <div className="row">
                                                            <div className="col m-0 pl-2 pr-1 pb-1">
                                                                <div className="poy-type">
                                                                    <span>{poy.roomDetails.name}</span>
                                                                    <br />
                                                                    <span>{poy.roomDetails.type}</span>
                                                                    <br />
                                                                    <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                                </div>
                                                            </div>
                                                            <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                                <div className="d-flex justify-content-between">
                                                                    <small>เงินเดิมพัน</small>
                                                                    <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <small>ผลแพ้/ชนะ</small>
                                                                    <span>
                                                                        <span className="thb">฿ {poy.winAmount || 0}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 border-top m-0 pt-1">
                                                                <span className="badge">
                                                                    <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <span className="badge">
                                                                    <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: false,
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <a
                                                                    role="button"
                                                                    href="javascript:void(0);"
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, `/poydetail?track=${poy.track}`)
                                                                    }}
                                                                    className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                                >
                                                                    รายละเอียด <i className="fas fa-search" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                <span className="text-danger">ไม่มีรายการ</span>
                                            </div>
                                        )}
                                    </div>

                                    {/* Content for "poy-history" */}
                                    <div className={`col-12 p-0 table-secondary poy-content ${activeTab === "poy-history" ? "active" : "d-none"}`} >
                                        {poyLists.length > 0 ? (
                                            poyLists.map((poy, index) => (
                                                <div className="poy-list">
                                                    <div className="poy-list-head">
                                                        <small>โพยเลขที่</small>
                                                        <span> #{poy.track}</span>
                                                        <div
                                                            className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                                poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                                }`}
                                                        >
                                                            {poy.rewardStatus}
                                                        </div>
                                                    </div>
                                                    <div className="poy-list-content">
                                                        <div className="row">
                                                            <div className="col m-0 pl-2 pr-1 pb-1">
                                                                <div className="poy-type">
                                                                    <span>{poy.roomDetails.name}</span>
                                                                    <br />
                                                                    <span>{poy.roomDetails.type}</span>
                                                                    <br />
                                                                    <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                                </div>
                                                            </div>
                                                            <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                                <div className="d-flex justify-content-between">
                                                                    <small>เงินเดิมพัน</small>
                                                                    <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <small>ผลแพ้/ชนะ</small>
                                                                    <span>
                                                                        <span className="thb">฿ {poy.winAmount || 0}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 border-top m-0 pt-1">
                                                                <span className="badge">
                                                                    <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <span className="badge">
                                                                    <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: false,
                                                                    }).format(new Date(poy.date))}
                                                                </span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, `/poydetail?track=${poy.track}`)
                                                                    }}
                                                                    role="button"
                                                                    className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                                >
                                                                    รายละเอียด <i className="fas fa-search" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-center">
                                                <span className="text-danger">ไม่มีรายการ</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div >
                    <div id="PoyDetail" hidden={!(this.state.loginstate && tabselect == 36)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a
                                    onClick={(e) => {
                                        this.checkurl(e, `/poy`)
                                    }}
                                    role="button"
                                >
                                    <i className="fas fa-chevron-left" /> โพยหวย
                                </a>
                            </div>
                            <div
                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                style={{ minHeight: "calc(-190px + 100vh)" }}
                            >
                                <div className="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0">
                                    <div className="lotto-title d-flex flex-row align-items-end">
                                        <h4 className="mr-1">
                                            <i className="fas fa-receipt" /> รายละเอียดโพย
                                        </h4>
                                    </div>
                                    <a href="#" className="btn btn-outline-danger btn-sm abort_poy">
                                        คืนโพย
                                    </a>
                                </div>

                                {/* Header ของโพย */}
                                <div
                                    className="border border-dark bg-white shadow-sm rounded mb-2"
                                    style={{ overflow: "hidden" }}
                                >
                                    <div className="bg-secondary text-white py-1 px-2 text-center border-bottom border-dark">
                                        <i className="fas fa-sticky-note" /> โพยเลขที่ #{buyListDetail?.track}
                                    </div>
                                    <div className="bg-light py-1 px-2">
                                        <div className="row m-0 p-0">
                                            <div className="col-12 col-sm-12 col-md-6 py-1 border-bottom d-flex flex-row justify-content-between align-items-center">
                                                <span className="mr-1">{buyListDetail?.roomDetails.name}</span>
                                                <span className="badge font-weight-light">
                                                    รอบ/วันที่: {moment(buyListDetail?.roomDetails.drawTime).format("DD/MM/YYYY HH:mm")}
                                                </span>
                                            </div>
                                            <div className="col px-0">
                                                <div className="pl-1 pr-0 text-center w-100">
                                                    <small>ยอดแทง</small>
                                                    <h5 className="font-weight-light text-primary thb">
                                                        ฿ {buyListDetail?.sumtotal}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col px-0">
                                                <div className="pr-1 pl-0 text-center border-left w-100">
                                                    <small>ผลได้เสีย</small>
                                                    <h5 className="font-weight-light ">฿ {buyListDetail?.winAmount}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Loop รายการในโพย */}
                                <div className="mb-5">
                                    {buyListDetail?.numbers.map((numberSet, index) => (
                                        <div className="poy-list" key={index}>
                                            <div className="poy-list-head">
                                                <small>ลำดับ</small> <span>{index + 1}</span>
                                                <div
                                                    className={`poy-status ${numberSet?.isWinning ? "win" : "lost"}`}
                                                >
                                                    {numberSet.isWinning ? "ถูกรางวัล" : "ไม่ถูกรางวัล"}
                                                </div>
                                            </div>
                                            <div className="poy-list-content pb-0">
                                                <div className="row">
                                                    <div className="col m-0 pl-2 pr-1 pb-1">
                                                        <div className="poy-type text-center">
                                                            <h3 className="text-primary">{numberSet?.number}</h3>
                                                            <small>{numberSet?.dynamicDetails?.key || "-"}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                        <div className="d-flex justify-content-between border-bottom">
                                                            <small>เลขที่ออก</small>
                                                            <span className="number-win">{numberSet?.winningKey || "-"}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <small>ราคาจ่าย</small>
                                                            <span>
                                                                <span className="thb">฿ {numberSet?.payout || "-"}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 bg-light border-top m-0 pt-1 d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <small className="mr-2">ราคาแทง:</small>{" "}
                                                            <span className="thb">฿ {numberSet?.price}</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <small className="mr-2">ผลได้เสีย:</small>
                                                            <h5 className="thb ">
                                                                ฿ {numberSet?.isWinning ? numberSet?.winningAmount : "0"}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div >
                    <div id="numbersets" hidden={!(this.state.loginstate && tabselect == 37)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, `/`)
                                }} >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div
                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                style={{ minHeight: "calc(-190px + 100vh)" }}
                            >
                                <div className=" text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0">
                                    <div className="lotto-title">
                                        <h4>
                                            <i className="fas fa-list-ol" /> จัดการเลขชุด
                                        </h4>
                                    </div>
                                    {/* end title -*/}
                                    <a
                                        onClick={() =>
                                            Swal.fire({
                                                title: 'แจ้งเตือน',
                                                text: 'ต้องเข้าไปเพิ่มที่ห้องหวย',
                                                icon: 'warning',
                                                confirmButtonText: 'ตกลง',
                                            })
                                        }
                                        // href="#/numbersets-add"
                                        // routerlink="/numbersets-add"
                                        className="btn btn-success btn-sm"
                                    >
                                        <i className="fas fa-plus" /> สร้างเลขชุด
                                    </a>
                                </div>
                                <div className=" text-secondary shadow-sm rounded py-2 xtarget col-lotto">
                                    <div
                                        id="numbersets_wrapper"
                                        className="dataTables_wrapper form-inline no-footer"
                                    >
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div className="dataTables_length" id="numbersets_length">
                                                    <label>
                                                        <select
                                                            name="numbersets_length"
                                                            className="custom-select custom-select-sm form-control form-control-sm"
                                                            value={itemsPerPage}
                                                            onChange={this.handleItemsPerPageChange}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                        <span>
                                                            แสดง
                                                            <br />
                                                            รายการ
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6">
                                                <div id="numbersets_filter" className="dataTables_filter">
                                                    <label>
                                                        <input
                                                            type="search"
                                                            className="form-control form-control-sm"
                                                            placeholder="ค้นหา"
                                                            value={searchQuery}
                                                            onChange={this.handleSearch}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <table className="datatable table table-hover table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th>ชื่อเลขชุด</th>
                                                            <th className="text-center">วัน-เวลา ที่สร้าง</th>
                                                            <th className="text-center">จัดการ</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredSets.slice(0, itemsPerPage).map((set) => (
                                                            <tr key={set._id}>
                                                                <td>{set.poyname}</td>
                                                                <td className="text-center"> {new Intl.DateTimeFormat("th-TH", {
                                                                    day: "2-digit",
                                                                    month: "2-digit",
                                                                    year: "numeric",
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                    hour12: false,
                                                                }).format(new Date(set.createdAt))}</td> {/* แปลงวันที่ให้อ่านง่าย */}
                                                                <td className="text-center">
                                                                    <div
                                                                        className={`btn-group dropleft ${this.state.activeDropdown === set._id
                                                                            ? "show"
                                                                            : ""
                                                                            }`}
                                                                    >
                                                                        <button
                                                                            className="btn btn-outline-secondary btn-sm dropdown-toggle"
                                                                            type="button"
                                                                            onClick={() => this.toggleDropdownxx(set._id)}
                                                                        >
                                                                            <i className="fas fa-cog"></i>
                                                                        </button>
                                                                        <div
                                                                            className={`dropdown-menu ${this.state.activeDropdown === set._id
                                                                                ? "show"
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            <a
                                                                                role="button"
                                                                                className="dropdown-item"
                                                                                onClick={(e) => this.checkurl(e, `/numbersetdetail?poyid=${set._id}`)}
                                                                            >
                                                                                รายละเอียด
                                                                            </a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <button
                                                                                className="dropdown-item"
                                                                                onClick={() => this.deleteNumberSet(set._id)}
                                                                            >
                                                                                <i className="fas fa-trash-alt"></i> ลบ
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-5">
                                                <div
                                                    className="dataTables_info"
                                                    id="numbersets_info"
                                                    role="status"
                                                    aria-live="polite"
                                                >
                                                    <p style={{ lineHeight: 1, display: "inline-block" }}>
                                                        <br />
                                                        <i className="fas fa-table"></i> {filteredSets.length} รายการ
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-7">
                                                <div
                                                    className="dataTables_paginate paging_bs_normal navigation"
                                                    id="numbersets_paginate"
                                                >
                                                    <ul className="pagination pagination-sm">
                                                        <li className="page-item prev disabled">
                                                            <a href="#" className="page-link">
                                                                <span className="glyphicon glyphicon-chevron-left" />
                                                                &nbsp;
                                                                <i className="fas fa-angle-left" />
                                                            </a>
                                                        </li>
                                                        <li className="page-item active">
                                                            <a href="#" className="page-link">
                                                                1
                                                            </a>
                                                        </li>
                                                        <li className="page-item next disabled">
                                                            <a href="#" className="page-link">
                                                                <i className="fas fa-angle-right" />
                                                                &nbsp;
                                                                <span className="glyphicon glyphicon-chevron-right" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div id="affiliate" hidden={!(this.state.loginstate && tabselect == 40)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, `/`)
                                }} >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch"
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-1 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div

                                        className="lotto-title d-flex flex-row justify-content-between align-items-center w-100"
                                    >
                                        <h4 className="mr-1">
                                            <i className="fas fa-thumbs-up" />
                                            &nbsp;<b  >แนะนำเพื่อน</b>
                                        </h4>
                                    </div>
                                </div>
                                <div>
                                    {/* Navigation */}
                                    <div className="row px-0 mb-1 mx-0">
                                        <div className="col-3 p-1 pb-0">
                                            <a
                                                onClick={() => this.setActiveTab(1)}
                                                role="button"
                                                className={`btn-af btn btn-theme-outline-primary btn-block d-flex flex-column ${activeTab === 1 ? "active" : ""}`}
                                            >
                                                <i className="far fa-handshake" /> ภาพรวม
                                            </a>
                                        </div>
                                        <div className="col-3 p-1 pb-0">
                                            <a
                                                onClick={() => this.setActiveTab(2)}
                                                role="button"
                                                className={`btn-af btn btn-theme-outline-primary btn-block d-flex flex-column ${activeTab === 2 ? "active" : ""}`}
                                            >
                                                <i className="fas fa-chalkboard-teacher" /> สมาชิก
                                            </a>
                                        </div>
                                        <div className="col-3 p-1 pb-0">
                                            <a
                                                onClick={() => this.setActiveTab(3)}
                                                role="button"
                                                className={`btn-af btn btn-theme-outline-primary btn-block d-flex flex-column ${activeTab === 3 ? "active" : ""}`}
                                            >
                                                <i className="fas fa-money-check-alt" /> รายได้
                                            </a>
                                        </div>
                                        <div className="col-3 p-1 pb-0">
                                            <a
                                                onClick={() => this.setActiveTab(4)}
                                                role="button"
                                                className={`btn-af btn btn-theme-outline-primary btn-block d-flex flex-column ${activeTab === 4 ? "active" : ""}`}
                                            >
                                                <i className="fas fa-hand-holding-usd" /> ถอนรายได้
                                            </a>
                                        </div>
                                    </div>

                                    {/* Content */}
                                    <div id="tabContent">
                                        {activeTab === 1 && (
                                            <div className="tab-content">
                                                <div className="w-100 my-2 border-bottom" />
                                                <div

                                                    className="border border-secondary shadow-sm rounded p-0 mb-1 mx-1"
                                                >
                                                    <a

                                                        href="#/aff-tool"
                                                        routerlink="/aff-tool"
                                                        className="image-btn-effect2"
                                                    >
                                                        <img

                                                            style={{ width: "-webkit-fill-available", height: "auto" }}
                                                            src="https://www.taokaelotto.asia/assets/images/banner-promote-01.png"
                                                        />
                                                    </a>
                                                </div>
                                                <div

                                                    className="table-secondary border border-secondary shadow-sm rounded p-0 mb-1 mx-1"
                                                >
                                                    <div className="row px-0 mx-0">
                                                        <div className="col-12 col-sm-12 col-md-2 p-1">
                                                            <div

                                                                className="p-1 text-center rounded bg-secondary text-white h-100 d-flex flex-row flex-sm-row flex-md-column justify-content-center align-items-center"
                                                            >
                                                                <small className="mr-1">
                                                                    ส่วนแบ่งรายได้สูงสุด:
                                                                </small>
                                                                <span className="text-warning">
                                                                    0%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-sm-6 col-md-2 p-1">
                                                            <div

                                                                className="py-1 text-center rounded bg-white"
                                                            >
                                                                <small  >รายได้ทั้งหมด</small>
                                                                <h6

                                                                    className="text-primary thb mb-0"
                                                                    style={{ fontFamily: "inherit" }}
                                                                >
                                                                    {" "}
                                                                    ฿ {this.state?.reports && this.state?.reports.lastMonth
                                                                        ? this.state?.reports.lastMonth.totalAmount
                                                                        : 0}{" "}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-sm-6 col-md-2 p-1">
                                                            <div

                                                                className="py-1 text-center rounded bg-white"
                                                            >
                                                                <small  >รายได้ปัจจุบัน</small>
                                                                <h6

                                                                    className="text-success thb mb-0"
                                                                    style={{ fontFamily: "inherit" }}
                                                                >
                                                                    {" "}
                                                                    ฿ 0.00{" "}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 col-sm-4 col-md-2 p-1">
                                                            <div

                                                                className="py-1 text-center rounded bg-white"
                                                            >
                                                                <small  >สมาชิกแนะนำ</small>
                                                                <h6

                                                                    className="text-info mb-0"
                                                                    style={{ fontFamily: "inherit" }}
                                                                >
                                                                    {" "}
                                                                    {this.state?.stats && this.state.stats?.totalSignups ? this.state.stats?.totalSignups : 0}{" "}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 col-sm-4 col-md-2 p-1">
                                                            <div

                                                                className="py-1 text-center rounded bg-white"
                                                            >
                                                                <small  >แทงทั้งหมด</small>
                                                                <h6

                                                                    className="text-info thb mb-0"
                                                                    style={{ fontFamily: "inherit" }}
                                                                >
                                                                    {" "}
                                                                    ฿ 0.00{" "}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 col-sm-4 col-md-2 p-1">
                                                            <div

                                                                className="py-1 text-center rounded bg-white"
                                                            >
                                                                <small  >คลิกทั้งหมด</small>
                                                                <h6

                                                                    className="text-info mb-0"
                                                                    style={{ fontFamily: "inherit" }}
                                                                >
                                                                    {" "}
                                                                    <b>{parseInt((this.state.stats?.lastMonthSignups || 0) * 3.5)} </b>{" "}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div

                                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 mb-1 xtarget"
                                                >
                                                    <h4  >
                                                        <i className="fas fa-share-alt-square" />{" "}
                                                        ลิงก์แนะนำ และแบนเนอร์
                                                    </h4>
                                                    <div className="card">
                                                        <h5 className="card-header">
                                                            ลิงก์สำหรับโปรโมท
                                                        </h5>
                                                        <div className="card-body">
                                                            <textarea

                                                                id="afurl"
                                                                cols={30}
                                                                rows={2}
                                                                readOnly=""
                                                                className="form-control text-center mb-1"
                                                                defaultValue={
                                                                    linkaff
                                                                }
                                                            />
                                                            <div className="row mt-2">
                                                                <div className="col-12">
                                                                    <button
                                                                        href={() => false} style={{ cursor: "pointer" }} onClick={() => this.copyCode(linkaff)}
                                                                        type="button"
                                                                        data-clipboard-target="#afurl"
                                                                        ngxclipboard=""
                                                                        className="btn btn-danger btn-block"
                                                                    >
                                                                        <i

                                                                            className="fas fa-copy mr-1"
                                                                            style={{ fontSize: 24 }}
                                                                        />
                                                                        <span style={{ fontSize: 18 }}>
                                                                            คัดลอกลิงก์แนะนำ
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col-6 pr-1">
                                                                    <button

                                                                        type="button"
                                                                        className="btn btn-primary btn-block"
                                                                    >
                                                                        <i

                                                                            className="fab fa-facebook-square mr-1"
                                                                            style={{ fontSize: 24 }}
                                                                        />
                                                                        <span style={{ fontSize: 18 }}>
                                                                            แชร์ไปเฟสบุ๊ค
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                <div className="col-6 pl-1">
                                                                    <button

                                                                        type="button"
                                                                        className="btn btn-success btn-block"
                                                                    >
                                                                        <i

                                                                            className="fab fa-line mr-1"
                                                                            style={{ fontSize: 24 }}
                                                                        />
                                                                        <span style={{ fontSize: 18 }}>
                                                                            แชร์ไปไลน์
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div

                                                                className="alert alert-danger d-flex flex-column flex-sm-column flex-md-row justify-content-between align-items-center"
                                                            >
                                                                <h1 className="mb-0 mr-2">
                                                                    <i

                                                                        className="fas fa-exclamation-triangle"
                                                                    />
                                                                </h1>
                                                                <div

                                                                    className="m-1 text-center text-sm-center text-md-left"
                                                                >
                                                                    <b  >หมายเหตุ!</b> หากพบปัญหา{" "}
                                                                    <u  >
                                                                        ลิงก์ถูกแบนจาก google, facebook หรือช่องทางอื่นๆ
                                                                    </u>{" "}
                                                                    ให้ส่งลิงก์แนะนำเพื่อนของท่านมายังทางไลน์ พร้อมระบุปัญหา
                                                                    สำหรับรับลิงก์ใหม่ในการแนะนำเพื่อน{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div

                                                    className="bg-white text-secondary shadow-sm rounded my-2 mx-1"
                                                >
                                                    <table

                                                        className="datatable table table-hover table-striped table-sm border"
                                                    >
                                                        <thead  >
                                                            <tr className="bg-dark text-white">
                                                                <th className="text-left ml-2">
                                                                    สินค้า
                                                                </th>
                                                                <th

                                                                    className="text-right mr-2"
                                                                    style={{ whiteSpace: "nowrap" }}
                                                                >
                                                                    ส่วนแบ่ง %
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody  >
                                                            <tr className="ng-star-inserted">
                                                                <td  >
                                                                    หวยไทย (หวย)
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </td>
                                                                <td className="text-right">
                                                                    - %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >
                                                                    หวยต่างประเทศ (หวย)
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </td>
                                                                <td className="text-right">
                                                                    - %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >
                                                                    หวยหุ้น (หวย)
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </td>
                                                                <td className="text-right">
                                                                    - %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >
                                                                    หวยยี่กี (หวย)
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </td>
                                                                <td className="text-right">
                                                                    - %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >
                                                                    หวยออนไลน์ (หวย)
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </td>
                                                                <td className="text-right">
                                                                    - %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >
                                                                    หวยหุ้นพิเศษ (หวย)
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </td>
                                                                <td className="text-right">
                                                                    - %
                                                                </td>
                                                            </tr>

                                                            <tr className="ng-star-inserted">
                                                                <td  >SA GAMING (คาสิโน)</td>
                                                                <td className="text-right">
                                                                    0.3 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >PRETTY GAMING (คาสิโน)</td>
                                                                <td className="text-right">
                                                                    0.3 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >SEXY (คาสิโน)</td>
                                                                <td className="text-right">
                                                                    0.5 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >DREAM GAMING (คาสิโน)</td>
                                                                <td className="text-right">
                                                                    0.3 %
                                                                </td>
                                                            </tr>

                                                            <tr className="ng-star-inserted">
                                                                <td  >KA GAMING (สล็อต)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >SPADE GAMING (สล็อต)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >NEXT SPIN (สล็อต)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >FUNTA GAMING (สล็อต)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >POCKET GAMES SOFT (สล็อต)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >SIMPLE PLAY (สล็อต)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>
                                                            <tr className="ng-star-inserted">
                                                                <td  >JOKER GAMING (สล็อต)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>

                                                            <tr className="ng-star-inserted">
                                                                <td  >KINGMIDAS (การ์ดเกม)</td>
                                                                <td className="text-right">
                                                                    0.25 %
                                                                </td>
                                                            </tr>



                                                            <tr className="ng-star-inserted">
                                                                <td  >หัวก้อย (มินิเกม)</td>
                                                                <td className="text-right">
                                                                    - %
                                                                </td>
                                                            </tr>

                                                            <tr  >
                                                                <td  >
                                                                    <strong  >
                                                                        หมายเหตุ{" "}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </strong>{" "}
                                                                    :{" "}
                                                                    <span className="text-danger">
                                                                        ส่วนแบ่งในการแนะนำจะเป็นค่าสูงสุดของแต่ละประเภทหวย
                                                                    </span>
                                                                </td>
                                                                <td />
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div

                                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 mb-5 xtarget col-lotto"
                                                >
                                                    <h4  >
                                                        <i className="fas fa-project-diagram" />{" "}
                                                        ลิงก์ช่วยแชร์ ช่วยแนะนำ
                                                    </h4>
                                                    <div className="alert alert-danger text-center">
                                                        <h6  >
                                                            {" "}
                                                            ลิงก์ช่วยแชร์รับสูงสุด
                                                            <span className="text-danger">
                                                                *
                                                            </span>{" "}
                                                            {tblprofile.AffPercent}% ฟรี!{" "}
                                                        </h6>{" "}
                                                        (เพียงก๊อปปี้ลิงก์ไปแชร์ก็ได้เงินแล้ว) <br />
                                                        ยิ่งแชร์มากยิ่งได้มาก{" "}
                                                    </div>
                                                    <p  >
                                                        {" "}
                                                        ท่านสามารถนำลิงก์แนะนำนี้หรือนำป้ายแบนเนอร์ ไปแชร์ในช่องทางต่างๆ
                                                        ไม่ว่าจะเป็น เว็บไชต์ส่วนตัว, Blog, Facebook หรือ Social Network อื่นๆ
                                                        หากมีการสมัครสมาชิกโดยคลิกผ่านลิงก์ของท่านเข้ามา
                                                        ลูกค้าที่สมัครเข้ามาก็จะอยู่ภายใต้เครือข่ายของท่านทันที
                                                        และหากลูกค้าภายใต้เครือข่ายของท่านมีการเดิมพันเข้ามา ทุกยอดการเดิมพัน
                                                        ท่านจะได้รับส่วนแบ่งในการแนะนำสูงสุด 0% ทันทีโดยไม่มีเงื่อนไข{" "}
                                                    </p>
                                                    <p  >
                                                        <strong  >
                                                            หมายเหตุ
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </strong>
                                                        &nbsp;ส่วนแบ่งในการแนะนำอาจจะมีอัตราแตกต่างกันไปในหวยแต่ละประเภท{" "}
                                                    </p>
                                                    <p  >
                                                        <b  >ตัวอย่างดังนี้</b>
                                                    </p>
                                                    <ul className="list-group">
                                                        <li className="list-group-item">
                                                            {" "}
                                                            ลูกค้าท่าน 1 คน แทง 1,000 บาท ท่านจะได้ 0.00 บาท{" "}
                                                        </li>
                                                        <li className="list-group-item">
                                                            {" "}
                                                            ลูกค้าท่าน 10 คน แทง 1,000 บาท ท่านจะได้รับ 0.00 บาท{" "}
                                                        </li>
                                                        <li className="list-group-item">
                                                            {" "}
                                                            ลูกค้าท่าน 100 คน แทง 1,000 บาท ท่านจะได้รับ 0.00 บาท{" "}
                                                        </li>
                                                    </ul>
                                                    <br />
                                                    <p  >
                                                        {" "}
                                                        สามารถทำรายได้หลัก 100,000 บาทต่อเดือนได้ง่ายๆ
                                                        เพราะทางเรามีหวยเปิดรับทายผลทุกวัน มีมากกว่าวันละ 200 รอบ
                                                        เปิดรับแทงออนไลน์ตลอด 24 ชม.
                                                        และรายได้ทุกบาททุกสตางค์ของท่านสามารถตรวจสอบได้ทุกขั้นตอน งานนี้แจกจริง
                                                        จ่ายจริง ที่นี่ที่เดียวที่ให้คุณมากกว่าใคร คัดลอกลิงก์แนะนำไปแชร์ได้เลย{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === 2 && (
                                            <div className="tab-content">
                                                <div

                                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 mb-1 xtarget col-lotto"
                                                >
                                                    <h4   >
                                                        <i className="fas fa-users" /> สมาชิกที่แนะนำ
                                                    </h4>
                                                </div>

                                                <div

                                                    className="bg-white text-secondary shadow-sm rounded py-2 px-1 mb-5"
                                                >
                                                    <table

                                                        id="invitemembers"
                                                        className="datatable table table-hover table-striped table-sm"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th className="datetime text-left">
                                                                    <span>วัน-เวลา</span>&nbsp;
                                                                    <span>ที่สมัคร</span>
                                                                </th>
                                                                <th>ยูสเซอร์เนม</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.useradvice.length > 0 ? (
                                                                this.state.useradvice.map((listitem, i) => (
                                                                    <tr key={i} className="ng-star-inserted">
                                                                        <td className="datetime text-left">{moment(listitem.DateCreate).format('DD/MM/YY') + " - " + moment(listitem.DateCreate).format('HH:mm') + " น."}</td>
                                                                        <td>{listitem.playerid}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={4} className="text-center">
                                                                        <span className="text-danger">ไม่มีรายการ</span>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>

                                                    </table>

                                                </div>

                                            </div>
                                        )}
                                        {activeTab === 3 && (
                                            <div className="tab-content">
                                                <div

                                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 mb-1 xtarget col-lotto"
                                                >
                                                    <h4  >
                                                        <i className="fas fa-money-check" /> รายได้
                                                    </h4>
                                                    <div className="w-100 my-2 border-bottom" />
                                                    <label  >
                                                        <i className="fas fa-calendar-alt" /> เลือกเดือน
                                                    </label>
                                                    <div

                                                        id="datetimepicker5"
                                                        data-target-input="nearest"
                                                        className="input-group date"
                                                    >
                                                        <input

                                                            type="text"
                                                            data-target="#datetimepicker5"
                                                            data-toggle="datetimepicker"
                                                            id="date"
                                                            name="date"
                                                            className="form-control datetimepicker-input mb-0"
                                                            style={{ padding: 10 }}
                                                        />
                                                        <div

                                                            data-target="#datetimepicker5"
                                                            data-toggle="datetimepicker"
                                                            className="input-group-append"
                                                        >
                                                            <button className="btn btn-outline-secondary">
                                                                <i className="fas fa-calendar my-0" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div

                                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 mb-5 xtarget col-lotto"
                                                >
                                                    <p className="alert alert-primary text-center">
                                                        {" "}
                                                        รายได้ ระบบแนะนำ จะถอนเข้าเป็นเงินเครดิต
                                                        หากสงสัยโปรดติดต่อเอเย่นต์ที่ท่านสมัครสมาชิก{" "}
                                                    </p>
                                                    <h5 className="w-100 text-center" />
                                                    <hr />
                                                    <div className="d-flex flex-row mb-2 w-100">
                                                        <div

                                                            className="table-secondary border rounded-left p-2 d-inline mr-0 pc-view"
                                                            style={{ width: "30%" }}
                                                        >
                                                            วันที่
                                                        </div>
                                                        <div

                                                            className="table-secondary border rounded-left p-2 d-inline mr-0 mobile-view"
                                                            style={{ width: "30%", fontSize: "0.8rem", justifyContent: "left" }}
                                                        >
                                                            วันที่
                                                        </div>
                                                        <div

                                                            className="bg-white border text-md-left p-2 d-inline ml-0 pc-view"
                                                            style={{ width: "35%" }}
                                                        >
                                                            {" "}
                                                            หวย{" "}
                                                        </div>
                                                        <div

                                                            className="bg-white border text-md-left p-2 d-inline ml-0 mobile-view"
                                                            style={{ width: "35%", fontSize: "0.8rem", justifyContent: "left" }}
                                                        >
                                                            {" "}
                                                            หวย{" "}
                                                        </div>
                                                        <div

                                                            className="bg-white border text-md-left rounded-right py-2 px-1 d-inline ml-0 pc-view"
                                                            style={{ width: "35%" }}
                                                        >
                                                            {" "}
                                                            สล็อต/คาสิโน{" "}
                                                        </div>
                                                        <div

                                                            className="bg-white border text-md-left rounded-right py-2 px-1 d-inline ml-0 mobile-view"
                                                            style={{ width: "35%", fontSize: "0.8rem", justifyContent: "left" }}
                                                        >
                                                            {" "}
                                                            สล็อต/คาสิโน{" "}
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row mb-2 w-100">
                                                        <div

                                                            className="table-success border border-success rounded-left p-2 d-inline mr-0 pc-view"
                                                            style={{ width: "30%" }}
                                                        >
                                                            ยอดรวม (เดือน)
                                                        </div>
                                                        <div

                                                            className="table-success border border-success rounded-left p-2 d-inline mr-0 mobile-view"
                                                            style={{ width: "30%", fontSize: "0.8rem", justifyContent: "left" }}
                                                        >
                                                            ยอดรวม (เดือน)
                                                        </div>
                                                        <div

                                                            className="bg-white border border-success border-left-0 text-right text-success p-2 d-inline ml-0 pc-view"
                                                            style={{ width: "35%" }}
                                                        >
                                                            <span className="thb">
                                                                0.00
                                                            </span>
                                                        </div>
                                                        <div

                                                            className="bg-white border border-success border-left-0 text-right text-success p-2 d-inline ml-0 mobile-view"
                                                            style={{ width: "35%", fontSize: "0.8rem" }}
                                                        >
                                                            <span className="thb">
                                                                0.00
                                                            </span>
                                                        </div>
                                                        <div

                                                            className="bg-white border border-success border-left-0 rounded-right text-right text-success p-2 d-inline ml-0 pc-view"
                                                            style={{ width: "35%" }}
                                                        >
                                                            <span className="thb">
                                                                0.00
                                                            </span>
                                                        </div>
                                                        <div

                                                            className="bg-white border border-success border-left-0 rounded-right text-right text-success p-2 d-inline ml-0 mobile-view"
                                                            style={{ width: "35%", fontSize: "0.8rem" }}
                                                        >
                                                            <span className="thb">
                                                                0.00
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                        {activeTab === 4 && (
                                            <div className="tab-content">
                                                <div

                                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 mb-1 xtarget col-lotto"
                                                >
                                                    <h4 >
                                                        <i className="fas fa-file-invoice-dollar" />{" "}
                                                        แจ้งถอนรายได้
                                                    </h4>
                                                    <hr />
                                                    <p className="alert alert-primary text-center">
                                                        รายได้ ระบบแนะนำ จะถอนเข้าเป็นเงินเครดิต
                                                        หากสงสัยโปรดติดต่อเอเย่นต์ที่ท่านสมัครสมาชิก
                                                    </p>
                                                    <div className="text-center">
                                                        <b >รายได้ปัจจุบัน - คงเหลือ</b>
                                                        <br />
                                                        <h4 className="thb text-primary">
                                                            ฿ {(tblprofile != null) ? tblprofile.AffiliateCash : 0.00}
                                                        </h4>
                                                    </div>
                                                    <div className="text-center my-3">
                                                        <div
                                                            id="afwithdraw"
                                                            role="form"
                                                            method="post"
                                                            acceptCharset="utf-8"
                                                            className="form-login form-horizontal ng-untouched ng-pristine ng-valid"
                                                        >
                                                            <a
                                                                onClick={(e) => {
                                                                    this.opencliamcashout(e, 'bonusrankaccount')
                                                                }}
                                                                className="btn btn-primary btn-block"
                                                            >
                                                                <i className="fas fa-hand-holding-usd" />{" "}
                                                                รับเครดิต{" "}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div

                                                    className="bg-white text-theme-secondary shadow-sm rounded pt-2 pb-1 px-2 mb-1"
                                                >
                                                    <h5 >
                                                        <i className="fas fa-history pr-1" />
                                                        ประวัติการการรับล่าสุด
                                                    </h5>
                                                </div>
                                                <div

                                                    className="bg-white text-theme-secondary shadow-sm rounded py-2 px-1 mb-5"
                                                >
                                                    <table

                                                        id="invitemembers"
                                                        className="datatable table table-hover table-striped table-sm"
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th className="datetime text-left">
                                                                    <span>วัน-เวลา</span>
                                                                    <span>ที่ถอน</span>
                                                                </th>
                                                                <th className="text-right">จำนวนที่ถอน</th>
                                                                <th className="totalbet text-right">
                                                                    <span>คงเหลือ</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {usercashoutlist.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan={3} className="text-center">
                                                                        <span className="text-danger">ไม่มีรายการ</span>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                usercashoutlist.map((listitem, i) => (
                                                                    <tr key={i} className="ng-star-inserted">
                                                                        <td className="datetime text-left">
                                                                            {new Date(listitem.dateupdate).toLocaleDateString("th-TH", {
                                                                                year: "numeric",
                                                                                month: "short",
                                                                                day: "numeric",
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                            })}
                                                                        </td>
                                                                        <td className="text-right">{listitem.amount || "-"}</td>
                                                                        <td className="totalbet text-right">{"0"}</td>
                                                                    </tr>
                                                                ))
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div >
                    <div id="minigame" hidden={!(this.state.loginstate && tabselect == 41)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a role="button" onClick={(e) => {
                                    this.checkurl(e, `/`)
                                }}>
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                style={{ minHeight: "70vh" }}
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-3 xtarget col-lotto"
                                >
                                    <h3 >มินิเกม</h3>
                                    <div className="row">
                                        <div className="col-12 col-md-4 my-2">
                                            <a

                                                role="button" onClick={(e) => {
                                                    this.checkurl(e, "/luckyfortune")
                                                }}
                                                className="btn-image ng-star-inserted"
                                            >
                                                <img

                                                    src="https://ufabetclub.co/wp-content/uploads/2022/08/img-wheel-title.webp"
                                                    alt="กงล้อ"
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            </a>
                                            {/**/}
                                            {/**/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="luckyfortune" hidden={!(this.state.loginstate && tabselect == 42)} className="ng-star-inserted">
                        <Luckyfortune props={this.state} />
                    </div>
                    <div id="numberdetail" hidden={!(this.state.loginstate && tabselect == 38)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a
                                    onClick={(e) => {
                                        this.checkurl(e, `/numbersets`)
                                    }}
                                    role="button"

                                >
                                    <i className="fas fa-chevron-left" /> จัดการเลขชุด
                                </a>
                            </div>
                            <div
                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                style={{ minHeight: "calc(-190px + 100vh)" }}
                            >
                                <div className="text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0">
                                    <div className="lotto-title">
                                        <h4>
                                            <i className="fas fa-file-alt" /> รายละเอียดเลขชุด
                                        </h4>
                                    </div>
                                    <button
                                        className="btn btn-danger btn-sm deleteconfirm"
                                        onClick={this.handleDelete}
                                    >
                                        <i className="fas fa-trash-alt" /> ลบรายการนี้
                                    </button>
                                </div>
                                <div className="text-secondary shadow-sm rounded pt-2 px-2 pb-0 xtarget col-lotto">
                                    <div className="row px-2 align-items-center">
                                        <div className="col-12 col-sm-12 col-md-6">
                                            <label>
                                                <i className="fas fa-tag" /> ชื่อชุด
                                            </label>{" "}
                                            : {poyDetail?.poyname}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 text-left text-sm-left text-md-right">
                                            <label>
                                                <i className="far fa-calendar" /> สร้างเมื่อ
                                            </label>{" "}
                                            : {this.formatDate(poyDetail?.createdAt)}
                                        </div>
                                        <div className="w-100" />
                                        <div className="col-12 table-secondary border-top border-bottom border-dark mb-1">
                                            <h6>ชุดตัวเลข</h6>
                                        </div>
                                        <div className="col-12">
                                            <ol className="pl-5 list-numsets">
                                                {poyDetail?.poyNumbers.map((num, index) => (
                                                    <li key={index} className="p-1 border-bottom">
                                                        <div className="row text-center">
                                                            <div className="col-5 col-sm-5 col-md-2">{num.type} </div>
                                                            <div className="col-7 col-sm-5 col-md-8">{num.number} </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal fade modal_confirm_delete"
                                tabIndex={-1}
                                role="dialog"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">ข้อความแจ้งเตือน</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <h6>คุณแน่ใจนะว่าต้องการลบ เลขชุดนี้ ?</h6>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                            >
                                                ปิด
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger btnconfirmdelete"
                                                onClick={this.handleDelete}
                                            >
                                                ลบ
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div id="numberlist" hidden={!(this.state.loginstate && tabselect == 39)} className="ng-star-inserted">

                    </div >
                    <div id="setting" hidden={!(this.state.loginstate && tabselect == 25)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a
                                    onClick={(e) => {
                                        this.checkurl(e, "/")
                                    }} role="button"

                                >
                                    <i className="fas fa-chevron-left" /> ย้อนกลับ
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch"
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div className="lotto-title">
                                        <h4 >
                                            <i className="fas fa-user-cog" />
                                            &nbsp;<b >ตั้งค่าบัญชีผู้ใช้</b>
                                        </h4>
                                    </div>
                                </div>
                                <form

                                    noValidate=""
                                    autoComplete="off"
                                    className="ng-untouched ng-pristine ng-invalid"
                                >
                                    <div

                                        className="bgwhitealpha shadow-sm rounded p-2 h-100 xtarget mb-5"
                                    >
                                        <div className="row profilesetting">
                                            <div className="col-12">
                                                <h6 >
                                                    <i className="fas fa-user-circle" />{" "}
                                                    โปรไฟล์
                                                </h6>
                                                <div className="form-group text-center">
                                                    <label htmlFor="image">
                                                        <div className="preview">
                                                            <img

                                                                id="photo"
                                                                src="/build/web/igame-index-lobby-wm/img/user-128.png"
                                                                width={80}
                                                                onError="this.src='/build/web/igame-index-lobby-wm/img/user-128.png'"
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12">
                                                <b >ชื่อผู้ใช้งาน</b>
                                                <input
                                                    value={username}
                                                    type="text"
                                                    readOnly=""
                                                    className="form-control tel-setting ng-untouched ng-pristine ng-valid"
                                                />
                                            </div>
                                            <div className="w-100 m-3 border-bottom" />
                                            <div className="col-12">
                                                <h6 >
                                                    <i className="fas fa-user-circle" />{" "}
                                                    จัดการรหัสผ่าน
                                                </h6>
                                                <small className="text-danger">
                                                    หมายเหตุ: กรณีจำรหัสผ่านปัจจุบันไม่ได้ กรุณาติดต่อแอดมิน ทางไลน์
                                                </small>
                                            </div>
                                            <div id="oldpass" className="col-12">
                                                <b >รหัสผ่านเดิม</b>
                                                <input

                                                    type="password"
                                                    name="oldpass"
                                                    id="oldpass_val"
                                                    autoComplete="off"
                                                    minLength={4}
                                                    required=""
                                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                                />

                                            </div>
                                            <div id="newpass" className="col-12">
                                                <b >รหัสผ่านใหม่</b>
                                                <input

                                                    type="password"
                                                    name="newpass"
                                                    id="newpass_val"
                                                    autoComplete="new-password"
                                                    minLength={4}
                                                    required=""
                                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                                />

                                            </div>
                                            <div id="renewpass" className="col-12">
                                                <b >ยืนยันรหัสผ่านใหม่</b>
                                                <input

                                                    type="password"
                                                    name="renewpass"
                                                    id="renewpass_val"
                                                    autoComplete="new-password"
                                                    required=""
                                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                                />

                                            </div>
                                            <div className="w-100 m-3 border-bottom" />
                                            <div className="col-12">
                                                <button

                                                    type="button"
                                                    id="profilesubmit"
                                                    className="btn btn-theme-primary btn-block"
                                                >
                                                    <i className="fas fa-save" />{" "}
                                                    เปลี่ยนรหัสผ่าน{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-enp-c12=""
                            className="ng-tns-c12-30"
                        >

                        </ngx-spinner>
                    </div>
                    <div id="request-all" hidden={!(this.state.loginstate && tabselect == 16)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/")
                                }} role="button"
                                >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch mb-5"
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div

                                        className="lotto-title d-flex flex-row align-items-end"
                                    >
                                        <h4 className="mr-1 ng-star-inserted">
                                            <i className="fas fa-tasks" />
                                            &nbsp;<b>สถานะ ฝาก-ถอนเงิน</b>
                                        </h4>



                                    </div>
                                </div>
                                <div

                                    className="col-12"
                                    style={{
                                        textAlign: "-webkit-center",
                                        position: "sticky",
                                        padding: 4,
                                        paddingTop: 10,
                                        paddingBottom: 20
                                    }}
                                >
                                    <a

                                        href="#"
                                        data-toggle="modal"
                                        data-target="#contactbox"
                                        className="mobile-view"
                                    >
                                        <button

                                            type="button"
                                            className="request-all-contact-btn"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "1px 2px 5px gray",
                                                borderRadius: 5,
                                                borderColor: "#ff1100",
                                                height: 40,
                                                color: "#ff1100"
                                            }}
                                        >
                                            <i className="fas fa-question-circle" />{" "}
                                            แจ้งปัญหาฝาก / ถอน{" "}
                                        </button>
                                    </a>
                                    <a

                                        href="#"
                                        data-toggle="modal"
                                        data-target="#contactbox"
                                        className="pc-view"
                                    >
                                        <button

                                            type="button"
                                            className="request-all-contact-btn"
                                            style={{
                                                width: "50%",
                                                boxShadow: "1px 2px 5px gray",
                                                borderRadius: 5,
                                                borderColor: "#ff1100",
                                                height: 40,
                                                color: "#ff1100"
                                            }}
                                        >
                                            <i className="fas fa-question-circle" />{" "}
                                            แจ้งปัญหาฝาก / ถอน{" "}
                                        </button>
                                    </a>
                                </div>
                                <div

                                    className="bgwhitealpha text-white shadow-sm rounded xtarget col-lotto d-flex flex-column pb-0"
                                >
                                    <div

                                        role="group"
                                        aria-label="Basic example"
                                        className="btn-group"
                                    >
                                        <a

                                            href="javascript:void(0);"
                                            className="btn btn-primary w-100"
                                        >
                                            <i className="fas fa-wallet" /> ทั้งหมด
                                        </a>
                                        <a

                                            href="javascript:void(0);"
                                            className="btn btn-success w-100"
                                        >
                                            <i className="fas fa-folder-plus" /> ฝาก
                                        </a>
                                        <a

                                            href="javascript:void(0);"
                                            className="btn btn-danger w-100"
                                        >
                                            <i className="fas fa-folder-minus" /> ถอน
                                        </a>
                                    </div>
                                </div>
                                <div className="mb-5">

                                </div>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-enp-c12=""
                            className="ng-tns-c12-32"
                        >

                        </ngx-spinner>
                    </div>
                    <div id="statement" hidden={!(this.state.loginstate && tabselect == 3)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/")
                                }} role="button" >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                style={{ minHeight: "calc((100vh - 140px) - 50px)" }}
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div className="lotto-title">
                                        <h4 >
                                            <i className="fas fa-file-invoice-dollar" />
                                            &nbsp;<b >รายงานการเงิน</b>
                                        </h4>
                                    </div>
                                </div>
                                <ul

                                    id="menucredit"
                                    role="tablist"
                                    className="nav nav-tabs nav-justified"
                                >
                                    <li className="nav-item">
                                        <a

                                            href="#tab1content"
                                            data-toggle="tab"
                                            id="tab1contentt"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                            className="nav-link active show"
                                        >
                                            <i className="fas fa-calendar-week" /> วันนี้
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a

                                            data-toggle="tab"
                                            href="#tab2content"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="false"
                                            id="tab2contentt"
                                            className="nav-link"
                                        >
                                            <i className="fas fa-history" /> ก่อนหน้า
                                        </a>
                                    </li>
                                </ul>
                                <div id="myTabContent" className="tab-content">
                                    <div

                                        id="tab1content"
                                        role="tabpanel"
                                        aria-labelledby="tab1contentt"
                                        className="tab-pane fade active show"
                                    >
                                        <div

                                            className="mb-5 py-1 bg-light rounded col-lotto"
                                        >
                                            <small className="text-secondary">
                                                *คลิกที่รายการเพื่อดูรายละเอียดเพิ่มเติม
                                            </small>
                                            <div

                                                id="accordionCredit"
                                                className="accordion bg-transparent"
                                            >
                                                <div

                                                    className="card border-0 ng-star-inserted"
                                                >
                                                    <div

                                                        id="hlist1"
                                                        data-toggle="collapse"
                                                        aria-expanded="true"
                                                        className="card-header"
                                                        data-target="#clist3"
                                                        aria-controls="clist3"
                                                    >
                                                        <div

                                                            className="bg-white border border-bottom-0 rounded-top p-2 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div

                                                                className="d-flex flex-column align-items-start text-theme-secondary"
                                                            >




                                                                <small >
                                                                    ระบบ{" "}
                                                                    <i

                                                                        className="fas fa-angle-right"
                                                                    />{" "}
                                                                    0629342954
                                                                </small>
                                                            </div>
                                                            <div

                                                                className="d-flex flex-column align-items-end"
                                                            >

                                                                <h4

                                                                    className="text-danger mb-0 d-inline ng-star-inserted"
                                                                >
                                                                    -{" "}
                                                                    <span className="thb">
                                                                        ฿ 1.00
                                                                    </span>
                                                                </h4>

                                                                <small className="text-secondary">
                                                                    <i className="far fa-clock" />{" "}
                                                                    29/10/2024 11:25:26
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                        aria-labelledby="hlist1"
                                                        data-parent="#accordionCredit"
                                                        className="collapse"
                                                        id="clist3"
                                                    >
                                                        <div className="card-body">
                                                            <div

                                                                className="d-flex justify-content-between"
                                                            >
                                                                <div className="align-middle">
                                                                    <span

                                                                        className="badge badge-secondary font-weight-light"
                                                                    >
                                                                        เลขที่รายการ #3
                                                                    </span>
                                                                </div>
                                                                <div className="align-middle">
                                                                    <small >คงเหลือ </small>
                                                                    <span className="thb text-info">
                                                                        ฿ 298.00
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small >หมายเหตุ: </small>
                                                            <small >เล่นเกม</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div

                                                    className="card border-0 ng-star-inserted"
                                                >
                                                    <div

                                                        id="hlist1"
                                                        data-toggle="collapse"
                                                        aria-expanded="true"
                                                        className="card-header"
                                                        data-target="#clist2"
                                                        aria-controls="clist2"
                                                    >
                                                        <div

                                                            className="bg-white border border-bottom-0 rounded-top p-2 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div

                                                                className="d-flex flex-column align-items-start text-theme-secondary"
                                                            >


                                                                <div

                                                                    className="align-middle ng-star-inserted"
                                                                >
                                                                    <span

                                                                        className="badge badge-danger font-weight-light w-auto"
                                                                    >
                                                                        แทงหวย
                                                                    </span>
                                                                </div>


                                                                <small >
                                                                    0629342954{" "}
                                                                    <i

                                                                        className="fas fa-angle-right"
                                                                    />{" "}
                                                                    ระบบ
                                                                </small>
                                                            </div>
                                                            <div

                                                                className="d-flex flex-column align-items-end"
                                                            >

                                                                <h4

                                                                    className="text-danger mb-0 d-inline ng-star-inserted"
                                                                >
                                                                    -{" "}
                                                                    <span className="thb">
                                                                        ฿ 1.00
                                                                    </span>
                                                                </h4>

                                                                <small className="text-secondary">
                                                                    <i className="far fa-clock" />{" "}
                                                                    29/10/2024 11:23:21
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                        aria-labelledby="hlist1"
                                                        data-parent="#accordionCredit"
                                                        className="collapse"
                                                        id="clist2"
                                                    >
                                                        <div className="card-body">
                                                            <div

                                                                className="d-flex justify-content-between"
                                                            >
                                                                <div className="align-middle">
                                                                    <span

                                                                        className="badge badge-secondary font-weight-light"
                                                                    >
                                                                        เลขที่รายการ #2
                                                                    </span>
                                                                </div>
                                                                <div className="align-middle">
                                                                    <small >คงเหลือ </small>
                                                                    <span className="thb text-info">
                                                                        ฿ 299.00
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small >หมายเหตุ: </small>
                                                            <small >แทงหวย</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div

                                                    className="card border-0 ng-star-inserted"
                                                >
                                                    <div

                                                        id="hlist1"
                                                        data-toggle="collapse"
                                                        aria-expanded="true"
                                                        className="card-header"
                                                        data-target="#clist1"
                                                        aria-controls="clist1"
                                                    >
                                                        <div

                                                            className="bg-white border border-bottom-0 rounded-top p-2 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div

                                                                className="d-flex flex-column align-items-start text-theme-secondary"
                                                            >

                                                                <div

                                                                    className="align-middle ng-star-inserted"
                                                                >
                                                                    <span

                                                                        className="badge badge-success font-weight-light w-auto"
                                                                    >
                                                                        เติมเงินโดยผู้ใช้งาน
                                                                    </span>
                                                                </div>



                                                                <small >
                                                                    0629342954{" "}
                                                                    <i

                                                                        className="fas fa-angle-right"
                                                                    />{" "}
                                                                    ระบบ
                                                                </small>
                                                            </div>
                                                            <div

                                                                className="d-flex flex-column align-items-end"
                                                            >
                                                                <h4

                                                                    className="text-success mb-0 d-inline ng-star-inserted"
                                                                >
                                                                    +{" "}
                                                                    <span className="thb">
                                                                        ฿ 300.00
                                                                    </span>
                                                                </h4>


                                                                <small className="text-secondary">
                                                                    <i className="far fa-clock" />{" "}
                                                                    29/10/2024 11:13:48
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                        aria-labelledby="hlist1"
                                                        data-parent="#accordionCredit"
                                                        className="collapse"
                                                        id="clist1"
                                                    >
                                                        <div className="card-body">
                                                            <div

                                                                className="d-flex justify-content-between"
                                                            >
                                                                <div className="align-middle">
                                                                    <span

                                                                        className="badge badge-secondary font-weight-light"
                                                                    >
                                                                        เลขที่รายการ #1
                                                                    </span>
                                                                </div>
                                                                <div className="align-middle">
                                                                    <small >คงเหลือ </small>
                                                                    <span className="thb text-info">
                                                                        ฿ 300.00
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small >หมายเหตุ: </small>
                                                            <small >เติมเงินโดยผู้ใช้งาน</small>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div

                                        id="tab2content"
                                        role="tabpanel"
                                        aria-labelledby="tab2contentt"
                                        className="tab-pane fade"
                                    >
                                        <div

                                            className="mb-5 py-1 bg-light rounded col-lotto"
                                        >
                                            <small className="text-secondary">
                                                *คลิกที่รายการเพื่อดูรายละเอียดเพิ่มเติม - รายการย้อนหลังไม่เกิน 3
                                                วัน
                                            </small>
                                            <div

                                                id="accordionCredithistory"
                                                className="accordion bg-transparent"
                                            >
                                                <div

                                                    className="card border-0 ng-star-inserted"
                                                >
                                                    <div

                                                        id="hlist1"
                                                        data-toggle="collapse"
                                                        aria-expanded="true"
                                                        className="card-header"
                                                        data-target="#clist3"
                                                        aria-controls="clist3"
                                                    >
                                                        <div

                                                            className="bg-white border border-bottom-0 rounded-top p-2 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div

                                                                className="d-flex flex-column align-items-start text-secondary"
                                                            >




                                                                <small >
                                                                    ระบบ{" "}
                                                                    <i

                                                                        className="fas fa-angle-right"
                                                                    />{" "}
                                                                    0629342954
                                                                </small>
                                                            </div>
                                                            <div

                                                                className="d-flex flex-column align-items-end"
                                                            >

                                                                <h4

                                                                    className="text-danger mb-0 d-inline ng-star-inserted"
                                                                >
                                                                    -{" "}
                                                                    <span className="thb">
                                                                        ฿ 1.00
                                                                    </span>
                                                                </h4>

                                                                <small className="text-secondary">
                                                                    <i className="far fa-clock" />{" "}
                                                                    29/10/2024 11:25:26
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                        aria-labelledby="hlist1"
                                                        data-parent="#accordionCredithistory"
                                                        className="collapse"
                                                        id="clist3"
                                                    >
                                                        <div className="card-body">
                                                            <div

                                                                className="d-flex justify-content-between"
                                                            >
                                                                <div className="align-middle">
                                                                    <span

                                                                        className="badge badge-secondary font-weight-light"
                                                                    >
                                                                        เลขที่รายการ #3
                                                                    </span>
                                                                </div>
                                                                <div className="align-middle">
                                                                    <small >คงเหลือ </small>
                                                                    <span className="thb text-info">
                                                                        ฿ 298.00
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small >หมายเหตุ: </small>
                                                            <small >เล่นเกม</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div

                                                    className="card border-0 ng-star-inserted"
                                                >
                                                    <div

                                                        id="hlist1"
                                                        data-toggle="collapse"
                                                        aria-expanded="true"
                                                        className="card-header"
                                                        data-target="#clist2"
                                                        aria-controls="clist2"
                                                    >
                                                        <div

                                                            className="bg-white border border-bottom-0 rounded-top p-2 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div

                                                                className="d-flex flex-column align-items-start text-secondary"
                                                            >


                                                                <div

                                                                    className="align-middle ng-star-inserted"
                                                                >
                                                                    <span

                                                                        className="badge badge-danger font-weight-light w-auto"
                                                                    >
                                                                        แทงหวย
                                                                    </span>
                                                                </div>


                                                                <small >
                                                                    0629342954{" "}
                                                                    <i

                                                                        className="fas fa-angle-right"
                                                                    />{" "}
                                                                    ระบบ
                                                                </small>
                                                            </div>
                                                            <div

                                                                className="d-flex flex-column align-items-end"
                                                            >

                                                                <h4

                                                                    className="text-danger mb-0 d-inline ng-star-inserted"
                                                                >
                                                                    -{" "}
                                                                    <span className="thb">
                                                                        ฿ 1.00
                                                                    </span>
                                                                </h4>

                                                                <small className="text-secondary">
                                                                    <i className="far fa-clock" />{" "}
                                                                    29/10/2024 11:23:21
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                        aria-labelledby="hlist1"
                                                        data-parent="#accordionCredithistory"
                                                        className="collapse"
                                                        id="clist2"
                                                    >
                                                        <div className="card-body">
                                                            <div

                                                                className="d-flex justify-content-between"
                                                            >
                                                                <div className="align-middle">
                                                                    <span

                                                                        className="badge badge-secondary font-weight-light"
                                                                    >
                                                                        เลขที่รายการ #2
                                                                    </span>
                                                                </div>
                                                                <div className="align-middle">
                                                                    <small >คงเหลือ </small>
                                                                    <span className="thb text-info">
                                                                        ฿ 299.00
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small >หมายเหตุ: </small>
                                                            <small >แทงหวย</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div

                                                    className="card border-0 ng-star-inserted"
                                                >
                                                    <div

                                                        id="hlist1"
                                                        data-toggle="collapse"
                                                        aria-expanded="true"
                                                        className="card-header"
                                                        data-target="#clist1"
                                                        aria-controls="clist1"
                                                    >
                                                        <div

                                                            className="bg-white border border-bottom-0 rounded-top p-2 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div

                                                                className="d-flex flex-column align-items-start text-secondary"
                                                            >

                                                                <div

                                                                    className="align-middle ng-star-inserted"
                                                                >
                                                                    <span

                                                                        className="badge badge-success font-weight-light w-auto"
                                                                    >
                                                                        เติมเงินโดยผู้ใช้งาน
                                                                    </span>
                                                                </div>



                                                                <small >
                                                                    0629342954{" "}
                                                                    <i

                                                                        className="fas fa-angle-right"
                                                                    />{" "}
                                                                    ระบบ
                                                                </small>
                                                            </div>
                                                            <div

                                                                className="d-flex flex-column align-items-end"
                                                            >
                                                                <h4

                                                                    className="text-success mb-0 d-inline ng-star-inserted"
                                                                >
                                                                    +{" "}
                                                                    <span className="thb">
                                                                        ฿ 300.00
                                                                    </span>
                                                                </h4>


                                                                <small className="text-secondary">
                                                                    <i className="far fa-clock" />{" "}
                                                                    29/10/2024 11:13:48
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div

                                                        aria-labelledby="hlist1"
                                                        data-parent="#accordionCredithistory"
                                                        className="collapse"
                                                        id="clist1"
                                                    >
                                                        <div className="card-body">
                                                            <div

                                                                className="d-flex justify-content-between"
                                                            >
                                                                <div className="align-middle">
                                                                    <span

                                                                        className="badge badge-secondary font-weight-light"
                                                                    >
                                                                        เลขที่รายการ #1
                                                                    </span>
                                                                </div>
                                                                <div className="align-middle">
                                                                    <small >คงเหลือ </small>
                                                                    <span className="thb text-info">
                                                                        ฿ 300.00
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <small >หมายเหตุ: </small>
                                                            <small >เติมเงินโดยผู้ใช้งาน</small>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-tcl-c12=""
                            className="ng-tns-c12-6"
                        >

                        </ngx-spinner>
                    </div>
                    <div id="result-all" hidden={!(this.state.loginstate && tabselect == 27)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back bg-main-custom">
                                <a
                                    onClick={(e) => {
                                        this.checkurl(e, "/")
                                    }} role="button"

                                    className="color-back-custom"
                                >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 main-content align-self-stretch bg-main-custom"
                                style={{ backgroundColor: "#fff" }}
                            >
                                <div

                                    className="text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div

                                        className="lotto-title lotto-title-result d-flex flex-row justify-content-between align-items-center w-100"
                                    >
                                        <h4 style={{ color: "black" }}>
                                            <i className="fas fa-award" />
                                            &nbsp;<b >ผลรางวัล</b>
                                        </h4>
                                    </div>
                                </div>
                                <div

                                    className="bg-white p-2 rounded shadow-sm w-100 mb-1"
                                >
                                    <div className="input-group date">
                                        <label className="mb-0 align-self-center">
                                            <i className="fas fa-calendar-alt" /> วันที่
                                        </label>
                                        <input

                                            matinput=""
                                            className="mat-input-element mat-form-field-autofill-control form-control datetimepicker-input mb-0 px-2 mx-2 cdk-text-field-autofill-monitored ng-untouched ng-pristine ng-valid"
                                            id="mat-input-0"
                                            aria-invalid="false"
                                            aria-required="false"
                                            aria-haspopup="dialog"
                                        />
                                        <mat-datepicker-toggle

                                            matsuffix=""
                                            className="mat-datepicker-toggle"
                                            tabIndex={-1}
                                        >
                                            <button
                                                mat-icon-button=""
                                                type="button"
                                                className="mat-focus-indicator mat-icon-button mat-button-base"
                                                aria-haspopup="dialog"
                                                aria-label="Open calendar"
                                                tabIndex={0}
                                            >
                                                <span className="mat-button-wrapper">
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="24px"
                                                        height="24px"
                                                        fill="currentColor"
                                                        focusable="false"
                                                        className="mat-datepicker-toggle-default-icon ng-star-inserted"
                                                    >
                                                        <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z" />
                                                    </svg>

                                                </span>
                                                <div
                                                    matripple=""
                                                    className="mat-ripple mat-button-ripple mat-button-ripple-round"
                                                />
                                                <div className="mat-button-focus-overlay" />
                                            </button>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker />

                                    </div>
                                </div>
                                <div

                                    className="text-secondary shadow-sm rounded xtarget col-lotto mb-5"
                                >
                                    <section

                                        id="contentbox"
                                        className="contentbox"
                                        style={{ backgroundColor: "#fff", border: "none" }}
                                    >
                                        <div id="government-section" className="p-2 xtarget">
                                            <div className="section-title">กลุ่มหวยไทย</div>
                                            <div className="row px-0 m-0">
                                                {filteredData.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{ background: `url(/build/web/igame-index-lobby-wm/flag/${item.productCode}.png)` }}
                                                            ></div>
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                className="flag-on-lottery-product"
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">  {item.periodName.split(" ")[1]}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1">
                                                                    <div className="p-0">
                                                                        {/* เงื่อนไขสำหรับหวยรัฐบาลไทย */}
                                                                        {item.productCode === "TH" ? (
                                                                            <div className="bg-result">
                                                                                <div className="huay-card-border-bottom-th text-th-award1 mx-2">รางวัลที่ 1</div>
                                                                                <div className="result-th-award1">{item.award1 || "-"}</div>
                                                                                <div className="d-flex">
                                                                                    <div className="col-6 pr-1 py-1 pl-0">
                                                                                        <div className="bg-result">
                                                                                            <div className="huay-card-border-bottom-th mx-2 text-th-award">3 ตัวหน้า</div>
                                                                                            <div className="d-flex justify-content-center">
                                                                                                <div className="huay-card-border-right pr-1 result-th-award">
                                                                                                    {item.award3?.split(",")[0] || "-"}
                                                                                                </div>
                                                                                                <div className="pl-1 result-th-award">
                                                                                                    {item.award3?.split(",")[1] || "-"}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="huay-card-border-bottom-th mx-2 text-th-award">3 ตัวล่าง</div>
                                                                                            <div className="d-flex justify-content-center">
                                                                                                <div className="huay-card-border-right pr-1 result-th-award">
                                                                                                    {item.award4?.split(",")[0] || "-"}
                                                                                                </div>
                                                                                                <div className="pl-1 result-th-award">
                                                                                                    {item.award4?.split(",")[1] || "-"}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-6 px-0 py-1">
                                                                                        <div className="bg-result" style={{ height: "100%" }}>
                                                                                            <div className="huay-card-border-bottom-th mx-2 text-th-award2">2 ตัวล่าง</div>
                                                                                            <div className="result-th-award2">{item.award2 || "-"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                                <div className="p-0">
                                                                                    <div className="d-flex flex-row">
                                                                                        <div className="text-center w-50 huay-card-border-right m-0">
                                                                                            <div className="p-0 huay-card-border-bottom-right">
                                                                                                <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                            </div>
                                                                                            <div className="p-0 text-award-choke">{item.award1 || "-"}</div>
                                                                                        </div>
                                                                                        <div className="text-center w-50 m-0">
                                                                                            <div className="p-0 huay-card-border-bottom-left">
                                                                                                <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                            </div>
                                                                                            <div className="p-0 text-award-choke">{item.award2 || "-"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}

                                            </div>
                                        </div>
                                        <div id="foreign-section"
                                            className="p-2 xtarget"
                                        >
                                            <div className="section-title">
                                                {" "}
                                                กลุ่มหวยต่างประเทศ{" "}
                                            </div>
                                            <div className="row px-0 px-md-1 m-0">

                                                {foreignLotteries.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{
                                                                    background: `url("/build/web/igame-index-lobby-wm/img/${item.productCode}.png")`,
                                                                }}
                                                            />
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                className="flag-on-lottery-product"
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">{item.periodName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                    <div className="p-0">
                                                                        <div className="d-flex flex-row">
                                                                            <div className="text-center w-50 huay-card-border-right m-0">
                                                                                <div className="p-0 huay-card-border-bottom-right">
                                                                                    <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award1 || "xxx"}</div>
                                                                            </div>
                                                                            <div className="text-center w-50 m-0">
                                                                                <div className="p-0 huay-card-border-bottom-left">
                                                                                    <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award2 || "xx"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div id="huayOnline-section"
                                            className="p-2 xtarget"
                                        >
                                            <div className="section-title">
                                                {" "}
                                                กลุ่มหวยออนไลน์{" "}
                                            </div>
                                            <div className="row px-0 px-md-1 m-0">

                                                {foreignLotteries.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{
                                                                    background: `url("/build/web/igame-index-lobby-wm/img/${item.productCode}.png")`,
                                                                }}
                                                            />
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                className="flag-on-lottery-product"
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">{item.periodName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                    <div className="p-0">
                                                                        <div className="d-flex flex-row">
                                                                            <div className="text-center w-50 huay-card-border-right m-0">
                                                                                <div className="p-0 huay-card-border-bottom-right">
                                                                                    <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award1 || "xxx"}</div>
                                                                            </div>
                                                                            <div className="text-center w-50 m-0">
                                                                                <div className="p-0 huay-card-border-bottom-left">
                                                                                    <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award2 || "xx"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div id="foreignStock-section"
                                            className="p-2 xtarget"
                                        >
                                            <div className="section-title">
                                                {" "}
                                                กลุ่มหวยหุ้น{" "}
                                            </div>
                                            <div className="row px-0 px-md-1 m-0">

                                                {foreignLotteries.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{
                                                                    background: `url("/build/web/igame-index-lobby-wm/img/${item.productCode}.png")`,
                                                                }}
                                                            />
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                className="flag-on-lottery-product"
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">{item.periodName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                    <div className="p-0">
                                                                        <div className="d-flex flex-row">
                                                                            <div className="text-center w-50 huay-card-border-right m-0">
                                                                                <div className="p-0 huay-card-border-bottom-right">
                                                                                    <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award1 || "xxx"}</div>
                                                                            </div>
                                                                            <div className="text-center w-50 m-0">
                                                                                <div className="p-0 huay-card-border-bottom-left">
                                                                                    <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award2 || "xx"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div id="stockVip-section"
                                            className="p-2 xtarget"
                                        >
                                            <div className="section-title">
                                                {" "}
                                                กลุ่มหวยหุ้นพิเศษ{" "}
                                            </div>
                                            <div className="row px-0 px-md-1 m-0">

                                                {foreignLotteries.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{
                                                                    background: `url("/build/web/igame-index-lobby-wm/img/${item.productCode}.png")`,
                                                                }}
                                                            />
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                className="flag-on-lottery-product"
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">{item.periodName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                    <div className="p-0">
                                                                        <div className="d-flex flex-row">
                                                                            <div className="text-center w-50 huay-card-border-right m-0">
                                                                                <div className="p-0 huay-card-border-bottom-right">
                                                                                    <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award1 || "xxx"}</div>
                                                                            </div>
                                                                            <div className="text-center w-50 m-0">
                                                                                <div className="p-0 huay-card-border-bottom-left">
                                                                                    <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award2 || "xx"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div

                                            id="huay4D-section"
                                            className="p-2 xtarget"
                                            hidden=""
                                        >
                                            <div className="section-title">
                                                {" "}
                                                กลุ่มหวย 4D{" "}
                                            </div>
                                            <div className="row px-0 px-md-1 m-0">

                                                {foreignLotteries.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{
                                                                    background: `url("/build/web/igame-index-lobby-wm/img/${item.productCode}.png")`,
                                                                }}
                                                            />
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                className="flag-on-lottery-product"
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">{item.periodName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                    <div className="p-0">
                                                                        <div className="d-flex flex-row">
                                                                            <div className="text-center w-50 huay-card-border-right m-0">
                                                                                <div className="p-0 huay-card-border-bottom-right">
                                                                                    <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award1 || "xxx"}</div>
                                                                            </div>
                                                                            <div className="text-center w-50 m-0">
                                                                                <div className="p-0 huay-card-border-bottom-left">
                                                                                    <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award2 || "xx"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div

                                            id="huayYeekee-section"
                                            className="p-2 xtarget"
                                        >
                                            <div className="section-title">
                                                {" "}
                                                หวยยี่กี{" "}
                                            </div>
                                            <div className="row px-0 px-md-1 m-0">

                                                {foreignLotteries.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{
                                                                    background: `url("/build/web/igame-index-lobby-wm/img/${item.productCode}.png")`,
                                                                }}
                                                            />
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                className="flag-on-lottery-product"
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">{item.periodName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                    <div className="p-0">
                                                                        <div className="d-flex flex-row">
                                                                            <div className="text-center w-50 huay-card-border-right m-0">
                                                                                <div className="p-0 huay-card-border-bottom-right">
                                                                                    <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award1 || "xxx"}</div>
                                                                            </div>
                                                                            <div className="text-center w-50 m-0">
                                                                                <div className="p-0 huay-card-border-bottom-left">
                                                                                    <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award2 || "xx"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div

                                            id="huayYeekee5-section"
                                            className="p-2 xtarget"
                                        >
                                            <div className="section-title">
                                                {" "}
                                                หวยยี่กีห้านาที{" "}
                                            </div>
                                            <div className="row px-0 m-0">
                                                {foreignLotteries.map((item, index) => (
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 px-1 py-1" key={index}>
                                                        <div className="d-flex bg-lottery-product cursor-pointer">
                                                            <div
                                                                className="col-4 p-0 flag-bg-product mb-0"
                                                                style={{
                                                                    background: `url("/build/web/igame-index-lobby-wm/img/${item.productCode}.png")`,
                                                                }}
                                                            />
                                                            <div className="col-8 px-1 px-sm-1 px-md-1 py-1">
                                                                <div className="d-flex m-0 py-1">
                                                                    <div className="col-3 p-0 mb-0">
                                                                        <div className={`bg-flag-lottery-product huay-card huay-card-${item.productCode.toLowerCase()}`}>
                                                                            <img
                                                                                className="flag-on-lottery-product"
                                                                                src={`/build/web/igame-index-lobby-wm/img/${item.productCode}.png`}
                                                                                alt={item.productNameTh}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 pr-0 pl-1">
                                                                        <div className={`huay-card-product huay-card-product-${item.productCode.toLowerCase()} px-2 py-0 py-md-2`}>
                                                                            <div className="huay-card-name-lotto">{item.productNameTh}</div>
                                                                            <div className="huay-card-period-lotto">{item.periodName}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column m-0 py-1 my-1 bg-result">
                                                                    <div className="p-0">
                                                                        <div className="d-flex flex-row">
                                                                            <div className="text-center w-50 huay-card-border-right m-0">
                                                                                <div className="p-0 huay-card-border-bottom-right">
                                                                                    <h5 className="text-result-topic">3 ตัวบน</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award1 || "xxx"}</div>
                                                                            </div>
                                                                            <div className="text-center w-50 m-0">
                                                                                <div className="p-0 huay-card-border-bottom-left">
                                                                                    <h5 className="text-result-topic">2 ตัวล่าง</h5>
                                                                                </div>
                                                                                <div className="p-0 text-award-choke">{item.award2 || "xx"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div

                            className="btn-group button-select-category"
                            style={{ opacity: 1, visibility: "visible", right: 10 }}
                        >
                            <button

                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className="btn dropdown-toggle"
                            >
                                <i className="fas fa-times" />
                                <i className="fas fa-search" />
                            </button>
                            <div

                                x-placement="top-start"
                                className="dropdown-menu"
                                style={{
                                    position: "absolute",
                                    transform: "translate3d(0px, -2px, 0px)",
                                    top: 0,
                                    left: 0,
                                    willChange: "transform"
                                }}
                            >
                                <a

                                    fragment="government-section"
                                    id="bt-government"
                                    className="dropdown-item"
                                    href="#/result-all#government-section"
                                >
                                    <i className="fas fa-crown" /> หวยหลัก
                                </a>
                                <a

                                    fragment="foreign-section"
                                    id="bt-foreign"
                                    className="dropdown-item"
                                    href="#/result-all#foreign-section"
                                >
                                    <i className="fas fa-flag-checkered" />{" "}
                                    หวยต่างประเทศ
                                </a>
                                <a

                                    fragment="huayOnline-section"
                                    id="bt-online"
                                    className="dropdown-item"
                                    href="#/result-all#huayOnline-section"
                                >
                                    <i className="fas fa-rss" /> หวยออนไลน์
                                </a>
                                <a

                                    fragment="foreignStock-section"
                                    id="bt-tock"
                                    className="dropdown-item"
                                    href="#/result-all#foreignStock-section"
                                >
                                    <i className="far fa-chart-line" /> หวยหุ้น
                                </a>
                                <a

                                    fragment="stockVip-section"
                                    id="bt-stockVip"
                                    className="dropdown-item"
                                    href="#/result-all#stockVip-section"
                                >
                                    <i className="fas fa-chart-bar" /> หวยหุ้นพิเศษ
                                </a>
                                <a

                                    fragment="huay4D-section"
                                    id="bt-4d"
                                    className="dropdown-item"
                                    hidden=""
                                    href="#/result-all#huay4D-section"
                                >
                                    <i className="fas fa-star" /> หวย 4D
                                </a>
                                <a

                                    fragment="huayYeekee-section"
                                    id="bt-yeekee"
                                    className="dropdown-item"
                                    href="#/result-all#huayYeekee-section"
                                >
                                    <i className="fas fa-trophy" /> หวยยี่กี
                                </a>
                                <a

                                    fragment="huayYeekee5-section"
                                    id="bt-yeekee5"
                                    className="dropdown-item"
                                    href="#/result-all#huayYeekee5-section"
                                >
                                    <i className="fas fa-trophy" /> หวยยี่กีห้านาที
                                </a>
                                <a

                                    fragment="app"
                                    id="back-top"
                                    className="dropdown-item text-dark"
                                    href="#/result-all#app"
                                >
                                    <i className="fas fa-arrow-up" /> Back to top
                                </a>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-oif-c12=""
                            className="ng-tns-c12-12"
                        >

                        </ngx-spinner>
                    </div>
                    <div id="setting-bank" hidden={!(this.state.loginstate && tabselect == 28)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/")
                                }} role="button" >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                style={{ minHeight: "calc((100vh - 140px) - 50px)" }}
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                >
                                    <div className="lotto-title">
                                        <h4 >
                                            <i className="fas fa-university" />
                                            &nbsp;<b >บัญชีธนาคาร</b>
                                        </h4>
                                    </div>
                                </div>
                                <div

                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 mb-5 xtarget col-lotto"
                                >
                                    <div id="secondtime" style={{ display: "block" }}>
                                        <h3 >
                                            <span

                                                className="badge badge-pill badge-secondary font-weight-light"
                                            >
                                                ชื่อบัญชี
                                            </span>{" "}
                                            {firstname}
                                        </h3>
                                        <div className="form-row">
                                            <div className="col-12">
                                                <label >
                                                    <i className="fas fa-money-check" />{" "}
                                                    บัญชีธนาคารที่สามารถใช้ได้
                                                </label>
                                            </div>
                                            <div

                                                className="col-6 col-sm-6 col-md-3 col-lg-2 text-center ng-star-inserted"
                                            >
                                                <input
                                                    type="radio"
                                                    name="bank2"
                                                    className="input-hidden"
                                                    defaultValue={accnumber !== null ? accnumberx.fullname_th : ""}
                                                />
                                                <label

                                                    className="bank-radio"
                                                    htmlFor="[object Object]"
                                                >
                                                    <img

                                                        className="icon-bank"
                                                        src={accnumber !== null ? accnumberx.img_path : ""}
                                                        alt="kbank"
                                                    />
                                                    <br />
                                                    <span >ธนาคาร{accnumber !== null ? accnumberx.fullname_th : ""}</span>
                                                    <span className="badge badge-dark">
                                                        {accnumber !== null ? accnumberx.accno.substring(0, 3) + "-" + accnumberx.accno.substring(3, 9) + "-" + accnumberx.accno.substring(9, 12) : ""}
                                                    </span>
                                                    <span > {firstname}</span>
                                                </label>
                                            </div>


                                            <div className="border-bottom w-100 my-2" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-oif-c12=""
                            className="ng-tns-c12-15"
                        >

                        </ngx-spinner>
                    </div>
                    <div id="gamelist" hidden={!(this.state.loginstate && tabselect == 15)} className="ng-star-inserted">
                        <div id="section-content" className="container p-1">

                            <div className="bar-back ng-star-inserted">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/game-sl")
                                }} role="button">
                                    <i className="fas fa-chevron-left" /> ย้อนกลับ
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 main-content align-self-stretch ng-star-inserted"
                            >

                                <div className="d-flex justify-content-center py-2">
                                    <img

                                        src="/build/web/igame-index-lobby-wm/img/game-topic.png"
                                        className="header-game-topic"
                                    />
                                    <h3 className="provider-name-head">
                                        {" "}
                                        {this.state.pathname}
                                    </h3>
                                </div>
                                <div className="col-12 mb-2 px-0">
                                    <div

                                        id="data-target-input"
                                        data-target="#datetimepicker3"
                                        className="input-group date"
                                    >
                                        <input

                                            type="text"
                                            name="dreamtext"
                                            id="dreamtext"
                                            placeholder="ค้นหารายชื่อเกม"
                                            className="form-control mb-0 input-search-game ng-untouched ng-pristine ng-valid"
                                            style={{ backgroundColor: "#ffffff", color: "black" }}
                                        />
                                        <div className="input-group-append">
                                            <button

                                                type="button"
                                                className="btn btn-outline-secondary"
                                                style={{ background: "white", border: "1px solid #ced4da" }}
                                            >
                                                <i className="fas fa-search" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div

                                    className="text-theme-primary rounded p-2 px-3 xtarget col-lotto"
                                >
                                    <div className="row border-game px-1 py-1">

                                        {listitallgame.map((listitem, i) => (
                                            <div

                                                className="col-6 col-md-4 col-lg-3 col-xl-3 p-1 ng-star-inserted"
                                            >
                                                <div className="ng-star-inserted">
                                                    <a
                                                        role="button"
                                                        onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}>
                                                        <img

                                                            style={{ width: "100%", height: "auto" }}
                                                            src={listitem.image}
                                                        />
                                                        <div

                                                            className="text-center py-1 theme-game"
                                                        >
                                                            {listitem.description}
                                                        </div>
                                                    </a>
                                                </div>


                                            </div>
                                        ))}

                                    </div>


                                </div>
                            </div>

                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-oif-c12=""
                            className="ng-tns-c12-17"
                        >

                        </ngx-spinner>
                        <div

                            id="openNewBrowser"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModal"
                            aria-hidden="true"
                            className="modal fade"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-lg"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div className="modal-header bg-theme-primary">
                                        <h5 className="modal-title">
                                            เบราว์เซอร์ไม่รองรับ กรุณาทำตามขั้นตอนต่อไปนี้
                                        </h5>
                                    </div>
                                    <div className="modal-body">

                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "-1px 2px 5px black"
                                            }}
                                        >
                                            ตกลง{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="numbersets" hidden={!(this.state.loginstate && tabselect == 32)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/")
                                }} role="button" >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div

                                className="p-2 w-100 bg-light main-content align-self-stretch"
                            >
                                <div

                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0"
                                >
                                    <div className="lotto-title">
                                        <h4 >
                                            <i className="fas fa-list-ol" />
                                            &nbsp;<b >จัดการเลขชุด</b>
                                        </h4>
                                    </div>
                                    <a
                                        onClick={() =>
                                            Swal.fire({
                                                title: 'แจ้งเตือน',
                                                text: 'ต้องเข้าไปเพิ่มที่ห้องหวย',
                                                icon: 'warning',
                                                confirmButtonText: 'ตกลง',
                                            })
                                        }
                                        // href="#/numbersets-add"
                                        // routerlink="/numbersets-add"
                                        className="btn btn-success btn-sm"
                                    >
                                        <i className="fas fa-plus" /> สร้างเลขชุด
                                    </a>
                                </div>
                                <div

                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded py-2 xtarget col-lotto"
                                >
                                    <table

                                        id="numbersets"
                                        className="datatable table table-hover table-striped table-sm"
                                    >
                                        <thead >
                                            <tr >
                                                <th >ชื่อเลขชุด</th>
                                                <th className="datetime">
                                                    <span >วัน-เวลา</span>
                                                    <span >ที่สร้าง</span>
                                                </th>
                                                <th className="option">
                                                    จัดการ
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {/* <tr className="ng-star-inserted">
                                                <td style={{ overflowWrap: "anywhere" }}>
                                                    ทดสอบ
                                                </td>
                                                <td className="text-center">
                                                    06/12/2024 06:37
                                                </td>
                                                <td className="text-center">
                                                    <a

                                                        href="#/numbersets-detail/5408"
                                                        className="btn btn-outline-primary"
                                                    >
                                                        รายละเอียด
                                                    </a>
                                                </td>
                                            </tr> */}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="uploadslip" hidden={!(this.state.loginstate && tabselect == 43)} className="ng-star-inserted">
                        <div id="section-content" className="container">
                            <div className="bar-back">
                                <a onClick={(e) => {
                                    this.checkurl(e, "/")
                                }} role="button" >
                                    <i className="fas fa-chevron-left" /> หน้าหลัก
                                </a>
                            </div>
                            <div className="p-2 w-100 bg-light main-content align-self-stretch"   >
                                <div className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0"  >
                                    <div className="lotto-title">
                                        <h4 >
                                            <i className="fas fa-list-ol" />
                                            &nbsp;<b >อัพโหลด Slip</b>
                                        </h4>
                                    </div>
                                </div>
                                <div className="bgwhitealpha text-theme-secondary shadow-sm rounded py-2 xtarget col-lotto" >
                                    <div>
                                        <h1 className="text-xl text-highlight font-medium text-center mt-8 mb-4">
                                            อัพโหลด Slip
                                        </h1>
                                        <h2 className="mb-4 font-medium">อัพโหลดเฉพาะภาพ Slip</h2>
                                        <form className="MuiBox-root css-1f8hzjo">
                                            <p className="MuiTypography-root MuiTypography-body1 css-17nk34h">
                                                อัปโหลด Slip
                                            </p>
                                            <div className="MuiBox-root css-10zh34x">
                                                {/* ปุ่มอัปโหลดไฟล์ */}
                                                {this.state.previewUrl && (
                                                    <>
                                                        <img
                                                            id="imagePreview"
                                                            width={"250px"}
                                                            src={this.state.previewUrl}
                                                            className="w-full mt-2 rounded-lg border"
                                                            alt="Preview"
                                                        />

                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                    </>
                                                )}

                                                <label
                                                    htmlFor="upload"
                                                    className="btn btn-primary inline-flex items-center gap-2 px-3 py-1.5 rounded-md cursor-pointer"
                                                >
                                                    <svg
                                                        className="w-[16px] h-[16px] text-white"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
                                                    </svg>
                                                    <br></br>
                                                    <span className="text-sm">อัปโหลด</span>
                                                </label>




                                                {/* Input เลือกไฟล์ (ซ่อน) */}
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={this.handleFileSelectedt}
                                                    id="upload"
                                                    name="filename"
                                                />

                                                {/* แสดงภาพพรีวิวถ้ามีไฟล์ที่เลือก */}

                                            </div>
                                        </form>
                                        {this.state.files.length > 0 && !this.state.isConfirmed && (
                                            <div id="submitBTN">
                                                <button role="button" onClick={this.handleConfirmUpload} className="col-5 px-3 mx-3  bg-link-product-main-green text-white rounded-lg py-2">
                                                    ยืนยันการอัพโหลด
                                                </button>
                                            </div>
                                        )}
                                        {this.state.isConfirmed && (
                                            <p className="text-success mt-2">✅ อัปโหลดสลิปสำเร็จแล้ว</p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay" />
                    <div

                        id="contactbox"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="contact"
                        aria-hidden="true"
                        className="modal fade"
                    >
                        <div

                            role="document"
                            className="modal-dialog modal-lg"
                        >
                            <div

                                className="modal-content"
                                style={{ borderRadius: 10 }}
                            >
                                <div className="modal-header bg-theme-primary">
                                    <span className="modal-title">
                                        เลือกช่องทางการติดต่อเรา
                                    </span>
                                    <button

                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="text-white close"
                                    >
                                        <span aria-hidden="true">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div

                                            className="col-6 col-sm-6 col-md-6 ng-star-inserted"
                                            data-index={0}
                                        >
                                            <a

                                                className="btn btn-outline-success btn-block btn-contactbox mb-3"
                                                style={{ marginBottom: 0 }}
                                                href={this.state.linelink}
                                            >
                                                <span >
                                                    <small

                                                        className="d-flex justify-content-center"
                                                    >
                                                        ติดต่อผ่านไลน์ &nbsp;
                                                    </small>
                                                </span>
                                                <i className="fab fa-line" />
                                            </a>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-6">
                                            <a
                                                onClick={(e) => {
                                                    this.handleClickChatScript(e)
                                                }}
                                                role="button"
                                                className="btn btn-outline-danger btn-block btn-contactbox mb-0"
                                            >
                                                <span >
                                                    <small >แชทสดกับพนักงาน</small>
                                                </span>
                                                <i

                                                    className="fa fa-user"
                                                    style={{ fontSize: 46 }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button

                                        type="button"
                                        data-dismiss="modal"
                                        className="btn btn-theme-primary"
                                        style={{
                                            width: "-webkit-fill-available",
                                            boxShadow: "-1px 2px 5px black"
                                        }}
                                    >
                                        ปิด
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div

                        id="loginPopup"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="loginPopup"
                        className="modal fade"
                        style={{ display: "none" }}
                        aria-hidden="true"
                    >
                        <div

                            role="document"
                            className="modal-dialog modal-lg"
                        >
                            <div

                                className="modal-header pb-0 px-lg-4 px-0"
                                style={{ border: "none" }}
                            >
                                <button

                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="text-white close pb-3"
                                    style={{ opacity: 1 }}
                                >
                                    <span

                                        aria-hidden="true"
                                        style={{ fontSize: "larger" }}
                                    >
                                        <i className="far fa-times-circle" />
                                    </span>
                                </button>
                            </div>
                            <div

                                className="modal-content px-3 px-lg-5 py-1"
                                style={{
                                    borderRadius: 20,
                                    backgroundColor: "transparent",
                                    border: "none"
                                }}
                            >
                                <div className="modal-body bg-login-popup">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="col-12 text-center">
                                                <img

                                                    src="https://cdn.javisx.com/image/d14c464b-0fe1-4865-991a-47d6f05ca862.png"
                                                    className="logo-app-popup"
                                                    alt="Lotto888"
                                                    title="Lotto888"
                                                />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <form

                                                    noValidate=""
                                                    id="login1"
                                                    autoComplete="off"
                                                    role="form"
                                                    acceptCharset="utf-8"
                                                    className="form-login form-horizontal ng-untouched ng-pristine ng-valid"
                                                >
                                                    <div className="row form-middle">
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 mt-2 px-4"
                                                        >
                                                            <div className="form-group mb-0">
                                                                <div className="dvImageTextBox">
                                                                    <img

                                                                        src="/build/web/igame-index-lobby-wm/img/telephone.svg"
                                                                    />
                                                                    <input
                                                                        id="username"
                                                                        type="number"
                                                                        name="username"
                                                                        inputMode="text"
                                                                        onChange={this.onChange}
                                                                        placeholder="เบอร์โทรศัพท์"
                                                                        className="form-control login-btn ng-untouched ng-pristine ng-valid"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 my-2 px-4"
                                                        >
                                                            <div className="form-group mb-0">
                                                                <div className="d-flex mb-0">
                                                                    <div

                                                                        className="dvImageTextBox box-pwd position-relative"
                                                                    >
                                                                        <img

                                                                            src="/build/web/igame-index-lobby-wm/img/lock.svg"
                                                                            className="lock-icon"
                                                                        />
                                                                        <input

                                                                            type="password"
                                                                            id="password"
                                                                            name="password"
                                                                            onChange={this.onChange}
                                                                            placeholder="รหัสผ่าน"
                                                                            required=""
                                                                            className="form-control ng-dirty ng-valid ng-touched"
                                                                            style={{
                                                                                paddingTop: 10,
                                                                                paddingBottom: 10,
                                                                                paddingLeft: 35
                                                                            }}
                                                                            data-gtm-form-interact-field-id={1}
                                                                        />
                                                                    </div>
                                                                    <div

                                                                        className="input-group-append"
                                                                    >
                                                                        <div

                                                                            className="input-group-text style-eye"
                                                                        >
                                                                            <i

                                                                                className="fa fa-eye-slash"
                                                                                style={{ cursor: "pointer" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="col-12 custombglogin"
                                                            style={{ justifyContent: "center", display: "flex" }}
                                                        >
                                                            <div

                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                                className="rule"
                                                            >
                                                                <a

                                                                    className="m-2"
                                                                    style={{ fontSize: "large", lineHeight: 17 }}
                                                                >
                                                                    ลืมรหัสผ่าน
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 my-2 px-4"
                                                        >
                                                            <a
                                                                onClick={(e) => {
                                                                    this.login(e)
                                                                }}
                                                                type="submit"
                                                                className="btn btn-gradiant-login-m btn-block"
                                                            >
                                                                {" "}
                                                                เข้าสู่ระบบ{" "}
                                                            </a>
                                                        </div>
                                                        <div

                                                            className="col-12 p-1"
                                                            style={{ textAlign: "-webkit-center" }}
                                                        >
                                                            <div className="uline" />
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 my-2 px-4"
                                                        >
                                                            <a
                                                                onClick={(e) => {
                                                                    this.checkurl(e, "/register")
                                                                }}
                                                                type="button"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                                className="btn btn-login-white-popup btn-block"
                                                            >
                                                                {" "}
                                                                สมัครสมาชิก{" "}
                                                            </a>
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 px-4 mb-2"
                                                        >
                                                            <a

                                                                target="_blank"
                                                                className="btn btn-login-register-admin-m btn-block"
                                                                href={this.state.linelink}
                                                            >
                                                                {" "}
                                                                สมัครสมาชิกผ่านแอดมิน{" "}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div

                                            className="col-12 col-md-6 align-self-center"
                                        >
                                            <div className="pc-view">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/login-popup-pc.png"
                                                    style={{ width: "100%", height: "auto", borderRadius: 10 }}
                                                />
                                            </div>
                                            <div className="mobile-view">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/login-popup-m.png"
                                                    style={{ width: "100%", height: "auto", borderRadius: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="page-footer font-small bg-danger mobile-view ng-star-inserted" >
                        <div className="footer-copyright text-center py-2">
                            <div className="footer-text">
                                © 2024 Copyright - Lotto888
                            </div>
                        </div>
                    </footer>
                    <div className="px-2">
                        <div id="myNavbar"
                            className="choke-bt-navbar my-0"
                            style={{ overflow: "visible" }}
                            hidden={!this.state.loginstate}
                        >
                            <a onClick={(e) => {
                                this.checkurl(e, "/lotto")
                            }} role="button">
                                <img

                                    src="/build/web/igame-index-lobby-wm/img/lot-btn-mb.png"
                                    style={{ maxHeight: 38 }}
                                />
                            </a>
                            <a onClick={(e) => {
                                this.checkurl(e, "/deposit")
                            }}

                                role='button'>
                                <img

                                    src="/build/web/igame-index-lobby-wm/img/deposit-btn-mb.png"
                                    style={{ maxHeight: 40, marginBottom: 2 }}
                                />
                            </a>
                            <a onClick={(e) => {
                                this.checkurl(e, "/")
                            }} role="button">
                                <img

                                    src="https://cdn.javisx.com/image/4c0ea74d-05a2-447e-a121-95cc85ce9eba.png"
                                    style={{ maxHeight: 85, marginBottom: 26 }}
                                />
                            </a>
                            <a onClick={(e) => {
                                this.checkurl(e, "/game-sl")
                            }}
                                role="button"
                            >
                                <img

                                    src="/build/web/igame-index-lobby-wm/img/slot-btn-mb.png"
                                    style={{ maxHeight: 40, marginBottom: 6 }}
                                />
                            </a>
                            <a onClick={(e) => {
                                this.checkurl(e, "/result-all")
                            }}
                                role="button">
                                <img

                                    src="/build/web/igame-index-lobby-wm/img/result-btn-mb.png"
                                    style={{ maxHeight: 40, marginBottom: 6 }}
                                />
                            </a>
                        </div>
                    </div>

                </div >
                <div id="app" style={{ minHeight: "calc(100% - 60px)", overflow: "hidden", display: tabselect == 22 ? "" : "none" }} >

                    <div className="custom-bg" />
                    <div className="bg-app" style={{ display: isMobile && tabselect === 0 ? "" : "none" }} >
                        <div className="mobile-view">
                            <mdb-carousel className="mobile carousel slide carousel-fade ng-star-inserted"  >
                                <div tabIndex={0} className="carousel carousel-fade mobile slide">
                                    <div className="carousel-inner">
                                        <Slider >
                                            <div
                                                className="carousel-item ng-star-inserted"
                                            >
                                                <div className="view w-100 ais-hero">
                                                    <img
                                                        className="d-block w-100"
                                                        src="https://s3.365huay.net/banner/TAOKAELOTTO/1718171818826.jpg"
                                                    />
                                                    <div
                                                        mdbwaveseffect=""
                                                        className="mask rgba-black-slight waves-light"
                                                    />
                                                </div>
                                            </div>


                                        </Slider >

                                    </div>


                                </div>
                            </mdb-carousel>



                        </div>
                        <div className="container px-0" hidden={this.state.loginstate}>
                            <div

                                className="pc-view row p-5 p-md-0 px-xs-2 justify-content-center"
                            >
                                <div

                                    className="col-sm-12 col-md-12 col-lg-3 py-1 pc-view"
                                    style={{
                                        alignSelf: "center",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <img

                                        src="/build/web/igame-index-lobby-wm/img/logo.png"
                                        className="mt-0"
                                        style={{ height: "3.2rem", width: "auto" }}
                                        alt="Lotto888"
                                        title="Lotto888"
                                    />
                                </div>
                                <div

                                    className="col-sm-12 col-md-8 col-lg-9 px-4"
                                    style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                    <form

                                        noValidate=""
                                        id="login2"
                                        autoComplete="off"
                                        role="form"
                                        acceptCharset="utf-8"
                                        className="form-login form-horizontal ng-untouched ng-pristine ng-valid"
                                    >
                                        <div className="row form-middle">
                                            <div className="col-lg" />
                                            <div

                                                className="col-sm-12 col-md-6 col-lg-3 p-1 mt-1"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="dvImageTextBox">
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/telephone.svg"
                                                        />
                                                        <input

                                                            id="username"
                                                            type="number"
                                                            name="username"
                                                            inputMode="text"
                                                            onChange={this.onChange}

                                                            placeholder="เบอร์โทรศัพท์"
                                                            className="form-control login-btn ng-untouched ng-pristine ng-valid"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div

                                                className="col-sm-12 col-md-6 col-lg-3 p-1 mt-1"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="d-flex mb-0">
                                                        <div

                                                            className="dvImageTextBox box-pwd position-relative"
                                                        >
                                                            <img

                                                                src="/build/web/igame-index-lobby-wm/img/lock.svg"
                                                                className="lock-icon"
                                                            />
                                                            <input
                                                                type="password"
                                                                id="password"
                                                                name="password"
                                                                onChange={this.onChange}
                                                                placeholder="รหัสผ่าน"
                                                                required=""
                                                                className="form-control ng-dirty ng-valid ng-touched"
                                                                style={{
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                    paddingLeft: 35
                                                                }}
                                                                data-gtm-form-interact-field-id={1}
                                                            />
                                                        </div>
                                                        <div

                                                            className="input-group-append"
                                                        >
                                                            <div

                                                                className="input-group-text style-eye"
                                                            >
                                                                <i

                                                                    className="fa fa-eye-slash"
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-2 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.login(e)
                                                    }}
                                                    type="submit"
                                                    className="btn btn-gradiant-login btn-block"
                                                >
                                                    {" "}
                                                    เข้าสู่ระบบ{" "}
                                                </a>
                                            </div>
                                            <div className="col-6 col-lg-2 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/register")
                                                    }}
                                                    type="button"
                                                    className="btn btn-login-white btn-block d-flex justify-content-center"
                                                >
                                                    {" "}
                                                    สมัครสมาชิก{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <div

                                            className="row mt-1 mb-2 custombglogin"
                                        >
                                            <div className="col-lg pc-view" />
                                            <div

                                                className="col-sm-12 col-md-7 col-lg-6 rule pc-view"
                                            >
                                                <a

                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#ModalRule"
                                                    className="m-2"
                                                >
                                                    กฏกติกา
                                                </a>
                                                <a

                                                    href="#"
                                                    className="m-2"
                                                >
                                                    ลืมรหัสผ่าน
                                                </a>
                                            </div>
                                            <div

                                                className="col-lg-4 submenulogin pc-view px-1 m-0"
                                            >
                                                <div

                                                    className="btoutline choke-btn-font"
                                                >
                                                    <a

                                                        target="_blank"
                                                        className="btn btn-login-register-admin btn-block"
                                                        href={this.state.linelink}
                                                    >
                                                        {" "}
                                                        สมัครสมาชิกผ่านแอดมิน{" "}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="mobile-view row px-5 pt-0 pb-0">
                                <div

                                    className="col-sm-12 col-md-4 col-lg-3 mt-0 mb-0 text-center py-2 py-md-0"
                                >
                                    <img

                                        src="https://cdn.javisx.com/image/4c0ea74d-05a2-447e-a121-95cc85ce9eba.png"
                                        className="logo-app"
                                        alt="Lotto888"
                                        title="Lotto888"
                                    />
                                </div>
                                <div

                                    className="col-sm-12 col-md-8 col-lg-9 px-4 mt-0"
                                >
                                    <form

                                        noValidate=""
                                        id="login3"
                                        autoComplete="off"
                                        role="form"
                                        acceptCharset="utf-8"
                                        className="form-login form-horizontal ng-dirty ng-touched ng-valid"
                                        data-gtm-form-interact-id={0}
                                    >
                                        <div

                                            className="row form-middle"
                                            style={{ marginTop: "-15px" }}
                                        >
                                            <div className="col" />
                                            <div

                                                className="col-sm-12 col-md-4 col-lg-3 p-1 mt-3"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="dvImageTextBox">
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/telephone.svg"
                                                            className="mx-1"
                                                        />
                                                        <input

                                                            id="username"
                                                            type="number"
                                                            name="username"
                                                            inputMode="text"
                                                            onChange={this.onChange}

                                                            placeholder="เบอร์โทรศัพท์"
                                                            className="form-control login-btn ng-valid ng-dirty ng-touched"
                                                            data-gtm-form-interact-field-id={0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div

                                                className="col-sm-12 col-md-4 col-lg-3 p-1 mt-1"
                                            >
                                                <div className="form-group mb-0">
                                                    <div className="d-flex mb-0">
                                                        <div

                                                            className="dvImageTextBox box-pwd position-relative"
                                                        >
                                                            <img

                                                                src="/build/web/igame-index-lobby-wm/img/lock.svg"
                                                                className="lock-icon"
                                                            />
                                                            <input

                                                                type="password"
                                                                id="password"
                                                                name="password"
                                                                onChange={this.onChange}
                                                                placeholder="รหัสผ่าน"
                                                                required=""
                                                                className="form-control ng-dirty ng-valid ng-touched"
                                                                style={{
                                                                    paddingTop: 10,
                                                                    paddingBottom: 10,
                                                                    paddingLeft: 35
                                                                }}
                                                                data-gtm-form-interact-field-id={1}
                                                            />
                                                        </div>
                                                        <div

                                                            className="input-group-append"
                                                        >
                                                            <div

                                                                className="input-group-text style-eye"
                                                            >
                                                                <i

                                                                    className="fa fa-eye-slash"
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 custombglogin">
                                                <div

                                                    className="rule"
                                                    style={{ justifyContent: "space-between", display: "flex" }}
                                                >
                                                    <a

                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#ModalRule"
                                                        className="m-2"
                                                    >
                                                        กฏกติกา
                                                    </a>
                                                    <a className="m-2">
                                                        ลืมรหัสผ่าน
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.login(e)
                                                    }}
                                                    type="submit"
                                                    className="btn btn-gradiant-login-m btn-block"
                                                >
                                                    {" "}
                                                    เข้าสู่ระบบ{" "}
                                                </a>
                                            </div>
                                            <div

                                                className="col-12 p-1"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <div className="uline" />
                                            </div>
                                            <div className="col-12 p-1 mt-1">
                                                <a
                                                    onClick={(e) => {
                                                        this.checkurl(e, "/register")
                                                    }}
                                                    type="button"
                                                    className="btn btn-login-white-m btn-block"
                                                >
                                                    {" "}
                                                    สมัครสมาชิก{" "}
                                                </a>
                                            </div>
                                            <div className="col-12 p-1 mt-1">
                                                <a

                                                    target="_blank"
                                                    className="btn btn-login-register-admin-m btn-block"
                                                    href={this.state.linelink}
                                                >
                                                    {" "}
                                                    สมัครสมาชิกผ่านแอดมิน{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <div

                                            className="row mt-1 mb-2 custombglogin"
                                        >
                                            <div className="col pc-view" />
                                            <div

                                                className="col-sm-12 col-md-7 col-lg-6 rule pc-view"
                                            >
                                                <a className="m-2">
                                                    ลืมรหัสผ่าน
                                                </a>
                                            </div>
                                            <div

                                                className="col-lg-4 submenulogin pc-view px-1 m-0"
                                            >
                                                <div

                                                    className="btoutline choke-btn-font"
                                                >
                                                    <a

                                                        target="_blank"
                                                        className="btn btn-login-register-admin btn-block"
                                                        href={this.state.linelink}
                                                    >
                                                        {" "}
                                                        สมัครสมาชิกผ่านแอดมิน{" "}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div

                                id="ModalRule"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="ModalRule"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-lg"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div

                                            className="modal-header bg-theme-primary"
                                        >
                                            <span className="modal-title">
                                                กฏและกติกา
                                            </span>
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                className="text-white close"
                                            >
                                                <i className="far fa-times" />
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p >
                                                <span className="pl-3">
                                                    ยินดีต้อนรับสู่หวยออนไลน์
                                                </span>{" "}
                                                เพื่อให้ท่านทราบถึงสิทธิประโยชน์ของสมาชิกกรุณาอ่านข้อตกลงอย่างละเอียดก่อนที่จะลงทะเบียนสมัครเป็นสมาชิกและใช้บริการ{" "}
                                                <a href="https:">
                                                    Lotto888
                                                </a>{" "}
                                                เมื่อสมัครสมาชิกแล้วทางเว็บจะถือว่า สมาชิกได้อ่านและยอมรับ{" "}
                                                <span className="text-primary">
                                                    " นโยบายและเงื่อนไขการให้บริการของเว็บไซต์"
                                                </span>
                                            </p>
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        การรักษาผลประโยชน์และช่วยเหลือการเดิมพันออนไลน์
                                                    </u>
                                                </strong>
                                                <br />
                                            </p>
                                            <ul className="pl-3">
                                                <li >
                                                    ถ้าสมาชิกหลุดออกจากเว็บไซต์ระหว่างการเดิมพัน
                                                    บัญชีของท่านจะไม่มีผลกระทบต่อผลลัพธ์ของการเดิมพัน{" "}
                                                </li>
                                                <li >
                                                    ไม่ต้องวิตกกังวล ถ้าสมาชิกออกจากเว็บไซด์ระหว่างการเดิมพัน
                                                    สมาชิกจะถูกออกจากระบบช่วงเวลาหนึ่ง
                                                    จากนั้นสมาชิกสามารถเข้าสู่ระบบ{" "}
                                                    <a href="https:">
                                                        Lotto888
                                                    </a>{" "}
                                                    ได้อีกครั้งตามปกติ{" "}
                                                </li>
                                            </ul>
                                            <p />
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        การให้ความคุ้มครอง
                                                    </u>
                                                </strong>
                                                <br />
                                                <a href="https:">
                                                    Lotto888
                                                </a>{" "}
                                                ได้มีการจัดเตรียม ระบบที่มีประสิทธิภาพ
                                                ความรวดเร็วและการบริการลูกค้าที่เป็นมิตร
                                                ถ้าหากท่านมีคำถามหรือคำแนะนำใด ๆ
                                                กรุณาอย่าลังเลใจที่จะโทรศัพท์มาทางฝ่ายบริการลูกค้า
                                                ซึ่งจะมีให้บริการตลอด 24 ชั่วโมง
                                                ทุกวันไม่มีวันหยุดและทางเราจะตอบกลับอย่างรวดเร็วที่สุด{" "}
                                                <a href="https:">
                                                    Lotto888
                                                </a>{" "}
                                                ขอรับรองว่าจะรักษาข้อมูลส่วนตัวของท่านไว้เป็นความลับอย่างปลอดภัย{" "}
                                            </p>
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        ข้อตกลงในการเดิมพัน
                                                    </u>
                                                </strong>
                                                <br />
                                            </p>
                                            <ul className="pl-3">
                                                <li >
                                                    เพื่อหลีกเลี่ยงปัญหาต่างๆในขณะที่ใช้งานเว็บไซต์
                                                    สมาชิกกรุณาอ่านกฎกติกาของบริษัทอย่างละเอียดและระมัดระวัง
                                                    เมื่อสมาชิกเข้าสู่หน้าเดิมพันทางบริษัทจะถือว่าท่านได้ยอมรับข้อตกลงของทาง{" "}
                                                    <a href="https:">
                                                        Lotto888
                                                    </a>
                                                </li>
                                                <li >
                                                    การเดิมพันจะต้องอยู่ใน{" "}
                                                    <span className="text-success">
                                                        "ระยะเวลาที่กำหนด"
                                                    </span>{" "}
                                                    มิฉะนั้นจะถือว่าการเดิมครั้งนั้นเป็น{" "}
                                                    <span className="text-danger">
                                                        "โมฆะ"{" "}
                                                    </span>{" "}
                                                    ถ้าการเดิมพันได้มีการยกเลิกหรือหยุดพักชั่วคราว
                                                    เนื่องจากเหตุผลใดๆก็ตามก่อนการเล่น
                                                    การเดิมพันครั้งนั้นจะถือว่าเป็น{" "}
                                                    <span className="text-danger">
                                                        "โมฆะ"{" "}
                                                    </span>
                                                    และทางบริษัทจะคืนเครดิตให้กับสมาชิก{" "}
                                                </li>
                                                <li >
                                                    เป็นความรับผิดชอบของสมาชิกที่จะคอยดูผลลัพธ์
                                                    จากช่องหน้าต่างที่แสดงให้เห็นถึงผลการเดิมพันแพ้หรือชนะ
                                                    ทางบริษัทจะยึดถือข้อมูล{" "}
                                                    <span className="text-primary">
                                                        "รายละเอียดการวางเดิมพัน"
                                                    </span>{" "}
                                                    ของสมาชิก ในกรณีที่สมาชิกมีความสงสัย
                                                    และต้องการตรวจสอบข้อมูลในการคำนวณของการเดิมพันนั้นๆ{" "}
                                                </li>
                                                <li >
                                                    ถ้าหากเกิดความผิดพลาดของระบบระหว่างการเดิมพันหรือความผิดพลาดโดยไม่ได้เจตนาของพนักงาน
                                                    ทางบริษัทขอสงวนสิทธ์ที่จะแก้ไขผลลัพธ์ให้ถูกต้อง
                                                    และจะมีการพิมพ์ข้อความที่ได้มีการแก้ไขเผยแพร่ไว้ที่แถบตัววิ่งในหน้าเว็บไซต์
                                                    บริษัทจะไม่มีการแจ้งสมาชิกเป็นการส่วนตัว{" "}
                                                </li>
                                                <li >
                                                    เป็นความรับชอบของสมาชิกที่จะทำให้แน่ใจว่าชื่อผู้ใช้และรหัสผ่านสำหรับเข้าเว็บไซต์นั้นถูกต้อง
                                                    และกรุณาเปลี่ยนรหัสผ่านของสมาชิกอย่างน้อยเดือนละครั้ง
                                                    เพื่อความปลอดภัย
                                                    ถ้าหากท่านพบหรือสงสัยว่ามีผู้อื่นได้เข้าไปเล่นในบัญชีของท่านโดยไม่รับอนุญาต
                                                    กรุณา แจ้งตัวแทนของท่านทันทีเพื่อเปลี่ยนรหัสส่วนตัว
                                                    (หากมีการเดิมพันก่อนที่จะเปลี่ยนรหัสเดิมจะถือว่าการเดิมพันนั้นเป็นผล){" "}
                                                </li>
                                                <li >
                                                    ก่อนเริ่มการเดิมพันแต่ละครั้ง
                                                    ท่านสมาชิกควรจะตรวจสอบวงเงินของท่านเสียก่อน
                                                    ถ้าหากท่านมีข้อสงสัยใดๆ เกี่ยวกับวงเงิน
                                                    กรุณาแจ้งตัวแทนของท่านสมาชิกทันที{" "}
                                                </li>
                                                <li >
                                                    ในกรณีที่เหตุการณ์ไม่คาดคิดเกิดขึ้น อย่างเช่น
                                                    ข้อมูลสูญหายเนื่องจากระบบอินเตอร์เน็ตขัดข้อง
                                                    ทางบริษัทจะมีการประกาศให้รู้ถึงสาเหตุ และวิธีการแก้ปัญหา{" "}
                                                </li>
                                                <li >
                                                    ชั่วโมงการให้บริการ 24 ชั่วโมง/วัน และไม่มีวันหยุด
                                                </li>
                                                <li >
                                                    กรณีตรวจพบการใช้ตั้งแต่ 1 ยูสเซอร์ขึ้นไปเพื่อแทงทบ, แทงหวย 2
                                                    ตัว เกิน 50 หมายเลข/แทงหวย 3 ตัว เกิน 250 หมายเลข
                                                    ต่อหวยงวดนั้นๆ ไม่ว่าจะแยกหรือรวมบิล
                                                    หรือทางบริษัทตัดสินแล้วว่าการเดิมพันผิดปกติ
                                                    ทางบริษัทมีสิทธิ์ในการระงับการใช้งานและยึดเครดิตของสมาชิกโดยไม่ต้องแจ้งล่วงหน้า
                                                </li>
                                                <li >
                                                    คำตัดสินของบริษัทถือเป็นที่สิ้นสุด
                                                </li>
                                            </ul>
                                            <p />
                                            <p >
                                                <strong >
                                                    <u style={{ color: "#3b5898" }}>
                                                        ระบบการรักษาความปลอดภัย
                                                    </u>
                                                </strong>
                                                <br />
                                            </p>
                                            <ul className="pl-3">
                                                <li >
                                                    ข้อมูลทางด้านบัญชีเพื่อวัตถุประสงค์เฉพาะบุคคล
                                                    ข้อมูลที่ทางสมาชิกให้มาในการเปิดบัญชีจะถูกเก็บเป็นความลับขั้นสูงสุด
                                                    ทางบริษัทจะไม่เผยแพร่ข้อมูลนี้ให้กับบุคคลที่สามหรือองค์กรอื่นๆ
                                                    ล่วงรู้ ไม่ว่าจะเป็นบัญชีอีเมล์ หรือข้อมูลอื่นๆ
                                                    ที่ทางสมาชิกกรอกในใบสมัคร
                                                    ข้อมูลเหล่านี้จะใช้ในจุดมุ่งหมายที่ตั้งใจเอาไว้เท่านั้น เช่น
                                                    การส่งเช็คเงินสดสำหรับผู้ชนะหรือข้อมูลอื่นๆ
                                                    ตามที่สมาชิกร้องขอ{" "}
                                                </li>
                                                <li >
                                                    {" "}
                                                    ความปลอดภัยทางบริษัทได้ใช้วิธีที่มีประสิทธิภาพมากที่สุดเพื่อทำให้ท่านแน่ใจในความปลอดภัยของข้อมูลของท่าน
                                                    ทางเราจะพยายามให้ดีที่สุดที่จะรักษาความถูกต้องแน่นอน
                                                    และความลับเฉพาะของข้อมูล
                                                    เพื่อป้องกันไม่ให้ข้อมูลรั่วไหลหรือนำไปใช้อย่างไม่ถูกต้อง
                                                    ถ้าท่านมีข้อสงสัยใดๆ ที่เกี่ยวข้องกับการรักษาความลับ
                                                    ความปลอดภัยของข้อมูล กรุณาติดต่อฝ่ายบริการลูกค้า
                                                    พวกเรายินดีให้บริการ 24 ชั่วโมง/วัน ทุกวันไม่มีวันหยุด{" "}
                                                </li>
                                            </ul>
                                            <p />
                                            <p >
                                                บริษัทมีความยินดีเป็นอย่างยิ่งที่ได้รับใช้ท่าน
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ฉันเข้าใจและยอมรับ
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div

                                id="ModalRate"
                                tabIndex={-1}
                                role="dialog"
                                aria-labelledby="ModalRate"
                                aria-hidden="true"
                                className="modal fade"
                            >
                                <div

                                    role="document"
                                    className="modal-dialog modal-lg"
                                >
                                    <div

                                        className="modal-content"
                                        style={{ borderRadius: 10 }}
                                    >
                                        <div

                                            className="modal-header bg-theme-primary"
                                        >
                                            <span className="modal-title">
                                                อัตราการจ่าย
                                            </span>
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                className="text-white close"
                                            >
                                                <i className="far fa-times" />
                                            </button>
                                        </div>
                                        <div

                                            className="modal-body text-center ng-star-inserted"
                                        >
                                            <img

                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                    height: "inherit !important"
                                                }}
                                                src="https://s3.365huay.net/banner/TAOKAELOTTO/1696683195283.jpg"
                                            />
                                            <br />
                                        </div>


                                        <div className="modal-footer">
                                            <button

                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-theme-primary"
                                                style={{
                                                    width: "-webkit-fill-available",
                                                    boxShadow: "-1px 2px 5px black"
                                                }}
                                            >
                                                ปิด
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav id="sidebar" hidden={!this.state.loginstate}>
                        <div id="dismiss">
                            <i

                                className="fas fa-backspace fa-flip-horizontal"
                            />
                        </div>
                        <div className="sidebar-header">
                            <div className="align-center text-center">
                                <img

                                    src="/build/web/igame-index-lobby-wm/img/user-128.png"
                                    alt="หวยฮานอย"
                                    width={110}
                                    height={100}

                                    className="rounded-circle"
                                />
                                <br />
                                <span className="username choke-font-size" />
                                <br />
                                <i className="fas fa-coins" />
                                <span

                                    data-id="credit_balance"
                                    className="badge badge-pill badge-danger thb"
                                    style={{ marginLeft: 5, border: "3px solid #016F62" }}
                                >
                                    {" "}
                                    ฿
                                </span>
                            </div>
                        </div>
                        <ul className="list-unstyled components">
                            <li >
                                <a

                                    onClick={(e) => {
                                        this.checkurl(e, "/setting-profile")
                                    }} role="button"


                                >
                                    <i className="fas fa-user-cog" />{" "}
                                    ตั้งค่าบัญชีผู้ใช้
                                </a>
                            </li>
                            <li >
                                <a

                                    onClick={(e) => {
                                        this.checkurl(e, "/setting-bank")
                                    }} role="button"

                                >
                                    <i className="fas fa-money-check" />{" "}
                                    บัญชีธนาคาร
                                </a>
                            </li>
                            <li >
                                <a onClick={(e) => {
                                    this.checkurl(e, "/howto")
                                }} role="button">
                                    <i className="fas fa-info-circle" />{" "}
                                    วิธีใช้งาน
                                </a>
                            </li>
                            <li >
                                <a

                                    href="#"
                                    data-toggle="modal"
                                    data-target="#contactbox"
                                >
                                    <i className="fas fa-handshake" />{" "}
                                    ศูนย์ช่วยเหลือ
                                </a>
                            </li>
                        </ul>
                        <ul className="list-unstyled CTAs">
                            <li >
                                <a

                                    href="#"
                                    className="logout"
                                >
                                    <i className="fas fa-sign-out-alt" />{" "}
                                    ออกจากระบบ{" "}
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="sticky-top" hidden={!this.state.loginstate} style={{}}>
                        <div className="topnavbar">
                            <div className="container px-0">
                                <div

                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <div className="d-flex align-items-center">
                                        <a

                                            onClick={(e) => {
                                                this.checkurl(e, "/")
                                            }} role="button"

                                            title="Lotto888"
                                        >
                                            <div className="memberlogo">
                                                <div className="logomember">
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                        id="logofull"
                                                        className="pc-view"
                                                        alt="Lotto888"
                                                        title="Lotto888"
                                                    />
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                        id="logosymbol"
                                                        className="mobile-view ml-3"
                                                        alt="Lotto888"
                                                        title="Lotto888"
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div

                                        className="d-flex flex-row justify-content-between align-items-center"
                                    >
                                        <div

                                            id="menu-pc"
                                            className="d-flex justify-content-between align-items-center pc-view"
                                        >
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/")
                                                }} role="button"

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="lotto"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                หน้าแรก{" "}
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/deposit")
                                                }}

                                                role='button'

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="refill"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                ฝากเงิน{" "}
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/lotto")
                                                }} role="button"

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="lottery"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                แทงหวย{" "}
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/result-all")
                                                }}
                                                role="button"

                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                data-id="award"
                                                data-original-title=""
                                                title=""
                                            >
                                                {" "}
                                                ผลหวย{" "}
                                            </a>
                                            <a

                                                onClick={(e) => {
                                                    this.checkurl(e, "/game-sl")
                                                }}
                                                role="button"

                                                title=""
                                            >
                                                {" "}
                                                เกมส์{" "}
                                            </a>
                                        </div>
                                        <div

                                            className="d-flex flex-row bg-card-head justify-content-between align-items-center text-dark mr-2"
                                        >
                                            <div

                                                className="col-9"
                                                style={{ alignSelf: "center" }}
                                            >
                                                <div

                                                    className="row px-2 py-1 username-text-head"
                                                >
                                                    <i

                                                        className="fas fa-user mr-1 align-self-center"
                                                        style={{ fontSize: "0.6rem" }}
                                                    />{" "}
                                                </div>
                                                <div

                                                    className="row py-1 px-2"
                                                    style={{
                                                        background: "white",
                                                        fontSize: "1rem",
                                                        fontWeight: 600,
                                                        borderRadius: "0px 0px 0px 5px"
                                                    }}
                                                >
                                                    <img

                                                        src="/build/web/igame-index-lobby-wm/img/coin-stack.svg"
                                                        className="pr-1"
                                                    />
                                                </div>
                                            </div>
                                            <div

                                                className="col-3 px-1 align-self-center image-btn-effect2"
                                            >
                                                <div className="dropdown">
                                                    <div

                                                        id="menu-profilex"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="true"
                                                        className="p-0"
                                                    >
                                                        <img

                                                            src="/build/web/igame-index-lobby-wm/img/settings-outline-badged.svg"
                                                            style={{ height: 23, margin: 3 }}
                                                        />
                                                    </div>
                                                    <div

                                                        aria-labelledby="menu-profilex"
                                                        id="menu-profile-dropdown"
                                                        className="dropdown-menu"
                                                    >
                                                        <a

                                                            onClick={(e) => {
                                                                this.checkurl(e, "/setting-profile")
                                                            }} role="button"


                                                            className="dropdown-item"
                                                        >
                                                            <i className="fas fa-user-cog" />{" "}
                                                            ตั้งค่าบัญชีผู้ใช้{" "}
                                                        </a>
                                                        <a

                                                            onClick={(e) => {
                                                                this.checkurl(e, "/setting-bank")
                                                            }} role="button"

                                                            className="dropdown-item"
                                                        >
                                                            <i

                                                                className="fas fa-money-check"
                                                            />{" "}
                                                            บัญชีธนาคาร{" "}
                                                        </a>
                                                        <a

                                                            href="/howto"

                                                            className="dropdown-item"
                                                        >
                                                            <i

                                                                className="fas fa-info-circle"
                                                            />{" "}
                                                            วิธีใช้งาน{" "}
                                                        </a>
                                                        <a

                                                            href="#"
                                                            data-toggle="modal"
                                                            data-target="#contactbox"
                                                            className="dropdown-item"
                                                        >
                                                            <i

                                                                className="fas fa-handshake"
                                                            />{" "}
                                                            ศูนย์ช่วยเหลือ{" "}
                                                        </a>
                                                        <div

                                                            className="dropdown-divider"
                                                        />
                                                        <a

                                                            href="#"
                                                            className="dropdown-item"
                                                        >
                                                            <i

                                                                className="fas fa-sign-out-alt"
                                                            />{" "}
                                                            ออกจากระบบ{" "}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-outlet />
                    <app-register className="ng-star-inserted"  >
                        <div className="container">
                            <section id="contentbox" className="h-100 mb-5">
                                <div

                                    noValidate=""
                                    className="form-login form-horizontal ng-invalid ng-dirty ng-touched"
                                    data-gtm-form-interact-id={1}
                                >
                                    <div className="bg-theme-secondary border border-secondary rounded px-3 py-2 mt-2">
                                        <div className="row">
                                            <div

                                                className="col-12 col-sm-12 col-md-6"
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <div className="indexlogo pc-view">
                                                    <div className="logoindex">
                                                        <a href="/" >
                                                            <img

                                                                src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                                style={{ maxHeight: 60, width: "auto" }}
                                                                alt="lotto888"
                                                                title="lotto888"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/logo.png"
                                                    className="mobile-view register-logo-mobile text-center"
                                                    alt="lotto888"
                                                    title="lotto888"
                                                />
                                            </div>
                                            <div

                                                className="col-12 col-sm-12 col-md-6 text-center text-sm-center text-md-right"
                                            >
                                                <h2

                                                    className="font-weight-light text-white register-word"
                                                >
                                                    สมัครสมาชิก
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-banner-top my-2">
                                        <Slider {...settings}>
                                            <div
                                                data-slick-index={-1}
                                                aria-hidden="true"
                                                tabIndex={-1}
                                                className=" -slide-inner-wrapper  -slick-item slick-slide slick-cloned banner-colosal ng-star-inserted"
                                            >
                                                <img

                                                    alt="ผลหวยฮานอย vip พิเศษ"
                                                    className="d-block w-100 border-banner-register"
                                                    src="https://cdn.javisx.com/image/d05ca367-9209-43bc-a372-cb23f5fb5f9b.jpg"
                                                />
                                            </div>
                                            <div
                                                data-slick-index={-2}
                                                aria-hidden="true"
                                                tabIndex={-2}
                                                className=" -slide-inner-wrapper  -slick-item slick-slide slick-cloned banner-colosal ng-star-inserted"
                                            >
                                                <img

                                                    alt="ผลหวยฮานอย vip พิเศษ"
                                                    className="d-block w-100 border-banner-register"
                                                    src="https://cdn.javisx.com/image/ed032646-2f7d-4cb9-83ba-9c2e7505cd55.jpg"
                                                />
                                            </div>
                                            <div
                                                data-slick-index={-3}
                                                aria-hidden="true"
                                                tabIndex={-3}
                                                className=" -slide-inner-wrapper  -slick-item slick-slide slick-cloned banner-colosal ng-star-inserted"
                                            >
                                                <img

                                                    alt="ผลหวยฮานอย vip พิเศษ"
                                                    className="d-block w-100 border-banner-register"
                                                    src="https://cdn.javisx.com/image/347caed9-c446-42ec-9b03-a1bff067ec28.jpg"
                                                />
                                            </div>

                                        </Slider >
                                    </div>
                                    <div className="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2 mb-5"
                                        style={{ display: tabregis == 0 ? "" : "none" }}
                                    >
                                        <div

                                            className="col-12"
                                            style={{ textAlign: "-webkit-center", position: "sticky" }}
                                        >
                                            <a

                                                target="_blank"
                                                className="mobile-view"
                                                href={this.state.linelink}
                                            >
                                                <button

                                                    type="button"
                                                    className="btn btn-success btn-block"
                                                    style={{
                                                        width: "-webkit-fill-available",
                                                        boxShadow: "-1px 2px 5px black",
                                                        borderRadius: 10
                                                    }}
                                                >
                                                    <i className="fas fa-user-plus" />{" "}
                                                    สมัครผ่านแอดมิน{" "}
                                                </button>
                                            </a>
                                            <a

                                                target="_blank"
                                                className="pc-view"
                                                href={this.state.linelink}
                                            >
                                                <button

                                                    type="button"
                                                    className="btn btn-success btn-block"
                                                    style={{
                                                        width: "50%",
                                                        boxShadow: "-1px 2px 5px black",
                                                        borderRadius: 10
                                                    }}
                                                >
                                                    <i className="fas fa-user-plus" />{" "}
                                                    สมัครผ่านแอดมิน{" "}
                                                </button>
                                            </a>
                                            <br />
                                            <div className="strike-center">
                                                <span className="--content">
                                                    หรือ
                                                </span>
                                            </div>
                                            <br />
                                        </div>
                                        <h5 className="text-theme-primary">
                                            <span

                                                className="badge badge-pill badge-theme-primary font-weight-light"
                                            >
                                                STEP 1
                                            </span>{" "}
                                            ระบุเบอร์โทรศัพท์{" "}
                                        </h5>
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <h6 className="text-dark">
                                                    <i className="fas fa-mobile-alt" />{" "}
                                                    ลงทะเบียนเบอร์โทรศัพท์
                                                </h6>
                                            </div>
                                            <div

                                                className="col-4 col-sm-4 col-md-2 d-flex align-items-center justify-content-md-end"
                                            >
                                                <span

                                                    className="flag-icon flag-icon-th mr-1"
                                                />{" "}
                                                +66{" "}
                                            </div>
                                            <div className="col-8 col-sm-8 col-md-4">
                                                <input
                                                    onChange={this.onChangex}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    pattern="^0[6,8,9][0-9]{8}$"
                                                    type='number'
                                                    maxLength={10}
                                                    required=""
                                                    placeholder="ใส่เบอร์โทรศัพท์"
                                                    className="form-control telinput ng-dirty ng-valid ng-touched"
                                                    data-gtm-form-interact-field-id={2}
                                                />
                                            </div>
                                            <div

                                                className="col-12 col-sm-12 col-md-6 text-right text-sm-right text-md-left"
                                            >
                                                <small className="text-secondary">
                                                    กรุณาใส่หมายเลขโทรศัพท์เพื่อยืนยันตัวตน
                                                </small>
                                            </div>
                                            <div

                                                className="col-12 border-bottom my-3"
                                            />
                                            <div className="col-12">
                                                <h6 className="text-dark">
                                                    <i className="fas fa-shield-alt" />{" "}
                                                    กรุณากรอกอักขระ 4 ตัวในภาพ
                                                </h6>
                                            </div>
                                            <div

                                                className="col-12 col-sm-12 col-md-3 text-center text-sm-center text-md-right mt-2"
                                            >
                                                <div>
                                                    <LoadCanvasTemplate />
                                                </div>

                                            </div>
                                            <div

                                                className="col-12 col-sm-12 col-md-4 mt-2"
                                            >
                                                <input
                                                    type="text"
                                                    onChange={this.onChangex}
                                                    value={this.state.user_captcha_input}
                                                    pattern="^[0-9a-z]{4}$"
                                                    id="user_captcha_input"
                                                    name="user_captcha_input"
                                                    maxLength={4}
                                                    placeholder="กรุณากรอกอักขระ 4 ตัวในภาพ"
                                                    className="form-control mb-3 ng-dirty ng-valid ng-touched"
                                                    data-gtm-form-interact-field-id={4}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2 mb-5"
                                        style={{ display: tabregis == 1 ? "" : "none" }}
                                    >
                                        <h5 className="text-theme-primary">
                                            <span

                                                className="badge badge-pill badge-theme-primary font-weight-light"
                                            >
                                                STEP 2
                                            </span>{" "}
                                            ยืนยันเบอร์โทรศัพท์{" "}
                                        </h5>
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <h6 className="text-dark">
                                                    <i className="fas fa-sms" />{" "}
                                                    โปรดนำเลข ที่ได้รับจาก sms มากรอกในช่องว่าง
                                                </h6>
                                                <small
                                                    className="text-danger mx-1 ng-star-inserted"
                                                >
                                                    ขอรหัส OTP ได้อีกครั้งใน 5 นาที
                                                </small>

                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <div className="group__keyboard">
                                                    <div

                                                        className="num-pad box__show-number"
                                                    >
                                                        <div

                                                            className="label-number lists"
                                                        >
                                                            <label className="number" >

                                                                {this.state.otp1 !== "" ? this.state.otp1 : <span hidden={false} />}
                                                            </label>
                                                            <label className="number" >
                                                                {this.state.otp2 !== "" || this.state.otp1 === "" ? this.state.otp2 : <span hidden={false} />}
                                                            </label>
                                                            <label className="number" >
                                                                {this.state.otp3 !== "" || this.state.otp2 === "" ? this.state.otp3 : <span hidden={false} />}
                                                            </label>
                                                            <label className="number">
                                                                {this.state.otp4 !== "" || this.state.otp3 === "" ? this.state.otp4 : <span hidden={false} />}
                                                            </label>
                                                        </div>
                                                        <div

                                                            className="key-pad box__keyboard"
                                                        >
                                                            <div className="row p-2">
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 1)
                                                                        }}
                                                                        type="button"
                                                                        data-id={1}
                                                                        id="otp_click_1"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        1
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 2)
                                                                        }}
                                                                        type="button"
                                                                        data-id={2}
                                                                        id="otp_click_2"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        2
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 3)
                                                                        }}
                                                                        type="button"
                                                                        data-id={3}
                                                                        id="otp_click_3"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        3
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a

                                                                        type="button"
                                                                        data-id="delete"
                                                                        className="btn btn-theme-back-space btn-block"
                                                                    >
                                                                        <i

                                                                            className="fas fa-backspace"
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 4)
                                                                        }}
                                                                        type="button"
                                                                        data-id={4}
                                                                        id="otp_click_4"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        4
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 4)
                                                                        }}
                                                                        type="button"
                                                                        data-id={5}
                                                                        id="otp_click_5"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        5
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 6)
                                                                        }}
                                                                        type="button"
                                                                        data-id={6}
                                                                        id="otp_click_6"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        6
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a

                                                                        type="button"
                                                                        data-id="clear"
                                                                        className="btn btn-theme-cancel-last btn-block btn-reset"
                                                                    >
                                                                        <div className="pc-view">
                                                                            ล้างข้อมูล
                                                                        </div>
                                                                        <div

                                                                            className="mobile-view"
                                                                            style={{ fontSize: "large" }}
                                                                        >
                                                                            ล้างข้อมูล
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 7)
                                                                        }}
                                                                        type="button"
                                                                        data-id={7}
                                                                        id="otp_click_7"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        7
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 8)
                                                                        }}
                                                                        type="button"
                                                                        data-id={8}
                                                                        id="otp_click_8"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        8
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 9)
                                                                        }}
                                                                        type="button"
                                                                        data-id={9}
                                                                        id="otp_click_9"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        9
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <a

                                                                        type="button"
                                                                        data-id="requestOtpBtn"
                                                                        className="btn btn-theme-clear-all btn-block btn-reset"
                                                                        disabled=""
                                                                    >
                                                                        <div className="pc-view">
                                                                            ส่งรหัส OTP
                                                                        </div>
                                                                        <div

                                                                            className="mobile-view"
                                                                            style={{ fontSize: "large" }}
                                                                        >
                                                                            ส่งรหัส OTP
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <button

                                                                        disabled=""
                                                                        className="btn btn-blank"
                                                                    />
                                                                </div>
                                                                <div className="col-3">
                                                                    <a
                                                                        onClick={(e) => {
                                                                            this.cfotp(e, 0)
                                                                        }}
                                                                        type="button"
                                                                        data-id={0}
                                                                        id="otp_click_0"
                                                                        className="btn btn-theme-number-pad btn-block"
                                                                    >
                                                                        0
                                                                    </a>
                                                                </div>
                                                                <div className="col-3">
                                                                    <button

                                                                        disabled=""
                                                                        className="btn btn-blank"
                                                                    />
                                                                </div>
                                                                <div className="col-3" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div

                                                className="col-12 col-sm-12 text-center"
                                            >
                                                <small

                                                    className="text-secondary"
                                                    style={{ display: "inline-block", lineHeight: "1.2" }}
                                                >
                                                    กรุณาใส่หมายเลขที่ระบบได้ส่งไปยังเบอร์โทรศัพท์ของท่านภายใน 5
                                                    นาที
                                                </small>
                                            </div>
                                            <div

                                                className="col-12 border-bottom my-3"
                                            />
                                            <div className="col-6 text-right px-0">
                                                <small >ส่งรหัสไปยังเบอร์</small>
                                                <h6 className="text-danger">
                                                    <i className="fas fa-mobile-alt" />{" "}
                                                    {this.state.phoneNumber}
                                                </h6>
                                            </div>
                                            <div className="col-6 text-left px-2">
                                                <a

                                                    type="button"
                                                    className="btn btn-outline-dark btn-sm mt-2 resend-otp"
                                                    disabled=""
                                                >
                                                    <i className="fas fa-sync-alt" />{" "}
                                                    ส่งรหัส OTP
                                                </a>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2 mb-5"
                                        style={{ display: tabregis == 2 ? "" : "none" }}
                                    >
                                        <h5 className="text-theme-primary">
                                            <span

                                                className="badge badge-pill badge-theme-primary font-weight-light"
                                            >
                                                STEP 3
                                            </span>{" "}
                                            สร้างบัญชี{" "}
                                        </h5>
                                        <div className="row">
                                            <div

                                                className="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center"
                                            >
                                                <b >ชื่อผู้ใช้งาน</b>
                                            </div>
                                            <div

                                                id="username"
                                                className="col-12 col-sm-12 col-md-9 mb-2"
                                            >
                                                <input

                                                    type="text"
                                                    readOnly={true}
                                                    name="username"
                                                    placeholder="username (0-9,a-z)"
                                                    pattern="[a-z0-9]+"
                                                    value={this.state.phoneNumber}
                                                    required=""
                                                    minLength={6}
                                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                                />

                                            </div>
                                            <div

                                                className="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center"
                                            >
                                                <b >รหัสผ่าน</b>
                                            </div>
                                            <div

                                                id="password"
                                                className="col-12 col-sm-12 col-md-9 mb-2"
                                            >
                                                <input
                                                    onChange={this.onChangex}
                                                    type={!this.state.showpass ? "text" : "password"}
                                                    id="password"
                                                    name="password"
                                                    placeholder="password"
                                                    required=""
                                                    minLength={4}
                                                    className="form-control ng-dirty ng-valid ng-touched"
                                                    data-gtm-form-interact-field-id={3}
                                                />

                                            </div>
                                            <div

                                                className="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center"
                                            >
                                                <b >ยืนยันรหัสผ่าน</b>
                                            </div>
                                            <div

                                                id="repassword"
                                                className="col-12 col-sm-12 col-md-9 mb-2"
                                            >
                                                <input
                                                    onChange={this.onChangex}
                                                    type={!this.state.showpass ? "text" : "password"}
                                                    id="passwordcf"
                                                    name="passwordcf"
                                                    placeholder="กรอก password อีกครั้ง"
                                                    required=""
                                                    minLength={4}
                                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                                />

                                            </div>

                                            <div

                                                id="channelDiv"
                                                className="col-12 col-sm-12 col-md-9 mb-2"
                                                hidden={true}
                                            >
                                                <div className="dropdown bootstrap-select form-control border ng-untouched ng-pristine ng-valid">
                                                    <select

                                                        name="channelF"
                                                        id="channelF"
                                                        className="selectpicker form-control border ng-untouched ng-pristine ng-valid"
                                                        tabIndex={-98}
                                                    >
                                                        <option

                                                            value=""
                                                            disabled=""
                                                            selected=""
                                                        >
                                                            เลือกช่องทางที่รู้จักเว็บ
                                                        </option>

                                                    </select>
                                                    <button
                                                        type="button"
                                                        className="btn dropdown-toggle bs-placeholder btn-light"
                                                        data-toggle="dropdown"
                                                        role="button"
                                                        data-id="channelF"
                                                        title="เลือกช่องทางที่รู้จักเว็บ"
                                                    >
                                                        <div className="filter-option">
                                                            <div className="filter-option-inner">
                                                                เลือกช่องทางที่รู้จักเว็บ
                                                            </div>
                                                        </div>
                                                        &nbsp;
                                                        <span className="bs-caret">
                                                            <span className="caret" />
                                                        </span>
                                                    </button>
                                                    <div className="dropdown-menu " role="combobox">
                                                        <div
                                                            className="inner show"
                                                            role="listbox"
                                                            aria-expanded="false"
                                                            tabIndex={-1}
                                                        >
                                                            <ul className="dropdown-menu inner show" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2 mb-5"
                                        style={{ display: tabregis == 3 ? "" : "none" }}
                                    >
                                        <h5 className="text-theme-primary">
                                            <span

                                                className="badge badge-pill badge-theme-primary font-weight-light"
                                            >
                                                STEP 4
                                            </span>{" "}
                                            เพิ่มบัญชีธนาคาร {" "}
                                        </h5>
                                        <div className="row">
                                            <div
                                                hidden={false}
                                                className="p-2 w-100 bg-light main-content align-self-stretch"
                                            >
                                                <div
                                                    hidden={false}
                                                    className="bgwhitealpha text-theme-primary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0"
                                                >
                                                    <div
                                                        hidden={false}
                                                        className="lotto-title w-100 d-flex justify-content-between"
                                                    >
                                                        <div hidden={false} className="d-inline">
                                                            <h4
                                                                hidden={false}
                                                                className="mr-1 d-inline"
                                                                style={{ fontWeight: "bold" }}
                                                            >
                                                                <i hidden={false} className="fas fa-folder-plus" />{" "}
                                                                เพิ่มบัญชีธนาคาร
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    hidden={false}
                                                    className="bgwhitealpha text-theme-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-5 pb-0"
                                                >
                                                    <div
                                                        hidden={false}
                                                        noValidate=""
                                                        action="javascript:void(0);"
                                                        id="addbank"
                                                        className="w-100 ng-untouched ng-pristine ng-valid"
                                                    >
                                                        <div hidden={false} id="firsttime" style={{ display: "block" }}>
                                                            <div hidden={false} className="form-row">
                                                                <div hidden={false} className="col-12 col-sm-12 col-md-6">
                                                                    <label hidden={false}>
                                                                        <i hidden={false} className="fas fa-university" /> เลือกธนาคาร
                                                                    </label>
                                                                    <div hidden={false} className="border rounded mb-2">
                                                                        <select onChange={this.onChangex} name="checkboxacc" className="mat-select  form-control ng-tns-c68-1" id="checkboxacc">
                                                                            <option
                                                                                value={0}
                                                                                role="option"
                                                                                className="option mat-focus-indicator mat-active ng-tns-c68-1"
                                                                                id="option-0"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                            >

                                                                                <span className="option-text">กรุณาเลือกธนาคาร</span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={6}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-1"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{ backgroundColor: "rgb(19, 143, 45)", color: "rgb(255, 255, 255)" }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/kbank.svg"
                                                                                    />{" "}
                                                                                    KBANK : ธนาคารกสิกรไทย{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={5}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-2"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{ backgroundColor: "rgb(78, 46, 127)", color: "rgb(255, 255, 255)" }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/scb.svg"
                                                                                    />{" "}
                                                                                    SCB : ธนาคารไทยพาณิชย์{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={7}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-3"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{ backgroundColor: "rgb(30, 69, 152)", color: "rgb(255, 255, 255)" }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/bbl.svg"
                                                                                    />{" "}
                                                                                    BBL : ธนาคารกรุงเทพ{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={14}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-4"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{
                                                                                    backgroundColor: "rgb(27, 165, 225)",
                                                                                    color: "rgb(255, 255, 255)"
                                                                                }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/ktb.svg"
                                                                                    />{" "}
                                                                                    KTB : ธนาคารกรุงไทย{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={8}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-5"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{
                                                                                    backgroundColor: "rgb(254, 196, 59)",
                                                                                    color: "rgb(255, 255, 255)"
                                                                                }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/bay.svg"
                                                                                    />{" "}
                                                                                    BAY : ธนาคารกรุงศรีอยุธยา{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={11}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-6"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{
                                                                                    backgroundColor: "rgb(18, 121, 190)",
                                                                                    color: "rgb(255, 255, 255)"
                                                                                }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/ttb.svg"
                                                                                    />{" "}
                                                                                    TTB : ธนาคารทหารไทยธนชาต{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={15}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-7"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{
                                                                                    backgroundColor: "rgb(235, 25, 141)",
                                                                                    color: "rgb(255, 255, 255)"
                                                                                }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/gsb.svg"
                                                                                    />{" "}
                                                                                    GSB : ธนาคารออมสิน{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            {/* <option

                                                                                    role="option"
                                                                                    className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                    id="option-8"
                                                                                    tabIndex={0}
                                                                                    aria-disabled="false"
                                                                                    style={{ backgroundColor: "rgb(11, 57, 121)", color: "rgb(255, 255, 255)" }}
                                                                                >
                                                                                    <span className="option-text">
                                                                                        <img

                                                                                            with={20}
                                                                                            height={20}
                                                                                            src="assets/banks-logo/th/uob.svg"
                                                                                        />{" "}
                                                                                        UOB : ธนาคารยูโอบี{" "}
                                                                                    </span>
                                                                                    <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                                </option> */}
                                                                            {/* <option

                                                                                    role="option"
                                                                                    className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                    id="option-9"
                                                                                    tabIndex={0}
                                                                                    aria-disabled="false"
                                                                                    style={{ backgroundColor: "rgb(126, 47, 54)", color: "rgb(255, 255, 255)" }}
                                                                                >
                                                                                   
                                                                                    <span className="option-text">
                                                                                        <img

                                                                                            with={20}
                                                                                            height={20}
                                                                                            src="assets/banks-logo/th/cimb.svg"
                                                                                        />{" "}
                                                                                        CIMB : ธนาคารซีไอเอ็มบี{" "}
                                                                                    </span>
                                                                                    <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                                </option> */}
                                                                            {/* <option

                                                                                    role="option"
                                                                                    className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                    id="option-10"
                                                                                    tabIndex={0}
                                                                                    aria-disabled="false"
                                                                                    style={{ backgroundColor: "rgb(197, 15, 28)", color: "rgb(255, 255, 255)" }}
                                                                                >
                                                                                  
                                                                                    <span className="option-text">
                                                                                        <img

                                                                                            with={20}
                                                                                            height={20}
                                                                                            src="assets/banks-logo/th/icbc.svg"
                                                                                        />{" "}
                                                                                        ICBC : ธนาคารไอซีบีซี{" "}
                                                                                    </span>
                                                                                    <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                                </option> */}
                                                                            {/* <option

                                                                                    role="option"
                                                                                    className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                    id="option-11"
                                                                                    tabIndex={0}
                                                                                    aria-disabled="false"
                                                                                    style={{
                                                                                        backgroundColor: "rgb(21, 131, 199)",
                                                                                        color: "rgb(255, 255, 255)"
                                                                                    }}
                                                                                >
                                                                                   
                                                                                    <span className="option-text">
                                                                                        <img

                                                                                            with={20}
                                                                                            height={20}
                                                                                            src="assets/banks-logo/th/citi.svg"
                                                                                        />{" "}
                                                                                        CITI : ธนาคารซิตี้แบงก์{" "}
                                                                                    </span>
                                                                                    <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                                </option> */}
                                                                            <option
                                                                                value={9}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-12"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{
                                                                                    backgroundColor: "rgb(25, 156, 197)",
                                                                                    color: "rgb(255, 255, 255)"
                                                                                }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/kkp.svg"
                                                                                    />{" "}
                                                                                    KKP : ธนาคารเกียรตินาคิน{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={16}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-13"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{ backgroundColor: "rgb(75, 155, 29)", color: "rgb(255, 255, 255)" }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/baac.svg"
                                                                                    />{" "}
                                                                                    BAAC : ธนาคาร ธ.ก.ส{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            <option
                                                                                value={53}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-14"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{
                                                                                    backgroundColor: "rgb(245, 125, 35)",
                                                                                    color: "rgb(255, 255, 255)"
                                                                                }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/ghb.svg"
                                                                                    />{" "}
                                                                                    GHB : ธนาคารอาคารสงเคราะห์{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                            {/* <option

                                                                                    role="option"
                                                                                    className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                    id="option-15"
                                                                                    tabIndex={0}
                                                                                    aria-disabled="false"
                                                                                    style={{
                                                                                        backgroundColor: "rgb(122, 207, 227)",
                                                                                        color: "rgb(255, 255, 255)"
                                                                                    }}
                                                                                >
                                                                                   
                                                                                    <span className="option-text">
                                                                                        <img

                                                                                            with={20}
                                                                                            height={20}
                                                                                            src="assets/banks-logo/th/lhbank.svg"
                                                                                        />{" "}
                                                                                        LHBANK : ธนาคารแลนด์ แอนด์ เฮ้าส์{" "}
                                                                                    </span>
                                                                                    <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                                </option> */}
                                                                            <option
                                                                                value={99}
                                                                                role="option"
                                                                                className="option mat-focus-indicator ng-tns-c68-1 ng-star-inserted"
                                                                                id="option-16"
                                                                                tabIndex={0}
                                                                                aria-disabled="false"
                                                                                style={{
                                                                                    backgroundColor: "rgb(18, 121, 190)",
                                                                                    color: "rgb(255, 255, 255)"
                                                                                }}
                                                                            >

                                                                                <span className="option-text">
                                                                                    <img

                                                                                        with={20}
                                                                                        height={20}
                                                                                        src="assets/banks-logo/th/tsco.svg"
                                                                                    />{" "}
                                                                                    TRUE : ทรูวอลเลท{" "}
                                                                                </span>
                                                                                <div mat-ripple="" className="mat-ripple option-ripple" />
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div hidden={false} className="col-12 col-sm-12 col-md-6 mb-1">
                                                                    <label hidden={false}>
                                                                        <i hidden={false} className="fas fa-id-card" /> ชื่อบัญชี
                                                                    </label>
                                                                    <input
                                                                        onChange={this.onChangex}
                                                                        id="fname"
                                                                        value={this.state.fname}
                                                                        name="fname"
                                                                        placeholder="ชื่อ นามสกุล หน้าสมุดบัญชี"
                                                                        className="form-control ng-untouched ng-pristine ng-valid"
                                                                    />
                                                                </div>
                                                                <div hidden={false} className="col-12 col-sm-12 col-md-6 mb-1">
                                                                    <label hidden={false}>
                                                                        <i hidden={false} className="fas fa-clipboard-check" />{" "}
                                                                        เลขที่บัญชี
                                                                    </label>
                                                                    <input
                                                                        onChange={this.onChangex}
                                                                        id="accnumbertxt"
                                                                        inputMode="text"
                                                                        name="accnumbertxt"
                                                                        data-role="none" autoCapitalize="off" autoCorrect="off" autoComplete="off"
                                                                        type='number'
                                                                        placeholder="ระบุเฉพาะตัวเลข"
                                                                        maxLength={15}
                                                                        className="form-control bankinput ng-untouched ng-pristine ng-valid"
                                                                    />
                                                                    <small hidden={false} id="checkacc1" />
                                                                </div>
                                                                <div hidden={false} className="col-12 col-sm-12 col-md-6 mb-1">
                                                                    <label hidden={false}>
                                                                        <i hidden={false} className="fas fa-check-double" />{" "}
                                                                        ยืนยันเลขที่บัญชี
                                                                    </label>
                                                                    <input
                                                                        onChange={this.onChangex}
                                                                        id="accnumbertxtcf"
                                                                        inputMode="text"
                                                                        name="accnumbertxtcf"
                                                                        data-role="none" autoCapitalize="off" autoCorrect="off" autoComplete="off"
                                                                        type='number'
                                                                        placeholder="ยืนยันเลขที่บัญชีอีกครั้ง"
                                                                        maxLength={15}
                                                                        className="form-control bankinput ng-untouched ng-pristine ng-valid"
                                                                    />
                                                                    <small hidden={false} id="checkacc2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr hidden={false} />
                                                        <div hidden={false} className="row">
                                                            <div hidden={false} className="col pr-1">
                                                                <button
                                                                    hidden={false}
                                                                    type="button"
                                                                    className="btn btn-secondary btn-block"
                                                                >
                                                                    ยกเลิก
                                                                </button>
                                                            </div>
                                                            <div hidden={false} className="col pl-1">
                                                                <input
                                                                    onClick={(e) => {
                                                                        this.validatergisterForm(e)
                                                                    }}
                                                                    type="submit"
                                                                    defaultValue="เพิ่มบัญชี"
                                                                    className="btn btn-success btn-block"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="bg-secondary border-dark shadow-sm p-3 mt-2 mb-0 fixed-bottom ng-star-inserted"
                                        style={{ display: tabregis == 2 ? "" : "none" }}
                                    >
                                        <div className="row">
                                            <div className="col">
                                                <a

                                                    onClick={(e) => this.checkstepregister(e, 4)}
                                                    type="submit"
                                                    className="btn btn-primary btn-block"
                                                >
                                                    {" "}
                                                    &nbsp;&nbsp;&nbsp;ถัดไป{" "}
                                                    <i
                                                        className="fas fa-chevron-right"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-secondary border-dark shadow-sm p-3 mt-2 mb-0 fixed-bottom ng-star-inserted"
                                        style={{ display: tabregis == 1 ? "" : "none" }}
                                    >
                                        <div className="row">
                                            <div className="col">
                                                <a
                                                    onClick={(e) => this.checkstepregister(e, 3)}
                                                    type="button"
                                                    className="btn btn-warning btn-block"
                                                >
                                                    {" "}
                                                    ข้ามการยืนยัน{" "}
                                                    <i

                                                        className="fas fa-chevron-right"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col">
                                                <a
                                                    onClick={(e) => this.checkstepregister(e, 2)}
                                                    type="submit"
                                                    className="btn btn-primary btn-block"
                                                >
                                                    {" "}
                                                    &nbsp;&nbsp;&nbsp;ยืนยัน OTP{" "}
                                                    <i

                                                        className="fas fa-chevron-right"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-secondary border-dark shadow-sm p-3 mt-2 mb-0 fixed-bottom ng-star-inserted"
                                        style={{ display: tabregis == 0 ? "" : "none" }}
                                    >
                                        <div className="row">
                                            <div className="col">
                                                <a
                                                    href='/'
                                                    type="button"
                                                    className="btn btn-warning btn-block"
                                                >
                                                    {" "}
                                                    มีสมาชิกอยู่แล้ว{" "}
                                                    <i

                                                        className="fas fa-chevron-right"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col">
                                                <a
                                                    onClick={(e) => this.checkstepregister(e, 1)}
                                                    type="submit"
                                                    className="btn btn-block"
                                                    style={{ backgroundColor: "#28A745", color: "white" }}
                                                >
                                                    {" "}
                                                    &nbsp;&nbsp;&nbsp;ถัดไป{" "}
                                                    <i

                                                        className="fas fa-chevron-right"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </div>

                            </section>
                        </div>
                        <div

                            id="myModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModal"
                            aria-hidden="true"
                            className="modal fade"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-md"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div

                                        className="modal-header bg-theme-primary"
                                    >
                                        <h5 className="modal-title">
                                            อัปเดตล่าสุด
                                        </h5>
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="text-white close"
                                        >
                                            <i className="far fa-times" />
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">


                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                            style={{
                                                width: "-webkit-fill-available",
                                                boxShadow: "-1px 2px 5px black"
                                            }}
                                        >
                                            ปิด
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div

                            id="existModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="existModal"
                            aria-hidden="true"
                            className="modal fade"
                        >
                            <div

                                role="document"
                                className="modal-dialog modal-md"
                            >
                                <div

                                    className="modal-content"
                                    style={{ borderRadius: 10 }}
                                >
                                    <div

                                        className="modal-header bg-theme-primary"
                                    >
                                        <h5 className="modal-title">
                                            เบอร์นี้มีผู้ใช้งานแล้ว
                                        </h5>
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            className="text-white close"
                                        >
                                            <span aria-hidden="true">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-body pl-5">
                                        <p >
                                            {" "}
                                            เบอร์{" "}
                                            <span className="text-danger">
                                                0863352680
                                            </span>{" "}
                                            มีรหัสผู้ใช้งานแล้ว <br />
                                            <br /> กรุณาทำการสมัครด้วยเบอร์ใหม่ หรือ{" "}
                                            <br />
                                            <br />{" "}
                                            ทำการรีเซ็ตรหัสผ่านในกรณีที่ลืมรหัสผ่าน{" "}
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-theme-primary"
                                        >
                                            สมัครด้วยเบอร์ใหม่
                                        </button>
                                        <button

                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-warning"
                                        >
                                            ทำการรีเซ็ตรหัสผ่าน
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-spinner

                            bdcolor="rgba(0, 0, 0, 0.8)"
                            size="default"
                            color="#fff"
                            type="timer"
                            _nghost-wsd-c12=""
                            className="ng-tns-c12-1"
                        >

                        </ngx-spinner>
                    </app-register>

                    <div className="overlay" />
                    <div

                        id="contactbox"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="contact"
                        aria-hidden="true"
                        className="modal fade"
                    >
                        <div

                            role="document"
                            className="modal-dialog modal-lg"
                        >
                            <div

                                className="modal-content"
                                style={{ borderRadius: 10 }}
                            >
                                <div className="modal-header bg-theme-primary">
                                    <span className="modal-title">
                                        เลือกช่องทางการติดต่อเรา
                                    </span>
                                    <button

                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="text-white close"
                                    >
                                        <span aria-hidden="true">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div

                                            className="col-6 col-sm-6 col-md-6 ng-star-inserted"
                                            data-index={0}
                                        >
                                            <a

                                                className="btn btn-outline-success btn-block btn-contactbox mb-3"
                                                style={{ marginBottom: 0 }}
                                                href={this.state.linelink}
                                            >
                                                <span >
                                                    <small

                                                        className="d-flex justify-content-center"
                                                    >
                                                        ติดต่อผ่านไลน์ &nbsp;
                                                    </small>
                                                </span>
                                                <i className="fab fa-line" />
                                            </a>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-6">
                                            <a
                                                onClick={(e) => {
                                                    this.handleClickChatScript(e)
                                                }}
                                                role="button"
                                                className="btn btn-outline-danger btn-block btn-contactbox mb-0"
                                            >
                                                <span >
                                                    <small >แชทสดกับพนักงาน</small>
                                                </span>
                                                <i

                                                    className="fa fa-user"
                                                    style={{ fontSize: 46 }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button

                                        type="button"
                                        data-dismiss="modal"
                                        className="btn btn-theme-primary"
                                        style={{
                                            width: "-webkit-fill-available",
                                            boxShadow: "-1px 2px 5px black"
                                        }}
                                    >
                                        ปิด
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div

                        id="loginPopup"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="loginPopup"
                        className="modal fade"
                        style={{ display: "none" }}
                        aria-hidden="true"
                    >
                        <div

                            role="document"
                            className="modal-dialog modal-lg"
                        >
                            <div

                                className="modal-header pb-0 px-lg-4 px-0"
                                style={{ border: "none" }}
                            >
                                <button

                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="text-white close pb-3"
                                    style={{ opacity: 1 }}
                                >
                                    <span

                                        aria-hidden="true"
                                        style={{ fontSize: "larger" }}
                                    >
                                        <i className="far fa-times-circle" />
                                    </span>
                                </button>
                            </div>
                            <div

                                className="modal-content px-3 px-lg-5 py-1"
                                style={{
                                    borderRadius: 20,
                                    backgroundColor: "transparent",
                                    border: "none"
                                }}
                            >
                                <div className="modal-body bg-login-popup">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="col-12 text-center">
                                                <img

                                                    src="https://cdn.javisx.com/image/4c0ea74d-05a2-447e-a121-95cc85ce9eba.png"
                                                    className="logo-app-popup"
                                                    alt="Lotto888"
                                                    title="Lotto888"
                                                />
                                            </div>
                                            <div className="col-12 mt-2">
                                                <form

                                                    noValidate=""
                                                    id="login1"
                                                    autoComplete="off"
                                                    role="form"
                                                    acceptCharset="utf-8"
                                                    className="form-login form-horizontal ng-untouched ng-pristine ng-valid"
                                                >
                                                    <div className="row form-middle">
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 mt-2 px-4"
                                                        >
                                                            <div className="form-group mb-0">
                                                                <div

                                                                    className="dvImageTextBox"
                                                                >
                                                                    <img

                                                                        src="/build/web/igame-index-lobby-wm/img/telephone.svg"
                                                                    />
                                                                    <input

                                                                        id="username"
                                                                        type="number"
                                                                        name="username"
                                                                        inputMode="text"
                                                                        onChange={this.onChange}

                                                                        placeholder="เบอร์โทรศัพท์"
                                                                        className="form-control login-btn ng-untouched ng-pristine ng-valid"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 my-2 px-4"
                                                        >
                                                            <div className="form-group mb-0">
                                                                <div className="d-flex mb-0">
                                                                    <div

                                                                        className="dvImageTextBox box-pwd position-relative"
                                                                    >
                                                                        <img

                                                                            src="/build/web/igame-index-lobby-wm/img/lock.svg"
                                                                            className="lock-icon"
                                                                        />
                                                                        <input

                                                                            type="password"
                                                                            id="password"
                                                                            name="password"
                                                                            onChange={this.onChange}
                                                                            placeholder="รหัสผ่าน"
                                                                            required=""
                                                                            className="form-control ng-dirty ng-valid ng-touched"
                                                                            style={{
                                                                                paddingTop: 10,
                                                                                paddingBottom: 10,
                                                                                paddingLeft: 35
                                                                            }}
                                                                            data-gtm-form-interact-field-id={1}
                                                                        />
                                                                    </div>
                                                                    <div

                                                                        className="input-group-append"
                                                                    >
                                                                        <div

                                                                            className="input-group-text style-eye"
                                                                        >
                                                                            <i

                                                                                className="fa fa-eye-slash"
                                                                                style={{ cursor: "pointer" }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="col-12 custombglogin"
                                                            style={{ justifyContent: "center", display: "flex" }}
                                                        >
                                                            <div

                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                                className="rule"
                                                            >
                                                                <a

                                                                    className="m-2"
                                                                    style={{ fontSize: "large", lineHeight: 17 }}
                                                                >
                                                                    ลืมรหัสผ่าน
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 my-2 px-4"
                                                        >
                                                            <a
                                                                onClick={(e) => {
                                                                    this.login(e)
                                                                }}
                                                                type="submit"
                                                                className="btn btn-gradiant-login-m btn-block"
                                                            >
                                                                {" "}
                                                                เข้าสู่ระบบ{" "}
                                                            </a>
                                                        </div>
                                                        <div

                                                            className="col-12 p-1"
                                                            style={{ textAlign: "-webkit-center" }}
                                                        >
                                                            <div className="uline" />
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 my-2 px-4"
                                                        >
                                                            <a
                                                                onClick={(e) => {
                                                                    this.checkurl(e, "/register")
                                                                }}
                                                                type="button"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                                className="btn btn-login-white-popup btn-block"
                                                            >
                                                                {" "}
                                                                สมัครสมาชิก{" "}
                                                            </a>
                                                        </div>
                                                        <div

                                                            className="col-12 mx-0 mx-md-2 px-4 mb-2"
                                                        >
                                                            <a

                                                                target="_blank"
                                                                className="btn btn-login-register-admin-m btn-block"
                                                                href={this.state.linelink}
                                                            >
                                                                {" "}
                                                                สมัครสมาชิกผ่านแอดมิน{" "}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div

                                            className="col-12 col-md-6 align-self-center"
                                        >
                                            <div className="pc-view">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/login-popup-pc.png"
                                                    style={{ width: "100%", height: "auto", borderRadius: 10 }}
                                                />
                                            </div>
                                            <div className="mobile-view">
                                                <img

                                                    src="/build/web/igame-index-lobby-wm/img/login-popup-m.png"
                                                    style={{ width: "100%", height: "auto", borderRadius: 10 }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="page-footer font-small bg-danger pc-view" >
                        <div className="footer-copyright text-center py-2">
                            <div className="footer-text">
                                © 2024 Copyright - Lotto888
                            </div>
                        </div>
                    </footer>
                </div>

                <div

                    id="contactbox"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="contact"
                    className={`modal fade${this.state.showModalcontactbox ? ' show' : ''}`}
                    style={{
                        display: this.state.showModalcontactbox ? 'block' : 'none',
                        paddingRight: this.state.showModalcontactbox ? '10px' : '',
                    }}
                >
                    <div role="document" className="modal-dialog modal-lg">
                        <div

                            className="modal-content"
                            style={{ borderRadius: 10 }}
                        >
                            <div className="modal-header bg-theme-primary">
                                <span className="modal-title">
                                    เลือกช่องทางการติดต่อเรา
                                </span>
                                <button

                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="text-white close"
                                >
                                    <span aria-hidden="true">
                                        ×
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div

                                        className="col-6 col-sm-6 col-md-6 ng-star-inserted"
                                        data-index={0}
                                    >
                                        <a

                                            className="btn btn-outline-success btn-block btn-contactbox mb-3"
                                            style={{ marginBottom: 0 }}
                                            href={this.state.linelink}
                                        >
                                            <span   >
                                                <small

                                                    className="d-flex justify-content-center"
                                                >
                                                    ติดต่อผ่านไลน์ &nbsp;{/**/}
                                                </small>
                                            </span>
                                            <i className="fab fa-line" />
                                        </a>
                                    </div>
                                    {/**/}
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <a
                                            onClick={(e) => {
                                                this.handleClickChatScript(e)
                                            }}
                                            role="button"
                                            className="btn btn-outline-danger btn-block btn-contactbox mb-0"
                                        >
                                            <span   >
                                                <small   >แชทสดกับพนักงาน</small>
                                            </span>
                                            <i

                                                className="fa fa-user"
                                                style={{ fontSize: 46 }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button

                                    onClick={(e) => {
                                        this.setState({ showModalcontactbox: false });
                                    }} role="button"
                                    className="btn btn-theme-primary"
                                    style={{
                                        width: "-webkit-fill-available",
                                        boxShadow: "-1px 2px 5px black"
                                    }}
                                >
                                    ปิด
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${this.state.showModalPrice || this.state.showModalRule || this.state.showModalcontactbox ? 'modal-backdrop fade show' : ''}`}></div>
                {/* <div className={this.state.modalx ? "overlay active" : "overlay "}></div> */}
            </>
        );
    }
}

export default Main;
