import React, { Component } from 'react'; //different
import './App.css';
import Carousel from './carousel';
class App extends Component {


    onClick = (e) => {
        e.preventDefault()
        ////console.log('onClick..')
    }
    render() {
        return (
            <>
                <div
                style={{zIndex:"-99999999999999"}}
                    data-elementor-type="wp-page"
                    data-elementor-id={1198}
                    className="elementor elementor-1198"
                >
                    <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-02c5561 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="02c5561"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                    >
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-710fcd5"
                                data-id="710fcd5"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-ccafdf7 elementor-widget elementor-widget-image"
                                        data-id="ccafdf7"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={806}
                                                height={806}
                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red.png"
                                                className="attachment-large size-large"
                                                alt=""
                                                srcSet="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red.png 806w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-300x300.png 300w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-150x150.png 150w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-768x768.png 768w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-24x24.png 24w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-36x36.png 36w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/Calendar_corner_red-48x48.png 48w"
                                                sizes="(max-width: 806px) 100vw, 806px"
                                            />{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f130389"
                                data-id="f130389"
                                data-element_type="column"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-5ad0a04 elementor-widget elementor-widget-site-logo"
                                        data-id="5ad0a04"
                                        data-element_type="widget"
                                        data-settings='{"width":{"unit":"px","size":334,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                        data-widget_type="site-logo.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="hfe-site-logo">
                                                <a
                                                    data-elementor-open-lightbox=""
                                                    className="elementor-clickable"
                                                    href="https://idea-bet.live/theme/harem789"
                                                >
                                                    <div className="hfe-site-logo-set">
                                                        <div className="hfe-site-logo-container">
                                                            <img
                                                                decoding="async"
                                                                className="hfe-site-logo-img elementor-animation-"
                                                                src="https://cdn.javisx.com/image/61fecf50-99ec-498e-9508-3080597757f8.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-7cac728 elementor-widget elementor-widget-heading"
                                        data-id="7cac728"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                สนุก และ คว้าชัยชนะได้ทุกที่ทุกเวลา
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-6c6b4fc elementor-widget elementor-widget-text-editor"
                                        data-id="6c6b4fc"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <p>
                                                ให้บริการความสนุกไม่รู้จบ 24 ชม. ไปกับดีลเลอร์ “DEALER” สาวสวย
                                                สุดเซ็กซี่ คอยแจกไพ่ ให้คุณได้ลุ้นทุกเกมส์เดิมพัน สบายตา สบายใจ
                                                ด้วย สาวๆ น่ารัก สุดเซ็กซี่ มาใส่บิกินี่ แต่ละสีแต่ละวันสีสด
                                                มาแจกไพ่ ท่านจะได้รับประสบการณ์ใหม่ในการเดิมพัน
                                            </p>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-97f8a74 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="97f8a74"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                    >
                        <div className="elementor-background-overlay" />
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4992347"
                                data-id={4992347}
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-8b4c08e elementor-widget elementor-widget-image"
                                        data-id="8b4c08e"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={752}
                                                height={95}
                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min.png"
                                                className="attachment-large size-large"
                                                alt=""
                                                srcSet="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min.png 752w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-300x38.png 300w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-24x3.png 24w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-36x5.png 36w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/imgtext-image-home-min-48x6.png 48w"
                                                sizes="(max-width: 752px) 100vw, 752px"
                                            />{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-def9de3 elementor-widget elementor-widget-image"
                                        data-id="def9de3"
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={768}
                                                height={315}
                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-768x315.png"
                                                className="attachment-medium_large size-medium_large"
                                                alt=""
                                                srcSet="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-768x315.png 768w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-300x123.png 300w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-24x10.png 24w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-36x15.png 36w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1-48x20.png 48w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/สล็อต-PG-slot-all-666sx-1024x420-1.png 1024w"
                                                sizes="(max-width: 768px) 100vw, 768px"
                                            />{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-fb42a19 elementor-widget elementor-widget-heading"
                                        data-id="fb42a19"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                เว็บอันดับหนึ่ง Luca169th
                                                ที่รวมสาวสวยมากมายให้ท่านได้ตื่นตาตื่นใจ บาคาร่าออนไลน์ที่
                                                เย้ายวนใจที่สุดในตอนนี้ หรือ ที่รู้จักกันว่า Sexy Baccarat, Sexy
                                                Gaming, เอ็มจีเอ็มห้าจี เซ็กซี่ หรือ เซ็กซี่เกมส์
                                                เอ็มจีเอ็มห้าจี
                                            </h2>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-908289f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="908289f"
                        data-element_type="section"
                        data-settings='{"background_background":"gradient"}'
                    >
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-01e41b9"
                                data-id="01e41b9"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
                                    <div
                                        className="elementor-element elementor-element-82bf8e2 elementor-widget elementor-widget-image-carousel e-widget-swiper"
                                        data-id="82bf8e2"
                                        data-element_type="widget"
                                        data-settings='{"slides_to_show":"8","slides_to_show_tablet":"5","slides_to_show_mobile":"4","navigation":"none","autoplay_speed":1000,"image_spacing_custom":{"unit":"px","size":25,"sizes":[]},"autoplay":"yes","pause_on_hover":"yes","pause_on_interaction":"yes","infinite":"yes","speed":500}'
                                        data-widget_type="image-carousel.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div
                                                className="elementor-image-carousel-wrapper swiper-container swiper-container-initialized swiper-container-horizontal"
                                                dir="ltr"
                                            >
                                                <div
                                                    className="elementor-image-carousel swiper-wrapper"
                                                    style={{
                                                        transform: "translate3d(-2433.12px, 0px, 0px)",
                                                        transitionDuration: "0ms"
                                                    }}
                                                >
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={8}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/pretty-gaming.f27ce57.png"
                                                                alt="pretty-gaming.f27ce57"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={9}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/sa-gaming.52e386a.png"
                                                                alt="sa-gaming.52e386a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={10}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/sexy-gaming.09728c1.png"
                                                                alt="sexy-gaming.09728c1"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={11}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/spade-gaming.d835b4d.png"
                                                                alt="spade-gaming.d835b4d"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={0}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/ag-gaming.2eb0369.png"
                                                                alt="ag-gaming.2eb0369"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={1}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/ambbet.1d53780.png"
                                                                alt="ambbet.1d53780"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={2}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/ameba.dea3fdf.png"
                                                                alt="ameba.dea3fdf"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={3}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/dragoonsoft.a39781a.png"
                                                                alt="dragoonsoft.a39781a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-prev"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={8}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/pretty-gaming.f27ce57.png"
                                                                alt="pretty-gaming.f27ce57"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-active"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={9}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/sa-gaming.52e386a.png"
                                                                alt="sa-gaming.52e386a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-next"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={10}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/sexy-gaming.09728c1.png"
                                                                alt="sexy-gaming.09728c1"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={11}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/spade-gaming.d835b4d.png"
                                                                alt="spade-gaming.d835b4d"
                                                            />
                                                        </figure>
                                                    </div>{" "}
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={0}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/ag-gaming.2eb0369.png"
                                                                alt="ag-gaming.2eb0369"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={1}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/ambbet.1d53780.png"
                                                                alt="ambbet.1d53780"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={2}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/ameba.dea3fdf.png"
                                                                alt="ameba.dea3fdf"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={3}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/dragoonsoft.a39781a.png"
                                                                alt="dragoonsoft.a39781a"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={4}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/dream-gaming.d993e67.png"
                                                                alt="dream-gaming.d993e67"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={5}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/gamatron.96cdfab.png"
                                                                alt="gamatron.96cdfab"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={6}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/joker-gaming.417d073.png"
                                                                alt="joker-gaming.417d073"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div
                                                        className="swiper-slide swiper-slide-duplicate"
                                                        style={{ width: "118.125px", marginRight: 25 }}
                                                        data-swiper-slide-index={7}
                                                    >
                                                        <figure className="swiper-slide-inner">
                                                            <img
                                                                decoding="async"
                                                                className="swiper-slide-image"
                                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/03/live-22.dfd4972.png"
                                                                alt="live-22.dfd4972"
                                                            />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <span
                                                    className="swiper-notification"
                                                    aria-live="assertive"
                                                    aria-atomic="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-7177db0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="7177db0"
                        data-element_type="section"
                        data-settings='{"background_background":"gradient"}'
                    >
                        <div className="elementor-container elementor-column-gap-default">
                            <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3d4a575"
                                data-id="3d4a575"
                                data-element_type="column"
                            >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                        className="elementor-element elementor-element-cca7ed2 elementor-widget elementor-widget-heading"
                                        data-id="cca7ed2"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Luca169thเว็บคาสิโนออนไลน์
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-7ab7c41 elementor-widget elementor-widget-heading"
                                        data-id="7ab7c41"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                ครองอันดับในใจคนลาว 10 ปีซ้อน
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-2855b43 elementor-widget elementor-widget-heading"
                                        data-id="2855b43"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Luca169thบาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด
                                                เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบ HAREM789
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-c6f62f7 elementor-widget elementor-widget-heading"
                                        data-id="c6f62f7"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Created website by
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-78b4f26 elementor-widget elementor-widget-site-logo"
                                        data-id="78b4f26"
                                        data-element_type="widget"
                                        data-settings='{"width":{"unit":"px","size":256,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                        data-widget_type="site-logo.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="hfe-site-logo">
                                                <a
                                                    data-elementor-open-lightbox=""
                                                    className="elementor-clickable"
                                                    href="/"
                                                >
                                                    <div className="hfe-site-logo-set">
                                                        <div className="hfe-site-logo-container">
                                                            <img
                                                                decoding="async"
                                                                className="hfe-site-logo-img elementor-animation-"
                                                                src="https://cdn.javisx.com/image/61fecf50-99ec-498e-9508-3080597757f8.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-f84090e elementor-widget elementor-widget-heading"
                                        data-id="f84090e"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Terms and Conditions
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-20f83cd elementor-widget elementor-widget-heading"
                                        data-id="20f83cd"
                                        data-element_type="widget"
                                        data-widget_type="heading.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <h2 className="elementor-heading-title elementor-size-default">
                                                Copyright © 2021 Luca169thAll Rights Reserved.
                                            </h2>{" "}
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-4018346 elementor-widget elementor-widget-image"
                                        data-id={4018346}
                                        data-element_type="widget"
                                        data-widget_type="image.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <img
                                                loading="lazy"
                                                decoding="async"
                                                width={1024}
                                                height={91}
                                                src="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-1024x91.png"
                                                className="attachment-large size-large"
                                                alt=""
                                                srcSet="https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-1024x91.png 1024w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-300x27.png 300w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-768x68.png 768w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-24x2.png 24w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-36x3.png 36w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter-48x4.png 48w, https://idea-bet.live/theme/harem789/wp-content/uploads/2022/06/mfooter.png 1495w"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Carousel />
            </>

        );
    }
}
export default App;
